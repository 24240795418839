/* Template Name: SportsZone: Sports Club, New & Game Magazine Mobile Responsive Bootstrap Html Template Version: 1.0Author: DexignZoneWebsite: http://www.dexignzone.com/Contact: dexignexpert@gmail.comFollow: www.twitter.com/dexignzonesLike: www.facebook.com/dexignzone*/ /*======================================* CSS TABLE CONTENT *======================================1. GENERAL2. HEADINGS3. TEXT & PARAGRAPH4. LISTS5. BOX SPACING (inner & outer)>padding>marging6. BOX MAX-WIDTH CSS7. POSITIONS ( absolute & relative & statice & z-index )8. COLOR (text & background)>text color>background color9. IMAGES OVERLAY EFFECTS (overly animation on images)10. IMAGES EFFECTS (image animation in images) >image-opacity >image-zoom >image-zoom-slow >image-shrink >image-side-pan >image-vertical-pan >image-fade-in >image-fade-out >image-rotate >image-sepia >image-blurr >image-blurr-invert >image off color >image on color 11. CONTENT SHOW ON IMAGE BOX 12. FORM ELEMENTS13. WP DEFAULT14. HEADER (top part of page)15. PAGE TOP BAR (left & right content)16. LOGO ( company identify symbol )17. MAIN BAR ( place logo & main-navigation )18. NAVIGATIONS>mena-menu>mobile-menu>footer-menu19. BANNER20. SLIDER>rs slider>owl slider21. BLOG22. WIDGETS23. CUSTOMIZE CSS>set-radius>transition>float-clearfix24. SORTCODES >Buttons>Title separators>Deviders>Lists & bullets>Tabs>Accordians>Carousels>Testimonials>Pricing table>Alert box>Modal pop>Social icons>Breadcrumb>Tables>Image effects25. PAGINATION26. FOOTER27. PAGE-CONTENT28. INNER-CONTENT29. SIDE-NAV30. ONE-PAGE-LAYOUT======================================* END TABLE CONTENT *======================================*/ /*--------------------------------------------------------------1. GENERAL---------------------------------------------------------------*/
body {
  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #1d1d1d;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}
a {
  color: #efbb20;
  outline: 0 none;
  text-decoration: none;
}
a:hover,
a:focus {
  text-decoration: none;
  outline: 0 none;
}
a:active,
a:hover {
  color: #333333;
}
p a {
  color: #333333;
}
img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
hr {
  clear: both;
}
section,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
summary {
  display: block;
}
address {
  font-style: italic;
}
table {
  background-color: transparent;
  width: 100%;
}
table thead th {
  color: #333;
}
table td {
  padding: 15px 10px;
}
p,
address,
pre,
hr,
ul,
ol,
dl,
dd,
table {
  margin-bottom: 24px;
}
.overflow-hide {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
.clear {
  clear: both;
}
.pull-none {
  float: none;
}
::-moz-selection {
  background: var(--primary);
  color: #fff;
}
::selection {
  background: var(--primary);
  color: #fff;
} /*---------------------------------------------------------------2. HEADINGS---------------------------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1d1d1d;
  font-family: "Oswald", sans-serif;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #1d1d1d;
}
h1 {
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 25px;
  font-weight: 700;
}
h2 {
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 25px;
  font-weight: 700;
}
h3 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  font-weight: 700;
}
h4 {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;
  font-weight: 600;
}
h5 {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
  font-weight: 600;
}
h6 {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
  font-weight: 600;
} /*---------------------------------------------------------------3. TEXT & PARAGRAPH---------------------------------------------------------------*/
p {
  line-height: 24px;
  margin-bottom: 24px;
}
strong {
  font-weight: 600;
} /*text align*/
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
} /*text vertical align*/
.v-align-t {
  vertical-align: top;
}
.v-align-m {
  vertical-align: middle;
}
.v-align-b {
  vertical-align: bottom;
} /*text no line brack*/
.text-nowrap {
  white-space: nowrap;
} /*text transform*/
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
} /*text size*/
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}
.font-26 {
  font-size: 26px;
} /*text weight*/
.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-800 {
  font-weight: 800;
}
.font-weight-900 {
  font-weight: 900;
} /*---------------------------------------------------------------4. LISTS---------------------------------------------------------------*/
dl,
ul,
ol {
  list-style-position: outside;
  padding: 0;
}
ul,
ol {
  margin-bottom: 24px;
}
ul li,
ol li {
  padding: 0;
}
dl {
  margin-left: 0;
  margin-bottom: 30px;
}
dl dd {
  margin-bottom: 10px;
} /*---------------------------------------------------------------5. BOX SPACING (inner & outer)>padding>marging---------------------------------------------------------------*/ /*==>padding (around)======*/
.p-a0 {
  padding: 0;
}
.p-a5 {
  padding: 5px;
}
.p-a10 {
  padding: 10px;
}
.p-a15 {
  padding: 15px;
}
.p-a20 {
  padding: 20px;
}
.p-a25 {
  padding: 25px;
}
.p-a30 {
  padding: 30px;
}
.p-a40 {
  padding: 40px;
}
.p-a50 {
  padding: 50px;
}
.p-a60 {
  padding: 60px;
}
.p-a70 {
  padding: 70px;
}
.p-a80 {
  padding: 80px;
}
.p-a90 {
  padding: 90px;
}
.p-a100 {
  padding: 100px;
} /*==>Padding (top)==*/
.p-t0 {
  padding-top: 0;
}
.p-t5 {
  padding-top: 5px;
}
.p-t10 {
  padding-top: 10px;
}
.p-t15 {
  padding-top: 15px;
}
.p-t20 {
  padding-top: 20px;
}
.p-t30 {
  padding-top: 30px;
}
.p-t40 {
  padding-top: 40px;
}
.p-t50 {
  padding-top: 50px;
}
.p-t60 {
  padding-top: 60px;
}
.p-t70 {
  padding-top: 70px;
}
.p-t80 {
  padding-top: 80px;
}
.p-t90 {
  padding-top: 90px;
}
.p-t100 {
  padding-top: 100px;
} /*==>Padding (bottom)==*/
.p-b0 {
  padding-bottom: 0;
}
.p-b5 {
  padding-bottom: 5px;
}
.p-b10 {
  padding-bottom: 10px;
}
.p-b15 {
  padding-bottom: 15px;
}
.p-b20 {
  padding-bottom: 20px;
}
.p-b30 {
  padding-bottom: 30px;
}
.p-b40 {
  padding-bottom: 40px;
}
.p-b50 {
  padding-bottom: 50px;
}
.p-b60 {
  padding-bottom: 60px;
}
.p-b70 {
  padding-bottom: 70px;
}
.p-b80 {
  padding-bottom: 80px;
}
.p-b90 {
  padding-bottom: 90px;
}
.p-b100 {
  padding-bottom: 100px;
} /*==>Padding (left)==*/
.p-l0 {
  padding-left: 0;
}
.p-l5 {
  padding-left: 5px;
}
.p-l10 {
  padding-left: 10px;
}
.p-l15 {
  padding-left: 15px;
}
.p-l20 {
  padding-left: 20px;
}
.p-l30 {
  padding-left: 30px;
}
.p-l40 {
  padding-left: 40px;
}
.p-l50 {
  padding-left: 50px;
}
.p-l60 {
  padding-left: 60px;
}
.p-l70 {
  padding-left: 70px;
}
.p-l80 {
  padding-left: 80px;
}
.p-l90 {
  padding-left: 90px;
}
.p-l100 {
  padding-left: 100px;
} /*==>Padding (right)==*/
.p-r0 {
  padding-right: 0;
}
.p-r5 {
  padding-right: 5px;
}
.p-r10 {
  padding-right: 10px;
}
.p-r15 {
  padding-right: 15px;
}
.p-r20 {
  padding-right: 20px;
}
.p-r30 {
  padding-right: 30px;
}
.p-r40 {
  padding-right: 40px;
}
.p-r50 {
  padding-right: 50px;
}
.p-r60 {
  padding-right: 60px;
}
.p-r70 {
  padding-right: 70px;
}
.p-r80 {
  padding-right: 80px;
}
.p-r90 {
  padding-right: 90px;
}
.p-r100 {
  padding-right: 100px;
} /*==>Padding (left right)==*/
.p-lr0 {
  padding-left: 0;
  padding-right: 0;
}
.p-lr5 {
  padding-left: 5px;
  padding-right: 5px;
}
.p-lr10 {
  padding-left: 10px;
  padding-right: 10px;
}
.p-lr15 {
  padding-left: 15px;
  padding-right: 15px;
}
.p-lr20 {
  padding-left: 20px;
  padding-right: 20px;
}
.p-lr30 {
  padding-left: 30px;
  padding-right: 30px;
}
.p-lr40 {
  padding-left: 40px;
  padding-right: 40px;
}
.p-lr50 {
  padding-left: 50px;
  padding-right: 50px;
}
.p-lr60 {
  padding-left: 60px;
  padding-right: 60px;
}
.p-lr70 {
  padding-left: 70px;
  padding-right: 70px;
}
.p-lr80 {
  padding-left: 80px;
  padding-right: 80px;
}
.p-lr90 {
  padding-left: 90px;
  padding-right: 90px;
}
.p-lr100 {
  padding-left: 100px;
  padding-right: 100px;
} /*==>Padding (top bottom)==*/
.p-tb0 {
  padding-bottom: 0;
  padding-top: 0;
}
.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px;
}
.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px;
}
.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px;
}
.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px;
}
.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px;
}
.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px;
}
.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px;
}
.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px;
}
.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px;
}
.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px;
}
.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px;
}
.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px;
} /*==>margin (around)======*/
.m-auto {
  margin: auto;
}
.m-a0 {
  margin: 0;
}
.m-a5 {
  margin: 5px;
}
.m-a10 {
  margin: 10px;
}
.m-a15 {
  margin: 15px;
}
.m-a20 {
  margin: 20px;
}
.m-a30 {
  margin: 30px;
}
.m-a40 {
  margin: 40px;
}
.m-a50 {
  margin: 50px;
}
.m-a60 {
  margin: 60px;
}
.m-a70 {
  margin: 70px;
}
.m-a80 {
  margin: 80px;
}
.m-a90 {
  margin: 90px;
}
.m-a100 {
  margin: 100px;
} /*==>marging (top)==*/
.m-t0 {
  margin-top: 0;
}
.m-t5 {
  margin-top: 5px;
}
.m-t10 {
  margin-top: 10px;
}
.m-t15 {
  margin-top: 15px;
}
.m-t20 {
  margin-top: 20px;
}
.m-t30 {
  margin-top: 30px;
}
.m-t40 {
  margin-top: 40px;
}
.m-t50 {
  margin-top: 50px;
}
.m-t60 {
  margin-top: 60px;
}
.m-t70 {
  margin-top: 70px;
}
.m-t80 {
  margin-top: 80px;
}
.m-t90 {
  margin-top: 90px;
}
.m-t100 {
  margin-top: 100px;
} /*==>marging (bottom)==*/
.m-b0 {
  margin-bottom: 0;
}
.m-b5 {
  margin-bottom: 5px;
}
.m-b10 {
  margin-bottom: 10px;
}
.m-b15 {
  margin-bottom: 15px;
}
.m-b20 {
  margin-bottom: 20px;
}
.m-b30 {
  margin-bottom: 30px;
}
.m-b40 {
  margin-bottom: 40px;
}
.m-b50 {
  margin-bottom: 50px;
}
.m-b60 {
  margin-bottom: 60px;
}
.m-b70 {
  margin-bottom: 70px;
}
.m-b80 {
  margin-bottom: 80px;
}
.m-b90 {
  margin-bottom: 90px;
}
.m-b100 {
  margin-bottom: 100px;
} /*==>marging (left)==*/
.m-l0 {
  margin-left: 0;
}
.m-l5 {
  margin-left: 5px;
}
.m-l10 {
  margin-left: 10px;
}
.m-l15 {
  margin-left: 15px;
}
.m-l20 {
  margin-left: 20px;
}
.m-l30 {
  margin-left: 30px;
}
.m-l40 {
  margin-left: 40px;
}
.m-l50 {
  margin-left: 50px;
}
.m-l60 {
  margin-left: 60px;
}
.m-l70 {
  margin-left: 70px;
}
.m-l80 {
  margin-left: 80px;
}
.m-l90 {
  margin-left: 90px;
}
.m-l100 {
  margin-left: 100px;
} /*==>marging (right)==*/
.m-r0 {
  margin-right: 0;
}
.m-r5 {
  margin-right: 5px;
}
.m-r10 {
  margin-right: 10px;
}
.m-r15 {
  margin-right: 15px;
}
.m-r20 {
  margin-right: 20px;
}
.m-r30 {
  margin-right: 30px;
}
.m-r40 {
  margin-right: 40px;
}
.m-r50 {
  margin-right: 50px;
}
.m-r60 {
  margin-right: 60px;
}
.m-r70 {
  margin-right: 70px;
}
.m-r80 {
  margin-right: 80px;
}
.m-r90 {
  margin-right: 90px;
}
.m-r100 {
  margin-right: 100px;
} /*==>marging (left right)==*/
.m-lr0 {
  margin-left: 0;
  margin-right: 0;
}
.m-lr5 {
  margin-left: 5px;
  margin-right: 5px;
}
.m-lr10 {
  margin-left: 10px;
  margin-right: 10px;
}
.m-lr15 {
  margin-left: 15px;
  margin-right: 15px;
}
.m-lr20 {
  margin-left: 20px;
  margin-right: 20px;
}
.m-lr30 {
  margin-left: 30px;
  margin-right: 30px;
}
.m-lr40 {
  margin-left: 40px;
  margin-right: 40px;
}
.m-lr50 {
  margin-left: 50px;
  margin-right: 50px;
}
.m-lr60 {
  margin-left: 60px;
  margin-right: 60px;
}
.m-lr70 {
  margin-left: 70px;
  margin-right: 70px;
}
.m-lr80 {
  margin-left: 80px;
  margin-right: 80px;
}
.m-lr90 {
  margin-left: 90px;
  margin-right: 90px;
}
.m-lr100 {
  margin-left: 100px;
  margin-right: 100px;
} /*==>marging (top bottom)==*/
.m-tb0 {
  margin-bottom: 0;
  margin-top: 0;
}
.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px;
}
.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px;
}
.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px;
}
.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px;
}
.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px;
}
.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px;
}
.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px;
}
.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px;
}
.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px;
}
.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px;
}
.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px;
}
.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px;
} /*==> colum gap less==*/
.no-col-gap [class*="col-xs-"],
.no-col-gap [class*="col-sm-"],
.no-col-gap [class*="col-md-"],
.no-col-gap [class*="col-lg-"] {
  padding-left: 0;
  padding-right: 0;
} /*---------------------------------------------------------------6. BOX MAX-WIDTH CSS---------------------------------------------------------------*/
.w-100 {
  width: 100% !important;
}
.max-w100 {
  max-width: 100px;
}
.max-w200 {
  max-width: 200px;
}
.max-dez00 {
  max-width: 300px;
}
.max-w400 {
  max-width: 400px;
}
.max-w500 {
  max-width: 500px;
}
.max-w600 {
  max-width: 600px;
}
.max-w700 {
  max-width: 700px;
}
.max-w800 {
  max-width: 800px;
}
.max-w900 {
  max-width: 900px;
}
.max-w1000 {
  max-width: 1000px;
} /*---------------------------------------------------------------7. POSITIONS ( absolute & relative & statice & z-index )---------------------------------------------------------------*/
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.static {
  position: static;
}
.z-index1 {
  z-index: 1;
}
.z-index2 {
  z-index: 2;
}
.z-index3 {
  z-index: 3;
}
.z-index4 {
  z-index: 4;
}
.z-index5 {
  z-index: 5;
}
.z-index6 {
  z-index: 6;
}
.z-index7 {
  z-index: 7;
}
.z-index8 {
  z-index: 8;
}
.z-index9 {
  z-index: 9;
}
.z-index10 {
  z-index: 10;
}
.z-index100 {
  z-index: 100;
}
.z-index999 {
  z-index: 999;
} /*---------------------------------------------------------------8. COLOR (text & background)>text color>background color---------------------------------------------------------------*/ /*===>text color===*/
.text-white {
  color: #fff;
}
.text-black {
  color: #000;
}
.text-gray {
  color: #f5f6f6;
}
.text-gray-dark {
  color: #d3d3d3;
}
.text-red {
  color: #f00;
}
.text-green {
  color: #090;
}
.text-yellow {
  color: #ff0;
} /*===>background color===*/
.bg-transparent {
  background-color: transparent;
}
.bg-primary {
}
.bg-white {
  background-color: #fff;
}
.bg-black {
  background-color: #000;
}
.bg-black-light {
  background-color: #212121;
}
.bg-gray {
  background-color: #f5f6f6;
}
.bg-gray-dark {
  background-color: #d3d3d3;
}
.bg-red {
  background-color: #ff4045;
}
.bg-green {
  background-color: #38e225;
}
.bg-yellow {
  background-color: #f0f52e;
} /*===>background image===*/
.bg-img-fix {
  background-attachment: fixed;
} /*---------------------------------------------------------------9. IMAGES OVERLAY EFFECTS (overly animation on images)---------------------------------------------------------------*/
.dez-img-overlay1,
.dez-img-overlay2,
.dez-img-overlay3,
.dez-img-overlay4,
.dez-img-overlay5,
.dez-img-overlay6,
.dez-img-overlay7,
.dez-img-overlay8,
.dez-img-overlay9,
.dez-img-overlay11 {
  position: relative;
}
.dez-img-overlay1:before,
.dez-img-overlay1:after,
.dez-img-overlay2:before,
.dez-img-overlay2:after,
.dez-img-overlay3:before,
.dez-img-overlay3:after,
.dez-img-overlay4:before,
.dez-img-overlay4:after,
.dez-img-overlay5:before,
.dez-img-overlay5:after,
.dez-img-overlay6:before,
.dez-img-overlay6:after,
.dez-img-overlay7:before,
.dez-img-overlay7:after,
.dez-img-overlay8:before,
.dez-img-overlay8:after,
.dez-img-overlay9:before,
.dez-img-overlay9:after,
.dez-img-overlay11:before,
.dez-img-overlay11:after {
  content: "";
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0.4;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
} /*effect 1*/
.dez-img-overlay1:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}
.dez-img-overlay1:hover:before,
.dez-box:hover .dez-img-overlay1:before {
  opacity: 0.5;
} /*effect 2*/
.dez-img-overlay2:before {
  width: 100%;
  height: 100%;
  opacity: 0; /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* dezC, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000', GradientType=0); /* IE6-9 */
}
.dez-img-overlay2:hover:before,
.dez-box:hover .dez-img-overlay2:before {
  opacity: 0.9;
} /*effect 3*/
.dez-img-overlay3:before {
  left: 50%;
  top: 50%;
}
.dez-img-overlay3:hover:before,
.dez-box:hover .dez-img-overlay3:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
} /*effect 4*/
.dez-img-overlay4:before {
  left: 50%;
  top: 0;
  height: 100%;
}
.dez-img-overlay4:hover:before,
.dez-box:hover .dez-img-overlay4:before {
  width: 100%;
  left: 0;
} /*effect 5*/
.dez-img-overlay5:before {
  left: 0;
  top: 50%;
  width: 100%;
}
.dez-img-overlay5:hover:before,
.dez-box:hover .dez-img-overlay5:before {
  height: 100%;
  top: 0;
} /*effect 6*/
.dez-img-overlay6:before {
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
}
.dez-img-overlay6:hover:before,
.dez-box:hover .dez-img-overlay6:before {
  height: 100%;
} /*effect 7*/
.dez-img-overlay7:before,
.dez-img-overlay7:after {
  height: 100%;
}
.dez-img-overlay7:after {
  left: auto;
  right: 0;
}
.dez-img-overlay7:hover:before,
.dez-img-overlay7:hover:after,
.dez-box:hover .dez-img-overlay7:before,
.dez-box:hover .dez-img-overlay7:after {
  width: 50%;
} /*effect 8*/
.dez-img-overlay8:before,
.dez-img-overlay8:after {
  height: 100%;
}
.dez-img-overlay8:after {
  left: auto;
  right: 0;
}
.dez-img-overlay8:hover:before,
.dez-img-overlay8:hover:after,
.dez-box:hover .dez-img-overlay8:before,
.dez-box:hover .dez-img-overlay8:after {
  width: 100%;
  opacity: 0.3;
} /*effect 9*/
.dez-img-overlay9:before,
.dez-img-overlay9:after {
}
.dez-img-overlay9:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}
.dez-img-overlay9:hover:before,
.dez-img-overlay9:hover:after,
.dez-box:hover .dez-img-overlay9:before,
.dez-box:hover .dez-img-overlay9:after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
} /*effect 10*/
.dez-img-overlay10:before,
.dez-img-overlay10:after {
  width: 100%;
  height: 100%;
  opacity: 0;
}
.dez-img-overlay10:hover:before,
.dez-box:hover .dez-img-overlay10:before {
  opacity: 0.5;
}
.dez-img-overlay10:hover:after,
.dez-box:hover .dez-img-overlay10:after {
  width: 90%;
  height: 80%;
  left: 5%;
  top: 10%;
  opacity: 0.5;
  background: none;
  border: 1px dashed #ffffff;
}
.dez-img-overlay11:after {
}
.dez-img-overlay11:hover:after,
.dez-box:hover .dez-img-overlay11:after {
  height: 95%;
  opacity: 1;
  right: 7.5%;
  top: 2.5%;
  -moz-transform: skewY(0deg);
  -webkit-transform: skewY(0deg);
  -o-transform: skewY(0deg);
  -ms-transform: skewY(0deg);
  transform: skewY(0deg);
  width: 85%;
}
.dez-img-overlay11:hover:before,
.dez-box:hover .dez-img-overlay11:before {
  height: 85%;
  left: 2.5%;
  opacity: 1;
  top: 7.5%;
  -moz-transform: skewY(0deg);
  -webkit-transform: skewY(0deg);
  -o-transform: skewY(0deg);
  -ms-transform: skewY(0deg);
  transform: skewY(0deg);
  width: 95%;
}
.dez-img-overlay11:after {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  bottom: 5%;
  height: 80%;
  left: auto;
  right: 5%;
  top: auto;
  -moz-transform: skewY(70deg);
  -webkit-transform: skewY(70deg);
  -o-transform: skewY(70deg);
  -ms-transform: skewY(70deg);
  transform: skewY(70deg);
  width: 90%;
}
.dez-img-overlay11:before {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  bottom: auto;
  height: 90%;
  left: 5%;
  right: auto;
  top: 5%;
  -moz-transform: skewX(-70deg);
  -webkit-transform: skewX(-70deg);
  -o-transform: skewX(-70deg);
  -ms-transform: skewX(-70deg);
  transform: skewX(-70deg);
  width: 80%;
}
.dez-img-overlay11 {
  background-color: #000 !important;
}
.dez-img-overlay11:hover img {
  opacity: 0.7 !important;
} /*---------------------------------------------------------------10. IMAGES EFFECTS (image animation in images) >image-opacity >image-zoom >image-zoom-slow >image-shrink >image-side-pan >image-vertical-pan >image-fade-in >image-fade-out >image-rotate >image-sepia >image-blurr >image-blurr-invert >image off color >image on color---------------------------------------------------------------*/
.dez-img-effect {
  position: relative;
  overflow: hidden;
  display: block;
}
.dez-img-effect img {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: all 0.25s;
  -moz-transition: all 0.25s;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
} /* >image-opacity */
.dez-img-effect.opacity img:hover {
  opacity: 0.8;
  -moz-opacity: 0.8;
  -webkit-opacity: 0.8;
} /* >image-zoom */
.dez-img-effect.zoom-slow img {
  transition: all 10s;
  -moz-transition: all 10s;
  -webkit-transition: all 10s;
  -o-transition: all 10s;
}
.dez-img-effect.zoom-slow:hover img {
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
} /* >image-zoom-slow */
.dez-img-effect.zoom:hover img {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
} /* >image-shrink */
.dez-img-effect.shrink:hover img {
  transform: scale(0.8);
  -ms-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -o-transform: scale(0.8);
  -moz-transform: scale(0.8);
} /* >image-side-pan */
.dez-img-effect.side-pan:hover img {
  margin-left: -11%;
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -moz-transform: scale(1.2);
} /* >image-vertical-pan */
.dez-img-effect.vertical-pan:hover img {
  margin-top: -10%;
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -moz-transform: scale(1.2);
} /* >image-fade-in */
.dez-img-effect.fade-in {
  background: #000;
}
.dez-img-effect.fade-in img {
  opacity: 0.65;
  -moz-opacity: 0.65;
  -webkit-opacity: 0.65;
}
.dez-img-effect.fade-in:hover img {
  opacity: 1;
  -moz-opacity: 1;
  -webkit-opacity: 1;
} /* >image-fade-out */
.dez-img-effect.fade-out {
  background: #000;
}
.dez-img-effect.fade-out:hover img {
  opacity: 0.7;
  -moz-opacity: 0.7;
  -webkit-opacity: 0.7;
} /* >image-rotate */
.dez-img-effect.rotate:hover img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
} /* >image-sepia */
.dez-img-effect.sepia img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
}
.algo-image-hover.sepia:hover img {
  -webkit-filter: sepia(0);
  filter: sepia(0);
} /* >image-blurr */
.dez-img-effect.blurr img {
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}
.dez-img-effect.blurr:hover img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
} /* >image-blurr-invert */
.dez-img-effect.blurr-invert img {
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}
.dez-img-effect.blurr-invert img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}
.dez-img-effect.blurr-invert:hover img {
  filter: blur(0px);
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
} /* >image off color */
.dez-img-effect.off-color img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}
.dez-img-effect.off-color:hover img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
} /* >image on color */
.dez-img-effect.on-color img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}
.dez-img-effect.on-color:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
} /*---------------------------------------------------------------11. CONTENT SHOW ON IMAGE BOX---------------------------------------------------------------*/ /* info box show */
.dez-info-has {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 30px 15px 40px;
  opacity: 0;
  margin-bottom: -100px;
  z-index: 2;
}
.dez-info-has.p-a15 {
  padding: 15px;
}
.dez-info-has.p-a20 {
  padding: 20px;
}
.dez-info-has.bg-primary {
  background-color: rgba(27, 188, 232, 0.9);
}
.dez-info-has.bg-green {
  background-color: rgba(106, 179, 62, 0.9);
}
.dez-info-has.bg-black {
  background-color: rgba(0, 0, 0, 0.6);
}
.dez-info-has.bg-white {
  background-color: rgba(255, 255, 255, 0.8) !important;
}
.dez-info-has.bg-white h1,
.dez-info-has.bg-white h2,
.dez-info-has.bg-white h3,
.dez-info-has.bg-white h4,
.dez-info-has.bg-white h5,
.dez-info-has.bg-white h6 {
  color: #3d474a;
}
.dez-info-has.bg-white h1 a,
.dez-info-has.bg-white h2 a,
.dez-info-has.bg-white h3 a,
.dez-info-has.bg-white h4 a,
.dez-info-has.bg-white h5 a,
.dez-info-has.bg-white h6 a {
  color: #3d474a;
}
.dez-info-has.bg-white .dez-info-has-text,
.dez-info-has.bg-white p {
  color: #767676;
}
.dez-info-has h1,
.dez-info-has h2,
.dez-info-has h3,
.dez-info-has h4,
.dez-info-has h5,
.dez-info-has h6,
.dez-info-has p,
.dez-info-has-text,
.dez-info-has a {
  color: #fff;
}
.dez-info-has .dez-info-has-text {
  margin-bottom: 20px;
  line-height: 24px;
}
.dez-media {
  overflow: hidden;
  position: relative;
}
.dez-box:hover .dez-info-has,
.dez-media:hover .dez-info-has {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
} /* without hover */
.dez-info-has.no-hover {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
} /* Skew */
.dez-info-has.skew-has {
  border-top: 4px solid #2d3239;
  padding: 30px 40px 60px;
  -moz-transform: skewY(10deg);
  -webkit-transform: skewY(10deg);
  -o-transform: skewY(10deg);
  -ms-transform: skewY(10deg);
  transform: skewY(10deg);
  bottom: -35px;
}
.dez-info-has.skew-has > * {
  -moz-transform: skewY(-10deg);
  -webkit-transform: skewY(-10deg);
  -o-transform: skewY(-10deg);
  -ms-transform: skewY(-10deg);
  transform: skewY(-10deg);
} /*---------------------------------------------------------------12. FORM ELEMENTS---------------------------------------------------------------*/
label {
  font-weight: 600;
  margin-bottom: 10px;
}
.accordion-item-default,
.accordion-item-default > .accordion-item-heading {
  border-color: #e7ecf1;
  color: inherit;
}
.accordion-item-default
  > .accordion-item-heading
  + .accordion-item-collapse
  > .accordion-item-body {
  border-top-color: #e7ecf1;
}
.form-group {
  margin-bottom: 25px;
}
.form-control {
  border-color: #e1e6eb;
  box-shadow: none;
  height: 40px;
  font-size: 13px;
  line-height: 20px;
  padding: 9px 12px;
  color: #000;
}
.form-control:focus {
  border-color: #e1e1e1;
  outline: 0;
  color: #000;
}
.form-control:focus,
.has-error .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control.kv-fileinput-caption {
  height: 39px;
}
.form-group.form-inline .radio,
.form-group.form-inline .checkbox {
  margin-right: 15px;
} /*input group*/
.input-group {
  width: 100%;
}
.input-group .form-control,
.input-group-addon,
.input-group-btn {
  display: table-cell;
}
.input-group-addon {
  background: #fff;
  border-color: #e1e1e1;
  padding: 6px 14px;
  font-size: 16px;
}
.input-group-addon.font-size-20 {
  font-size: 20px;
}
.input-group-addon.fixed-w {
  text-align: center;
  padding: 6px 0;
  width: 40px;
}
.input-group-addon.v-align-t {
  line-height: 30px;
} /*select box*/
.dropdown-menu {
  font-size: 13px;
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  width: 100%;
}
.bootstrap-select .dropdown-toggle {
  border: 1px solid #e7ecf1 !important;
  background-color: #fff !important;
  height: 40px;
  font-size: 13px;
  color: #999;
} /* .bootstrap-select .dropdown-toggle:after{display: none;}*/
.bootstrap-select .dropdown-toggle:active,
.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select .dropdown-toggle:hover {
  background-color: #fff !important;
  border: 1px solid #e9e9e9 !important;
  box-shadow: none !important;
  outline: 0px !important;
}
.bootstrap-select:active,
.bootstrap-select:focus {
  border: 0px !important;
  box-shadow: none !important;
  outline: 0px !important;
}
.bootstrap-select.show {
  box-shadow: none !important;
  outline: 0px !important;
}
.bootstrap-select.show .dropdown-toggle {
  box-shadow: none !important;
  outline: 0px !important;
}
.bootstrap-select.show.btn-group .dropdown-menu.inner {
  display: block;
}
.bootstrap-select div.dropdown-menu {
  border: 1px solid #e9e9e9;
  border-radius: 0;
  box-shadow: none;
  margin-top: -1px;
  padding: 0;
  font-size: 13px;
}
.bootstrap-select div.dropdown-menu ul li {
  padding: 0;
}
.bootstrap-select div.dropdown-menu ul li a {
  padding: 5px 15px;
}
.bootstrap-select div.dropdown-menu ul li a img {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  margin-right: 5px;
}
.bootstrap-select .bs-searchbox .form-control {
  padding: 5px 10px;
  height: 30px;
}
.bootstrap-select .dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
.bootstrap-select .dropdown-menu > li > a:hover {
  background-color: #f2f2f4;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary);
} /* Hide and show pasword */
.search-input {
  position: relative;
}
.search-input .show-pass {
  cursor: pointer;
  position: absolute;
  right: 0;
  height: 100%;
  width: 50px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.search-input .show-pass .eye-close {
  display: block;
}
.search-input .show-pass .eye-open {
  display: none;
}
.search-input .show-pass.active .eye-close {
  display: none;
}
.search-input .show-pass.active .eye-open {
  display: block;
} /*radio & checkbox*/
.dez-form input[type="checkbox"] {
  display: none;
}
input[type="checkbox"],
input[type="radio"] {
  opacity: 0;
  margin-left: 1px !important;
}
input[type="checkbox"] + label,
input[type="radio"] + label {
  display: block;
  padding-left: 20px !important;
  position: relative;
}
input[type="checkbox"] + label:hover:before,
input[type="radio"] + label:hover:before {
  border-color: #3396d1;
}
input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  background-color: #fff;
  border: 2px solid #b6b7b8;
  border-radius: 0px;
  content: "";
  display: inline-block;
  height: 16px;
  line-height: 1;
  margin-left: -20px;
  position: absolute;
  top: 2px;
  transition: all linear 0.1s;
  width: 16px;
}
input[type="checkbox"] + label:after {
  color: #fff;
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 0px;
  -webkit-font-smoothing: antialiased;
  left: 6px;
  position: absolute;
  top: 7px;
  transition: all linear 0.1s;
}
input[type="checkbox"]:checked + label:before {
  border-width: 7px;
  border-color: #3396d1;
}
input[type="checkbox"]:checked + label:after {
  font-size: 10px;
  left: 2px;
  top: 2px;
}
input[type="radio"] + label:before {
  border-radius: 50%;
  content: "";
}
input[type="radio"]:checked + label:before {
  border-color: #3396d1;
  border-width: 5px;
}
.form-inline .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"] {
  position: absolute;
  left: 0;
  bottom: 0;
} /*touchspin input type number*/
.bootstrap-touchspin .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}
.bootstrap-touchspin .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 10px 10px 9px;
  margin-left: -1px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: #e1e1e1;
  background: #fff;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0;
  border-top-right-radius: 0;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  margin-top: -2px;
  border-radius: 0;
  border-bottom-right-radius: 0;
}
.bootstrap-touchspin .input-group-btn-vertical i {
  position: absolute;
  top: 4px;
  left: 5px;
  font-size: 9px;
  font-weight: normal;
  color: #9fa0a1;
} /*rating star*/
.input-rating input[type="radio"] {
  display: none;
}
.input-rating input[type="radio"] + label {
  display: inline-block;
  margin-left: -4px;
  padding-left: 0px !important;
  padding-right: 0px;
  width: 24px;
}
.input-rating input[type="radio"] + label:first-of-type {
  margin-left: 0px;
}
.input-rating input[type="radio"] + label:before {
  background-color: transparent;
  border: none;
  color: #ffd925;
  content: "\f006";
  display: inline-block;
  font-family: FontAwesome;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  height: 14px;
  line-height: 1;
  margin-left: 0px;
  position: static;
  text-align: center;
  top: 2px;
  transition: none;
  width: 14px;
}
.input-rating input[type="radio"]:checked + label:before,
.input-rating input[type="radio"] + label.marked:before,
.input-rating input[type="radio"] + label.filled:before,
.input-rating input[type="radio"] + label.hovered.filled:before {
  content: "\f005";
}
.input-rating input[type="radio"] + label.hovered:before {
  content: "\f006";
} /*simple rating list*/
.rating-bx {
  color: #ffd925;
  font-size: 14px;
}
.rating-bx i {
  margin: 0 2px;
}
.rating-container .rating-stars::before {
  text-shadow: none;
}
.rating-container {
  color: #c8c8c8;
}
.rating-container .rating-stars {
  color: #3396d1;
} /*validation symbol*/
.has-feedback label ~ .form-control-feedback {
  top: 31px;
} /*loading*/
.loading-box {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0.7;
  z-index: 9999;
}
.loading-pic {
  width: 70px;
  height: 70px;
  background: url(https://sportszone.dexignzone.com/xhtml/css/inc/images/loading.GIF)
    center no-repeat;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -35px;
  z-index: 99999;
} /*loading - 2*/
.loading-cover,
.loading-srh-bar,
.loading-map {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.loading-cover .fa-spinner,
.loading-srh-bar .fa-spinner,
.loading-map .fa-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -10px;
  font-size: 25px;
  color: #333;
}
.dez-banner-outer {
  margin-bottom: 0;
}
.dez-success-top,
.dez-error-top {
  margin-bottom: 0;
}
.alert-bx.alert-info {
  padding: 15px;
  border: 1px solid #bce8f1;
} /*---------------------------------------------------------------13. WP DEFAULT---------------------------------------------------------------*/
.alignnone {
  margin: 0;
}
.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 15px;
}
.alignright {
  float: right;
  margin: 5px 0 25px 25px;
}
.alignleft {
  float: left;
  margin: 5px 25px 25px 0;
}
a img.alignright {
  float: right;
  margin: 5px 0 25px 25px;
}
a img.alignnone {
  margin: 5px 0 25px 0;
}
a img.alignleft {
  float: left;
  margin: 5px 25px 25px 0;
}
a img.aligncenter {
  display: block;
  margin: 5px auto 15px;
}
.wp-caption {
  max-width: 100%;
  text-align: center;
}
.wp-caption img[class*="wp-image-"] {
  display: block;
  margin: 0;
}
.wp-caption.alignnone {
  margin: 5px 0 25px 0;
}
.wp-caption.alignleft {
  margin: 5px 25px 25px 0;
}
.wp-caption.alignright {
  margin: 5px 0 25px 25px;
}
.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}
.wp-caption p.wp-caption-text {
  color: #9d9d9d;
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  padding: 10px 0;
  text-align: left;
}
blockquote {
  padding: 16px 20px 16px 80px;
  font-size: 18px;
  font-style: italic;
  color: #555555;
  margin: 40px 0;
  border-left: none;
  line-height: 30px;
  position: relative;
  background: #f6f6f6;
  clear: both;
  border-left: 4px solid #efbb20;
}
blockquote p {
  font-size: 18px;
  color: #555555;
  line-height: 30px;
  margin-bottom: 0px;
}
blockquote:before {
  content: "\f10d";
  font-family: "FontAwesome";
  color: #efbb20;
  font-size: 35px;
  position: absolute;
  left: 20px;
  top: 20px;
  font-style: normal;
}
.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto;
} /*---------------------------------------------------------------14. HEADER (top part of page)---------------------------------------------------------------*/
.site-header {
  position: relative;
  z-index: 999;
}
.site-header ul,
.site-header ol {
  margin-bottom: 0;
} /*without top bar*/
.extra-nav {
  float: right;
  padding: 25px 0 20px;
  position: relative;
  z-index: 9;
}
.extra-nav .extra-cell {
  display: inline-block;
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .extra-nav {
    margin-right: 15px;
  }
}
@media only screen and (max-width: 480px) {
  .extra-nav {
    margin: 0 1px 0 0;
    text-align: right;
  }
} /* map page header*/
#header-part.fix-map-header {
  height: 90px;
}
#header-part.fix-map-header .main-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
@media only screen and (max-width: 480px) {
  #header-part.fix-map-header {
    height: 160px;
  }
} /*---------------------------------------------------------------15. PAGE TOP BAR (left & right content)---------------------------------------------------------------*/
.top-bar {
  background-color: #f5f5f5;
  color: #ababab;
  padding: 5px 0;
}
.dez-topbar-left {
  float: left;
}
.dez-topbar-right {
  float: right;
}
.dez-topbar-center,
.dez-topbar-left,
.dez-topbar-right {
  padding-left: 15px;
  padding-right: 15px;
  width: auto;
}
@media only screen and (max-width: 767px) {
  .top-bar [class*="col-"] {
    width: 100%;
    text-align: right;
    padding: 10px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .top-bar [class*="col-"]:last-child {
    border-bottom: none;
  }
} /*===>eamil-phone (left)===*/
.e-p-bx li {
  display: inline;
  font-size: 14px;
  padding-right: 10px;
}
.e-p-bx li a {
  color: #fff;
}
.e-p-bx li i {
  margin-right: 10px;
  vertical-align: middle;
} /*===>social-links (right)===*/
.social-bx,
.login-bx {
  margin: 0 0 0 15px;
  float: right;
}
.social-bx li,
.login-bx li {
  font-size: 12px;
  margin: 0;
  position: relative;
}
.social-bx li {
  padding-left: 1px;
  padding-right: 1px;
}
.social-bx li a,
.login-bx li a {
  color: #ffffff;
}
.social-bx li i,
.login-bx li i {
  vertical-align: baseline;
  margin: 0 5px 0 0;
}
.social-bx li span,
.login-bx li span {
  vertical-align: baseline;
  margin: 0 2px 0;
  font-size: 8px;
}
.login-bx li ul {
  top: 25px;
  left: auto;
  right: 0;
  border: none;
  padding: 0;
}
.login-bx li ul li {
  border-bottom: 1px solid #f7f7f7;
}
.login-bx li ul li a {
  color: #767676;
  padding: 9px 15px;
}
.arrow-up-border:before,
.arrow-up:after {
  position: absolute;
  display: inline-block;
  content: "";
}
.arrow-up-border:before {
  top: -7px;
  right: 19px;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #f00;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.arrow-up:after {
  top: -6px;
  right: 20px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
}
.arrow-left:before {
  position: absolute;
  top: -7px;
  left: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}
.arrow-left:after {
  position: absolute;
  top: -6px;
  left: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
} /*===>language-list ()===*/
.language-bx li {
  display: inline;
  margin: 0 10px;
} /*---------------------------------------------------------------16. LOGO ( company identify symbol )---------------------------------------------------------------*/ /*===>logo for header===*/
.logo-header {
  display: table;
  float: left;
  vertical-align: middle;
  padding: 0;
  font-size: 36px;
  color: #efbb20;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 200px;
  height: 90px;
  position: relative;
  z-index: 9;
}
.logo-footer {
  display: table;
  vertical-align: middle;
  padding: 0;
  font-size: 36px;
  color: #efbb20;
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: 60px;
}
.logo-header > a,
.logo-footer > a {
  display: table-cell;
  vertical-align: middle;
}
.logo-header.center-block {
  margin-left: auto;
  margin-right: auto;
  float: none;
}
.logo-header img,
.logo-footer img {
  max-width: 100%;
  width: 100%;
  height: auto;
}
.logo-header span,
.logo-footer span {
  font-size: 20px;
  color: #efbb20;
  letter-spacing: 20px;
} /*=When header is sticky=*/
.is-fixed .logo-header,
.is-fixed.header-curve .logo-header {
  height: 90px;
  width: 200px;
} /*=Set width for mobile=*/
@media only screen and (max-width: 767px) {
  .logo-header,
  .is-fixed.header-curve .logo-header {
    width: 180px;
  }
  .is-fixed .logo-header a img,
  .is-fixed.header-curve .logo-header a img {
    vertical-align: sub;
  }
} /*---------------------------------------------------------------17. MAIN BAR ( place logo & main-navigation )---------------------------------------------------------------*/
.screen-lock {
  overflow: hidden;
}
.main-bar {
  background: #ffffff;
  width: 100%;
  position: relative;
}
.header-curve .logo-header:before,
.header-curve .logo-header:after {
  background-color: #fff;
  content: "";
  position: absolute;
  bottom: 0;
  height: 100%;
  z-index: -1;
}
.header-curve .logo-header:before {
  right: -50px;
  width: 80%;
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -o-transform: skew(30deg);
  -ms-transform: skew(30deg);
  transform: skew(30deg);
}
.header-curve .logo-header:after {
  right: 10%;
  width: 2000px;
}
.header-curve .logo-header img {
  width: 100%;
}
.is-fixed.header-curve .logo-header img {
  margin-top: -0;
}
@media only screen and (max-width: 767px) {
  .header-curve .logo-header:after {
    right: 0;
    left: auto;
  }
} /*---------------------------------------------------------------18. NAVIGATIONS>mena-menu>mobile-menu>footer-menu---------------------------------------------------------------*/ /*==>mena-menu==*/
.navbar-toggle {
  border: 2px solid #efbb20;
  margin: 24px 0 24px 10px;
  padding: 12px 10px;
}
.navbar-toggle span {
  background: #666;
}
.is-fixed .navbar-toggle {
  margin: 14px 0;
}
.header-nav {
  position: relative;
  padding: 0;
}
.header-nav .nav {
  float: right;
}
.header-nav .nav i {
  font-size: 9px;
  margin-left: 3px;
  margin-top: -3px;
  vertical-align: middle;
  opacity: 0.7;
}
.header-nav .nav > li {
  margin: 0px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
}
.header-nav .nav > li > a {
  border-radius: 0px;
  color: #555555;
  font-size: 13px;
  padding: 35px 12px;
  cursor: pointer;
  display: block;
}
.header-nav .nav > li > a:hover {
  background-color: transparent;
  color: #efbb20;
}
.header-nav .nav > li > a:active,
.header-nav .nav > li > a:focus {
  background-color: transparent;
}
.header-nav .nav > li.active > a,
.header-nav .nav > li.current-menu-item > a {
  background-color: transparent;
  color: #efbb20;
}
.header-nav .nav > li:hover > a {
  color: #efbb20;
}
.header-nav .nav > li:hover > .sub-menu,
.header-nav .nav > li:hover > .mega-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.header-nav .nav > li .sub-menu {
  background-color: #ffffff;
  border: 1px solid #f4f4f4;
  display: block;
  left: 0;
  list-style: none;
  margin: 20px 0 0;
  opacity: 0;
  padding: 0px;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
}
.header-nav .nav > li .sub-menu li {
  border-bottom: 1px solid #f4f4f4;
  position: relative;
}
.header-nav .nav > li .sub-menu li a {
  color: #777777;
  display: block;
  font-size: 12px;
  padding: 10px 20px;
  text-transform: uppercase;
  transition: all 0.15s linear;
}
.header-nav .nav > li .sub-menu li a:hover {
  background-color: #f2f2f2;
  color: #efbb20;
  text-decoration: none;
}
.header-nav .nav > li .sub-menu li:hover > a {
  color: #efbb20;
}
.header-nav .nav > li .sub-menu li:last-child {
  border-bottom: 0px;
}
.header-nav .nav > li .sub-menu li .fa {
  color: #363636;
  display: block;
  float: right;
  position: absolute;
  right: 10px;
  top: 12px;
}
.header-nav .nav > li .sub-menu li > .sub-menu {
  left: 220px;
  margin: 0 0 0 20px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.header-nav .nav > li .sub-menu li:hover > .sub-menu {
  left: 220px;
  margin: 0px;
  opacity: 1;
  top: -1px;
  visibility: visible;
}
.header-nav .nav > li .sub-menu li:hover > .sub-menu:before {
  background-color: transparent;
  bottom: 0px;
  content: "";
  display: block;
  height: 100%;
  left: -6px;
  position: absolute;
  top: 0px;
  width: 6px;
}
.header-nav .nav > li.has-mega-menu {
  position: inherit;
}
.header-nav .nav > li .mega-menu {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  display: table;
  left: 0px;
  list-style: none;
  opacity: 0;
  position: absolute;
  right: 0px;
  visibility: hidden;
  width: 100%;
  margin-top: 20px;
}
.header-nav .nav > li .mega-menu > li {
  display: table-cell;
  padding: 10px 0;
  position: relative;
  vertical-align: top;
  width: 25%;
}
.header-nav .nav > li .mega-menu > li:after {
  content: "";
  background-color: rgba(204, 204, 204, 0.4);
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 1px;
  height: 100%;
}
.header-nav .nav > li .mega-menu > li:last-child:after {
  display: none;
}
.header-nav .nav > li .mega-menu > li > a {
  color: #555555;
  display: block;
  font-size: 14px;
  padding: 0 20px;
}
.header-nav .nav > li .mega-menu > li ul {
  list-style: none;
  margin: 10px 0px 0px 0px;
  padding: 0px;
  width: 100%;
}
.header-nav .nav > li .mega-menu > li ul a {
  color: #777777;
  display: block;
  font-size: 12px;
  line-height: 34px;
  text-transform: uppercase;
  padding: 0 20px;
}
.header-nav .nav > li .mega-menu > li ul a:hover {
  color: #efbb20;
  background-color: #f2f2f2;
}
.header-nav .nav > li.menu-item-has-children:before {
  content: "\f078";
  display: block;
  font-family: "FontAwesome";
  right: 4px;
  position: absolute;
  top: 50%;
  color: #999;
  margin-top: -8px;
  font-size: 8px;
}
@media only screen and (max-width: 991px) {
  header .logo {
    display: block;
    padding: 4px 0;
  }
  .header-nav .nav > li > a {
  }
  .header-nav .nav i {
    margin-top: 6px;
  }
}
@media only screen and (max-width: 991px) {
  /* responsive strat when media screen [991px] css START*/
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  } /* .navbar-collapse.collapse{display: none!important;}*/
  .navbar-collapse.collapse.in {
    /* NEW */
    display: block !important;
  } /* responsive strat when medai screen [991px] css END*/
  .header-nav {
    clear: both;
    margin: 0 -15px;
    border-bottom: 1px solid #e9e9e9;
  }
  .header-nav .nav {
    float: none;
    margin: 0;
    background: #fff;
  }
  .header-nav .nav li {
    float: none;
  }
  .header-nav .nav > li .sub-menu > li,
  .header-nav .nav > li .mega-menu > li {
    float: none;
    display: block;
    width: auto;
  }
  .header-nav .nav > li > a {
    padding: 12px 15px;
    border-top: 1px solid #e9e9e9;
  }
  .header-nav .nav i {
    float: right;
  }
  .header-nav .nav > li > a:hover,
  .header-nav .nav > li > a:active,
  .header-nav .nav > li > a:focus {
    background-color: #f0f0f0;
    text-decoration: none;
  }
  .header-nav .nav > li .mega-menu > li:after {
    display: none;
  }
  .header-nav .nav > li ul,
  .header-nav .nav > li .sub-menu,
  .header-nav .nav > li .mega-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
  }
  .header-nav .nav > li ul.mega-menu ul {
    display: none;
  }
  .header-nav .nav > li:hover > ul,
  .header-nav .nav > li:hover .sub-menu,
  .header-nav .nav > li:hover .mega-menu,
  .header-nav .nav > li .sub-menu li > .sub-menu {
    opacity: 1;
    visibility: visible;
    display: block;
    margin: 0;
  }
  .header-nav .nav > li ul.mega-menu li:hover ul {
    display: block;
  }
} /*=nav dark version=*/
.nav-dark.header-nav .nav > li .sub-menu,
.nav-dark.header-nav .nav > li .mega-menu {
  background-color: #1d1d1d;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.nav-dark.header-nav .nav > li .sub-menu li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.nav-dark.header-nav .nav > li .sub-menu li a,
.nav-dark.header-nav .nav > li .mega-menu > li ul a,
.nav-dark.header-nav .nav > li .mega-menu > li > a {
  color: #cccccc;
}
.nav-dark.header-nav .nav > li .sub-menu li a:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.nav-dark.header-nav .nav > li .mega-menu > li ul a:hover {
  background-color: rgba(255, 255, 255, 0.04);
  color: #efbb20;
}
.nav-dark.header-nav .nav > li .mega-menu > li:after {
  background-color: rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 991px) {
  .nav-dark.header-nav {
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  }
  .nav-dark.header-nav .nav {
    background-color: #202020;
  }
  .nav-dark.header-nav .nav > li > a {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  [class*="bg-"] .nav-dark.header-nav .nav > li > a {
    color: #999;
  }
  [class*="bg-"] .nav-dark.header-nav .nav > li.active > a,
  [class*="bg-"] .nav-dark.header-nav .nav > li:hover > a {
    color: #efbb20;
  }
  .nav-dark.header-nav .nav > li > a:hover,
  .nav-dark.header-nav .nav > li > a:active,
  .nav-dark.header-nav .nav > li > a:focus {
    background-color: #282828;
    color: #efbb20;
  }
  .nav-dark.header-nav .nav > li ul,
  .nav-dark.header-nav .nav > li .sub-menu,
  .nav-dark.header-nav .nav > li .mega-menu {
    background-color: #282828;
  }
} /*=when header is sticky=*/
.sticky-no .main-bar {
  position: static !important;
} /*=when header is sticky=*/
.is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
}
.is-fixed .header-nav .nav > li > a {
  padding: 35px 12px;
}
.is-fixed .extra-nav {
  padding: 25px 0 20px;
}
@media only screen and (max-width: 991px) {
  .is-fixed .header-nav .nav > li > a {
    padding: 12px 15px;
  }
}
@media only screen and (max-width: 767px) {
  .is-fixed .header-nav .nav {
    overflow: auto;
    height: 225px;
  }
  .is-fixed .header-nav .nav > li > a {
    padding: 12px 15px;
  }
  .is-fixed#header-part .navbar-toggle {
    margin: 15px 0;
  }
} /*=header full width=*/
#header-part.full-width .top-bar .container,
#header-part.full-width .main-bar .container {
  width: auto;
} /*=Header style designs strat=*/ /*header with bg primary color 1 */
[class*="bg-"] .header-nav .nav > li > a {
  color: #fff;
}
[class*="bg-"] .header-nav .nav > li.active > a,
[class*="bg-"] .header-nav .nav > li.current-menu-item > a {
  color: #2d3239;
}
[class*="bg-"] .header-nav .nav > li > a:hover,
[class*="bg-"] .header-nav .nav > li:hover > a {
  color: #2d3239;
}
[class*="bg-"] .navbar-toggle {
  border-color: #fff;
}
[class*="bg-"] .navbar-toggle span {
  background-color: #2d3239;
}
@media only screen and (max-width: 991px) {
  [class*="bg-"] .header-nav .nav > li > a {
    color: #555;
  }
} /*header style 1 (default) */ /*header style 2 (center content) */
.dez-bnr-inr.dez-bnr-inr-lg {
  height: 800px;
}
.dez-bnr-inr.dez-bnr-inr-md {
  height: 600px;
}
.dez-bnr-inr.dez-bnr-inr-sm {
  height: 400px;
}
.header-style-1.dark .top-bar {
  background-color: #1d1d1d;
}
.header-style-1 .top-bar .fa {
  border: 1px solid rgba(255, 255, 255, 0.05);
  font-size: 15px;
  height: 35px;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  width: 35px;
  color: #ababab;
} /* responsive */
@media only screen and (max-width: 767px) {
  .header-style-1 .top-bar .dez-topbar-right ul li span {
    display: none;
  }
  .header-style-1 .top-bar .dez-topbar-right ul li i {
    margin-right: 0;
  }
  .header-style-1 .top-bar .dez-topbar-left,
  .header-style-1 .top-bar .dez-topbar-right {
    padding: 0;
  }
} /*==================Header Style 2==================*/
.header-style-2 .fa-chevron-down {
  display: none;
}
.header-style-2 .top-btn a {
  background: #f0ba22;
  border: 10px solid #2d313a;
  width: 65px;
  height: 65px;
  display: block;
  margin: 0px auto;
  color: #fff;
  text-align: center;
  line-height: 45px;
  font-size: 20px;
  transform: rotate(45deg);
  margin: -62px auto 0;
}
.header-style-2 .social-line li a {
  color: #ababab;
  border: 1px solid #e3e3e3;
  border-width: 0 1px;
  height: 45px;
  line-height: 45px;
  padding: 0 15px;
  display: inline-block;
  min-width: 45px;
  cursor: pointer;
}
.header-style-2 .top-bar {
  border-bottom: 1px solid #e3e3e3;
  padding: 0;
  background-color: #f5f5f5;
} /* header style dark 1 */
.header-style-2.dark .main-bar {
  background-color: #fff;
}
.header-style-2.dark .top-bar {
  background-color: #2c2c2c;
  border-color: #2c2c2c;
}
.header-style-2.dark .social-line li a {
  border-color: #47372c;
} /* header style dark 1 End */ /* Responsive CSS */ /* Tablate [991] Responsive CSS */
@media only screen and (max-width: 991px) {
  .header-style-2 .dez-topbar-left,
  .header-style-2 .dez-topbar-right {
    padding-left: 0;
    padding-right: 0;
  }
  .header-style-2 .dez-topbar-left li a span {
    display: none;
  }
  .header-style-2 .fa-chevron-down {
    display: block;
  }
} /* Tablate [991] Responsive CSS End */ /* Responsive CSS End */ /*==================Header Style 2 End==================*/ /*==================Header Style 3==================*/
.header-style-3 .main-bar {
  background-color: #f5f5f5;
  height: 45px;
  border-bottom: 1px solid #e3e3e3;
}
.header-style-3 .main-bar .container {
}
.header-style-3 .dez-topbar-left,
.header-style-3 .dez-topbar-right {
  padding-left: 0;
  padding-right: 0;
}
.header-style-3 .slide-up {
  position: absolute;
  width: 100%;
}
.header-style-3 .logo-header {
  height: 85px;
}
.header-style-3 .header-nav .nav > li > a {
  padding: 32px 15px;
}
.header-style-3 .top-bar {
  padding: 0;
}
.header-style-3 .header-curve .logo-header::after {
  right: 15px;
  width: 100%;
} /* Dark */
.header-style-3.dark .top-bar,
.header-style-3.dark .main-bar {
  background-color: #1d1d1d;
}
.header-style-3.dark .social-line li a {
  border-color: #2f2f2f;
}
.header-style-3.dark .main-bar {
  border-bottom: 0;
} /* Dark End */ /* is Fixed */
.header-style-3 .is-fixed.header-curve .logo-header {
  height: 85px;
} /* Top Bar */
.header-style-3 .fa-chevron-down {
  display: none;
}
.header-style-3 .social-line li a {
  color: #ababab;
  border: 1px solid;
  border-color: #e3e3e3;
  border-width: 0 1px;
  height: 45px;
  line-height: 45px;
  padding: 0 15px;
  display: inline-block;
  min-width: 45px;
  cursor: pointer;
}
.header-style-3 .top-bar {
  border-bottom: 0px;
  background-color: #f5f5f5;
} /* Top Bar End */ /* Responsive CSS */ /* Tablate [991] Responsive CSS */
@media only screen and (max-width: 991px) {
  .header-style-3 .header-nav .nav > li > a {
    padding: 12px 15px;
  }
  .header-style-3 .dez-topbar-left,
  .header-style-3 .dez-topbar-right {
    padding-left: 0;
    padding-right: 0;
  }
  .header-style-3 .dez-topbar-left li a span {
    display: none;
  }
  .header-style-3 .fa-chevron-down {
    display: block;
  }
  .header-style-3 .header-curve .logo-header {
    height: 90px;
  }
  .header-style-3 .is-fixed.header-curve .logo-header {
    height: 70px;
  }
} /* Tablate [991] Responsive CSS End */ /* Responsive CSS End */ /*==================Header Style 3 End==================*/ /*==================Header Style 4==================*/
.header-style-4 {
  margin-bottom: 0px;
}
.header-style-4 .slide-up {
  position: absolute;
  width: 100%;
}
.header-style-4 .header-nav.navbar-collapse ul {
  float: left;
}
.header-style-4 .mostion {
  display: none;
}
.header-style-4 .main-bar {
  background: #fff;
}
.header-style-4 .header-nav .nav > li > a {
  padding: 25px 15px;
}
.header-style-4 .extra-nav {
  padding: 15px 0;
}
.header-style-4 .top-bar {
  padding: 0;
}
.header-style-4.style-1 .top-bar {
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
}
.topbar-info ul {
  list-style: none;
}
.topbar-info ul li {
  display: inline-block;
  padding: 10px 10px 10px 0;
}
.topbar-info ul a {
  color: #727272;
}
.header-style-4.style-1 .social-line li a {
  border: 0;
  padding: 0;
  color: #727272;
}
.header-style-4.style-1 .is-fixed .slide-up {
  bottom: auto;
}
.navigation-bar {
  background-color: #1d1d1d;
}
.navigation-bar .navbar-nav > li > a {
  color: #fff;
}
.header-style-4.style-1 .header-nav .nav > li.active > a:before,
.header-style-4.style-1 .header-nav .nav > li:hover > a:before {
  bottom: 0px;
  content: "";
  height: 3px;
  position: absolute;
  width: 100%;
  left: 0;
}
.header-style-4.style-1 .contact-info h6 {
  color: #1d1d1d;
  font-weight: 400;
}
.header-style-4.style-1 .extra-nav {
  padding: 10px 0;
}
.header-style-4.style-1 .contact-info span {
  font-size: 13px;
  color: #a7a7a7;
}
.header-style-4.style-1 .header-nav .nav > li > a {
  padding: 20px 15px;
  position: relative;
}
.header-style-4.style-1 .header-nav .nav > li > a:after {
  background-color: #4a4a4a;
  bottom: 20px;
  content: "";
  height: 20px;
  margin-top: -10px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 1px;
}
.navigation-bar .navbar-nav > li > a {
  font-weight: bold;
  font-size: 15px;
} /* Dark */
.header-style-4.dark .main-bar {
  background: #2c2c2c;
}
.header-style-4.dark .top-bar {
  border-bottom: 1px solid #1d1d1d;
  background-color: #1d1d1d;
}
.header-style-4.dark .social-line li a {
  border-color: #2c2c2c;
} /* Dark End */ /* Contact-Info Boxed */
.contact-info {
  margin: 0;
  padding: 15px 0;
}
.contact-info li {
  list-style: none;
  width: 25%;
  float: left;
  padding: 11px 0;
}
.contact-info li:last-child {
  padding: 0;
}
.contact-info h6 {
  font-size: 16px;
  margin: 0 0 5px 0;
}
.contact-info li i {
  margin-right: 5px;
  font-size: 18px;
}
.social-line li {
  display: inline-block;
  float: left;
  margin-left: -1px;
} /* Contact-Info Boxed End */ /* Top Bar */
.header-style-4 .fa-chevron-down {
  display: none;
}
.header-style-4 .social-line li a {
  color: #ababab;
  border: 1px solid;
  border-color: #e3e3e3;
  border-width: 0 1px;
  height: 45px;
  line-height: 45px;
  padding: 0 15px;
  display: inline-block;
  min-width: 45px;
  cursor: pointer;
}
.header-style-4 .top-bar {
  border-bottom: 1px solid #e3e3e3;
  background-color: #f5f5f5;
}
.nav-block {
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 #a0a0a0;
  margin-bottom: 0;
  position: relative;
} /* Header Boxed */
.boxed .header-style-4 .top-bar .container {
  padding-left: 15px;
  padding-right: 15px;
}
.boxed .header-style-4 .container.header-contant-block {
  padding-left: 15px;
  padding-right: 15px;
}
.boxed .header-style-4 .nav-block {
  border-radius: 0px;
} /* Header Boxed End */ /* Header is-fixed */
.header-style-4 .is-fixed .header-contant-block {
  display: none;
}
.header-style-4 .is-fixed .main-bar {
  background: #fff;
}
.header-style-4 .is-fixed .nav-block {
  border-radius: 0px;
  box-shadow: none;
  position: unset;
}
.header-style-4.style-1 .dez-quik-search .form-control {
  height: 60px;
} /* Header is-fixed End*/ /* Dark Header */ /* Dark Header End */ /* Responsive CSS */ /* Tablate [991] Responsive CSS */
@media only screen and (max-width: 1280px) {
  .header-style-4.style-1 .site-button text {
    font-size: 13px;
  }
}
@media only screen and (max-width: 991px) {
  .header-style-4 .header-contant-block {
    display: none;
  }
  .header-style-4 {
    margin-bottom: 0px;
  }
  .header-style-4 .nav-block {
    box-shadow: none;
  }
  .header-style-4 .header-nav.navbar-collapse ul {
    float: none;
  }
  .header-style-4 .mostion {
    display: table;
  }
  .header-style-4 .top-bar .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .header-style-4 .header-nav .nav > li > a {
    padding: 12px 15px;
  }
  .header-style-4 .extra-nav {
    padding: 25px 0;
  }
  .header-style-4 .is-fixed .extra-nav {
    padding: 15px 0;
  }
  .header-style-4 .dez-topbar-left,
  .header-style-4 .dez-topbar-right {
    padding-left: 0;
    padding-right: 0;
  }
  .header-style-4 .dez-topbar-left li a span {
    display: none;
  }
  .header-style-4 .fa-chevron-down {
    display: block;
  }
} /* Tablate [991] Responsive CSS End */ /* Mobile [767] Responsive CSS */
@media only screen and (max-width: 991px) {
  .header-style-4 .slide-up .container {
    box-shadow: none;
  }
  .header-style-4 .nav-dark.header-nav .nav > li > a {
    color: #fff;
  }
  .header-style-4.style-1 .extra-nav {
    padding: 15px 0;
  }
  .header-style-4.style-1 .is-fixed .extra-nav {
    padding: 15px 5px;
  }
  .header-style-4.style-1 .navbar-toggle span {
    background: #fff;
  }
  .header-style-4.style-1 .topbar-info ul li {
    padding: 10px;
  }
  .header-style-4.style-1 .header-nav .nav > li.active > a:before,
  .header-style-4.style-1 .header-nav .nav > li:hover > a:before,
  .header-style-4.style-1 .header-nav .nav > li > a:after {
    content: none;
  }
  .header-style-4.style-1 .header-nav .nav > li > a {
    padding: 15px;
  }
  .navigation-bar .navbar-nav > li > a {
    font-weight: normal;
  }
}
@media only screen and (max-width: 767px) {
  .header-style-4 .slide-up {
    position: unset;
  }
  .header-style-4.style-1 .top-bar {
    display: none;
  }
} /* Mobile [767] Responsive CSS End */ /* Responsive CSS End */ /*==================Header Style 4 End==================*/ /*header style 5 (header black overlay) */ /*==================Header Style 5==================*/
.header-style-5 .top-bar {
  background-color: rgba(255, 255, 255, 0);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0;
}
.header-style-5 .social-line li a {
  color: #fff;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 0 1px;
  height: 45px;
  line-height: 45px;
  padding: 0 15px;
  display: inline-block;
  min-width: 45px;
  cursor: pointer;
}
.header-style-5 .main-bar {
  background: rgba(0, 0, 0, 0);
  position: absolute;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.header-style-5 .header-nav .nav > li > a {
  color: #fff;
}
.header-style-5 .navbar-toggle span {
  background: #fff;
}
.header-style-5.dark .main-bar {
  background: rgba(0, 0, 0, 0.5);
} /*header 5 fixed*/
.header-style-5 .is-fixed .social-line li a {
  color: #ababab;
}
.header-style-5 .is-fixed .top-bar,
.header-style-5 .is-fixed .social-line li a {
  border-color: #e3e3e3;
}
.header-style-5 .is-fixed .main-bar {
  position: fixed;
  background: #fff;
}
.header-style-5 .is-fixed .navbar-toggle span {
  background: #666;
}
.header-style-5 .is-fixed .header-nav .nav > li > a {
  color: inherit;
}
.header-style-5 .is-fixed .navbar-toggle span {
  background: #666 !important;
}
.header-style-5 .fa-chevron-down {
  display: none;
} /*header 5 fixed End */ /* Responsive CSS */ /* Tablate [1024] Responsive CSS */
@media only screen and (max-width: 1024px) {
  .header-style-5 .fa-chevron-down {
    display: none;
  }
} /* Tablate [1024] Responsive CSS End */ /* Tablate [991] Responsive CSS */
@media only screen and (max-width: 991px) {
  .header-style-5 .fa-chevron-down {
    display: block;
  }
  .header-style-5 .header-nav .nav > li > a {
    color: unset;
  }
  .header-style-5 .header-nav.nav-dark .nav > li > a {
    color: #fff;
  }
} /* Tablate [991] Responsive CSS End */ /* Mobile [767] Responsive CSS */
@media only screen and (max-width: 767px) {
  .header-style-5 .header-nav .nav > li > a {
    color: #555555;
  }
} /* Mobile [767] Responsive CSS End */ /* Responsive CSS End */ /* Responsive CSS */ /* Tablate [991] Responsive CSS */
@media only screen and (max-width: 991px) {
  .header-style-5 .dez-topbar-left,
  .header-style-5 .dez-topbar-right {
    padding-left: 0;
    padding-right: 0;
  }
  .header-style-5 .dez-topbar-left li a span {
    display: none;
  }
  .header-style-5 .fa-chevron-down {
    display: block;
  }
} /* Tablate [991] Responsive CSS End */ /* Responsive CSS End */ /*==================Header Style 5 End==================*/ /*==================Header Style 6==================*/
.header-style-6 .top-bar {
  background-color: rgba(255, 255, 255, 0);
  padding: 0;
  overflow: hidden;
}
.header-style-6 .social-line li a {
  color: #000;
  height: 45px;
  line-height: 45px;
  padding: 0 10px;
  display: inline-block;
  cursor: pointer;
}
.header-style-6 .main-bar {
  background: rgba(0, 0, 0, 0);
  position: absolute;
}
.header-style-6 .header-nav .nav > li {
  padding: 20px 0;
}
.header-style-6 .header-nav .nav > li > a {
  color: #fff;
  padding: 12px 15px;
  border-radius: 30px;
}
.header-style-6 .header-nav .nav > li.active > a,
.header-style-6 .header-nav .nav > li:hover > a {
  background-color: #ffc300;
  color: #fff;
}
.header-style-6 .navbar-toggle span {
  background: #fff;
}
.header-style-6 .logo-header {
  height: 80px;
}
.header-style-6 .header-bar {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
  margin-bottom: 5px;
}
.header-style-6 .extra-nav {
  padding: 20px 0;
}
.header-style-6 .header-nav .nav > li .sub-menu,
.header-style-6 .header-nav .nav > li .mega-menu {
  margin-top: 20px;
  margin-left: 40px;
}
.header-style-6 .header-nav .nav > li:hover .sub-menu,
.header-style-6 .header-nav .nav > li:hover .mega-menu {
  margin-top: 20px;
  margin-left: 0px;
}
.header-style-6 .dez-quik-search {
  top: 40px;
} /* Top Bar */
.header-style-6 .top-bar-crve,
.header-style-6 .dez-topbar-left,
.header-style-6 .dez-topbar-right {
  position: relative;
  z-index: 2;
}
.header-style-6 .top-bar-crve:before {
  transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -webkit-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  background: #ffc300;
  content: "";
  height: 45px;
  position: absolute;
  right: 0;
  width: 1900px;
  z-index: -1;
}
.header-style-6 .top-bar-crve:after {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
  content: "";
  left: 830px;
  position: absolute;
  top: 5px;
  width: 100%;
  z-index: -2;
}
.header-style-6.dark .main-bar {
  background: rgba(0, 0, 0, 0.5);
} /*header 6 fixed*/
.header-style-6 .is-fixed .social-line li a {
  color: #000;
}
.header-style-6 .is-fixed .top-bar,
.header-style-6 .is-fixed .social-line li a {
  border-color: #e3e3e3;
}
.header-style-6 .is-fixed .main-bar {
  box-shadow: 0 0 5px 0 #808080;
  position: fixed;
  background: #fff;
}
.header-style-6 .is-fixed .navbar-toggle span {
  background: #666;
}
.header-style-6 .is-fixed .header-nav .nav > li > a {
  color: inherit;
}
.header-style-6 .is-fixed .navbar-toggle span {
  background: #666 !important;
}
.header-style-6 .fa-chevron-down {
  display: none;
}
.header-style-6 .is-fixed .header-bar,
.header-style-6 .is-fixed .top-bar-crve::after {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
} /*header 6 fixed End */ /* Responsive CSS */ /* Tablate [1024] Responsive CSS */
@media only screen and (max-width: 1024px) {
  .header-style-6 .fa-chevron-down {
    display: none;
  }
  .header-style-6 .top-bar-crve:before {
    transform: skew(0);
    -moz-transform: skew(0);
    -webkit-transform: skew(0);
    -ms-transform: skew(0);
    -o-transform: skew(0);
  }
} /* Tablate [1024] Responsive CSS End */ /* Tablate [991] Responsive CSS */
@media only screen and (max-width: 991px) {
  .header-style-6 .fa-chevron-down {
    display: block;
  }
  .header-style-6 .dez-topbar-left,
  .header-style-6 .dez-topbar-right {
    padding-left: 0;
    padding-right: 0;
  }
  .header-style-6 .dez-topbar-left li a span {
    display: none;
  }
  .header-style-6 .fa-chevron-down {
    display: block;
  }
  .header-style-6 .navbar-toggle {
    margin: 19px 0 19px 15px;
  }
  .header-style-6 .header-nav .nav > li.active > a,
  .header-style-6 .header-nav .nav > li:hover > a {
    color: inherit;
    background: inherit;
  }
  .header-style-6 .header-nav .nav > li > a {
    border-radius: 0;
    color: inherit;
  }
  .header-style-6 .header-nav .nav > li {
    padding: 0;
  }
  .header-style-6 .header-nav .nav > li:hover .sub-menu,
  .header-style-6 .header-nav .nav > li:hover .mega-menu {
    margin-top: 0;
  }
} /* Tablate [991] Responsive CSS End */ /* Mobile [767] Responsive CSS */
@media only screen and (max-width: 767px) {
  .header-style-6 .header-nav .nav > li > a {
    color: #555555;
  }
  .header-style-6 .extra-nav {
    clear: none;
    width: auto;
  }
  .header-style-6 .is-fixed .logo-header {
    width: 170px;
  }
} /* Mobile [767] Responsive CSS End */ /* Responsive CSS End */ /*==================Header Style 6 End==================*/ /*==================Header Style 9==================*/
.header-style-7 .fa-chevron-down {
  display: none;
}
.header-style-7 .top-btn a {
  background: #f0ba22;
  border: 10px solid #2d313a;
  width: 65px;
  height: 65px;
  display: block;
  margin: 0px auto;
  color: #fff;
  text-align: center;
  line-height: 45px;
  font-size: 20px;
  transform: rotate(45deg);
  margin: -62px auto 0;
}
.header-style-7 .social-line li a {
  color: #ababab;
  border: 1px solid #e3e3e3;
  border-width: 0 1px;
  height: 45px;
  line-height: 45px;
  padding: 0 15px;
  display: inline-block;
  min-width: 45px;
  cursor: pointer;
}
.header-style-7 .top-bar {
  border-bottom: 1px solid #e3e3e3;
  padding: 0;
  background-color: #f5f5f5;
}
.header-style-7 .extra-nav {
  padding: 15px 0 15px;
}
.header-style-7 .header-nav .nav > li > a {
  padding: 25px 12px;
  color: #fff;
}
.header-style-7 .logo-header {
  height: 70px;
  width: 170px;
}
.header-style-7 .header-nav .nav > li.active > a,
.header-style-7 .header-nav .nav > li:hover > a,
.header-style-7 .header-nav .nav > li.current-menu-item > a {
  background-color: rgba(0, 0, 0, 0.2);
  border-top: 2px solid;
}
.header-style-7 .dez-quik-search .form-control {
  height: 72px;
} /* header style dark 1 */
.header-style-7.dark .main-bar {
  background-color: #262626;
}
.header-style-7.dark .top-bar {
  background-color: #1d1d1d;
  border-color: #262626;
}
.header-style-7.dark .social-line li a {
  border-color: #262626;
} /* header style dark 1 End */ /* Responsive CSS */ /* Tablate [991] Responsive CSS */
@media only screen and (max-width: 991px) {
  .header-style-7 .top-bar {
    display: none;
  }
  .header-style-7 .dez-topbar-left,
  .header-style-7 .dez-topbar-right {
    padding-left: 0;
    padding-right: 0;
  }
  .header-style-7 .dez-topbar-left li a span {
    display: none;
  }
  .header-style-7 .fa-chevron-down {
    display: block;
  }
  .header-style-7 .navbar-toggle {
    margin: 14px 0 14px 5px;
  }
  .header-style-7.dark .header-nav .nav > li > a {
  }
  .header-style-7 .header-nav .nav > li.active > a,
  .header-style-7 .header-nav .nav > li:hover > a,
  .header-style-7 .header-nav .nav > li.current-menu-item > a {
    border: 0;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .header-style-7 .header-nav .nav > li > a {
  }
  .header-style-7 .header-nav .nav > li > a {
    padding: 12px 15px;
    color: #333;
  }
  .header-style-7 .header-nav .nav > li > a {
    color: #fff;
  }
  .header-style-7 .navbar-toggle span {
    color: #fff;
  }
} /* Tablate [991] Responsive CSS End */ /* Responsive CSS End */ /*==================Header Style 9 End==================*/ /*---------------------------------------------------------------19. BANNER---------------------------------------------------------------*/
.dez-banner-row,
.dez-banner-outer {
  position: relative;
}
.dez-bnr-pic {
  width: 100%;
  overflow: hidden;
}
.dez-bnr-pic img {
  width: 100%;
  height: auto;
  display: block;
}
.dez-bnr-text {
  position: absolute;
  left: 0;
  top: 35%;
  width: 100%;
  margin: 0;
  font-family: Raleway;
  color: #ffffff;
  font-family: "Raleway";
}
.dez-bnr-text-has {
  max-width: 600px;
}
.dez-bnr-text strong,
.dez-bnr-text span {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.dez-bnr-text strong {
  display: block;
  font-size: 60px;
  font-weight: 700;
  line-height: 70px;
  margin: 0 0 25px;
  color: #ffffff;
}
.dez-bnr-text span {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  display: block;
  margin-bottom: 30px;
}
.dez-bnr-text .btn {
  margin-right: 30px;
}
@media only screen and (max-width: 1200px) {
  .dez-bnr-text strong {
    font-size: 60px;
    line-height: 60px;
    margin: 0 0 15px;
  }
  .dez-bnr-text span {
    font-size: 20px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 991px) {
  .dez-bnr-text strong {
    font-size: 50px;
    line-height: 50px;
    margin: 0 0 10px;
  }
}
@media only screen and (max-width: 767px) {
  .dez-bnr-text strong {
    font-size: 40px;
    line-height: 40px;
    margin: 0 0 0;
  }
}
@media only screen and (max-width: 680px) {
  .dez-bnr-text strong {
    font-size: 30px;
    line-height: 30px;
    margin: 0 0 0;
  }
  .dez-bnr-text span {
    font-size: 14px;
    line-height: 18px;
  }
}
@media only screen and (max-width: 480px) {
  .dez-bnr-text strong {
    font-size: 20px;
    line-height: 20px;
  }
  .dez-bnr-text span {
    font-size: 12px;
    line-height: 16px;
  }
} /*==> inner page banner==*/
.dez-bnr-inr {
  background-position: center center;
  background-size: cover;
  display: table;
  height: 450px;
  padding-bottom: 60px;
  padding-top: 130px;
  width: 100%;
}
.dez-bnr-inr .container {
  display: table;
  height: 100%;
}
.dez-bnr-inr-entry {
  display: table-cell;
  vertical-align: middle;
}
.banner-inner-row h1,
.banner-inner-row h2,
.banner-inner-row h3,
.banner-inner-row h4,
.banner-inner-row h5,
.banner-inner-row h6 {
  color: #fff;
} /*==> Provider banner off==*/
.banner-inner-row.provider-banner-off {
  height: 150px;
  background-image: url("file:///C|/Users/admin/Documents/inc/images/pattern/pattern1.jpg") !important;
  background-repeat: repeat;
  background-size: auto;
}
.provider-banner-off.overlay-black-middle::after {
  opacity: 0.1;
} /*---------------------------------------------------------------20. SLIDER>rs slider>owl slider---------------------------------------------------------------*/ /*==>rs slider==*/
.tp-caption .font-weight-300 {
  font-weight: 300;
}
.tp-caption .font-weight-400 {
  font-weight: 400;
}
.tp-caption .font-weight-500 {
  font-weight: 500;
}
.tp-caption .font-weight-600 {
  font-weight: 600;
}
.tp-caption .font-weight-700 {
  font-weight: 700;
}
.tp-caption .font-weight-800 {
  font-weight: 800;
}
.tp-caption .font-weight-900 {
  font-weight: 900;
}
.tp-caption h1,
.tp-caption h2,
.tp-caption h3,
.tp-caption h4,
.tp-caption h5,
.tp-caption h6 {
  font-weight: 800;
  margin-bottom: 24px;
  color: #ffffff;
}
.tp-caption h1 {
  font-size: 70px;
  line-height: 60px;
}
.tp-caption h2 {
  font-size: 60px;
  line-height: 50px;
}
.tp-caption h3 {
  font-size: 50px;
  line-height: 40px;
  margin-bottom: 20px;
}
.tp-caption h4 {
  font-size: 40px;
  line-height: 30px;
  margin-bottom: 15px;
}
.tp-caption h5 {
  font-size: 30px;
  line-height: 4px;
  margin-bottom: 10px;
}
.tp-caption h6 {
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 10px;
}
.tp-caption h4,
.tp-caption h5,
.tp-caption h6 {
  font-weight: 600;
}
.tp-caption .text {
  color: #ffffff;
  font-size: 20px;
  line-height: 24px;
  width: 500px;
}
.overlay-row {
  background: #000 none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
} /*==>owl slider==*/ /* next pre btn */
.owl-none .owl-nav {
  display: none;
}
.owl-theme .owl-nav,
.owl-theme .owl-dots {
  margin-top: 20px;
  text-align: center;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
} /* owl dots button */
.owl-theme .owl-dots {
  text-align: center;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
} /* owl dots style */
.owl-theme.dots-style-1 .owl-dots .owl-dot {
}
.owl-theme.dots-style-1 .owl-dots .owl-dot span,
.owl-theme.dots-style-2 .owl-dots .owl-dot span {
  background: rgba(0, 0, 0, 0.2);
  width: 8px;
  height: 8px;
  margin: 5px;
}
.owl-theme.dots-style-1 .owl-dots .owl-dot.active span,
.owl-theme.dots-style-1 .owl-dots .owl-dot:hover span {
  background: #000;
} /* owl dots style 3 */
.owl-theme.dots-style-3 .owl-dots {
  margin-top: 30px;
  position: unset;
  right: auto;
  text-align: center;
  top: auto;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: auto;
}
.owl-theme.dots-style-3 .owl-dots .owl-dot span {
  height: 8px;
  width: 8px;
  margin: 0;
}
.owl-theme.dots-style-3 .owl-dots .owl-dot {
  border: 1px solid;
  border-radius: 20px;
  display: inline-block;
  margin: 4px;
  padding: 3px;
}
.owl-theme.dots-style-3 .owl-dots .owl-dot.active,
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover {
  border: 1px solid #ff3b68;
}
.owl-theme.dots-style-3 .owl-dots .owl-dot.active span,
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover span {
  background-color: #ff3b68;
}
.owl-item.dots-style-3 .slide-content-box {
  opacity: 0;
}
.owl-item.active.dots-style-3 .slide-content-box {
  opacity: 1;
  transition: all 2s ease 2s;
  -moz-transition: all 2s ease 2s;
  -webkit-transition: all 2s ease 2s;
  -o-transition: all 2s ease 2s;
} /*owl button top to center */
.owl-btn-top-c .owl-nav {
  text-align: center;
} /*owl button top to left */
.owl-btn-top-l .owl-nav {
  text-align: left;
}
.owl-btn-top-l .owl-nav .owl-prev {
  margin-left: 0;
} /*owl button top to right */
.owl-btn-top-r .owl-nav {
  text-align: right;
}
.owl-btn-top-r .owl-nav .owl-next {
  margin-right: 0;
} /*owl button top to left & right */
.owl-btn-top-lr .owl-nav {
  text-align: left;
}
.owl-btn-top-lr .owl-nav .owl-next {
  margin-right: 0;
  float: right;
}
.owl-btn-full .owl-prev,
.owl-btn-full .owl-next {
  padding: 40px 8px;
} /*owl button < > CENTER to left & right */
.owl-btn-center-lr .owl-nav {
  margin: 0;
}
.owl-btn-center-lr + .owl-theme .owl-nav {
  margin: 0;
}
.owl-btn-center-lr .owl-nav .owl-prev,
.owl-btn-center-lr .owl-nav .owl-next {
  position: absolute;
  left: 0;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.owl-btn-center-lr .owl-nav .owl-next {
  left: auto;
  right: 0;
}
.owl-carousel .owl-item img {
  transform-style: inherit;
} /* Owl Button Style */
.owl-btn-1 .owl-prev,
.owl-btn-1 .owl-next,
.owl-btn-2 .owl-prev,
.owl-btn-2 .owl-next,
.owl-btn-3 .owl-prev,
.owl-btn-3 .owl-next {
  border-radius: 3px;
  height: 40px;
  line-height: 40px;
  margin: 0 10px !important;
  padding: 0;
  text-align: center;
  width: 40px;
}
.owl-btn-2 .owl-prev,
.owl-btn-2 .owl-next,
.owl-btn-3 .owl-prev,
.owl-btn-3 .owl-next {
  margin: 0 -70px !important;
}
.owl-btn-3 .owl-prev,
.owl-btn-3 .owl-next {
  background-color: rgba(0, 0, 0, 0);
  color: #000000;
  font-size: 35px;
  height: auto;
  width: auto;
  opacity: 0.2;
}
.owl-btn-3 .owl-prev:hover,
.owl-btn-3 .owl-next:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #000;
  opacity: 1;
}
.owl-btn-3.btn-white .owl-prev,
.owl-btn-3.btn-white .owl-next {
  color: #fff;
}
.owl-btn-3.btn-white .owl-prev:hover,
.owl-btn-3.btn-white .owl-next:hover {
  color: #fff;
}
.owl-btn-4 {
  overflow: hidden;
}
.owl-btn-4 .owl-prev,
.owl-btn-4 .owl-next {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 30px;
  box-shadow: 0 0 20px 7px rgba(0, 0, 0, 0.5);
  color: #000000;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  margin: 0 -10px !important;
  overflow: hidden;
  padding: 0;
  width: 50px;
  opacity: 0.7;
}
.owl-btn-4 .owl-prev:hover,
.owl-btn-4 .owl-next:hover {
  background: #ffffff none repeat scroll 0 0;
  color: #000;
  opacity: 1;
} /* Thumb */
#sync2 .owl-item.active.current .item img {
  outline: 5px solid rgba(0, 0, 0, 0.2);
  outline-offset: -9px;
} /* owl-dots */
.owl-dots-none .owl-dots {
  display: none;
}
.owl-dots-primary-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-black-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-white-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-primary-big.owl-theme .owl-dots .owl-dot span,
.owl-dots-black-big.owl-theme .owl-dots .owl-dot span,
.owl-dots-white-big.owl-theme .owl-dots .owl-dot span {
  opacity: 0.5;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  margin: 4px;
}
.owl-dots-primary-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-primary-big.owl-theme .owl-dots .owl-dot span {
  background-color: #1abc9c;
}
.owl-dots-black-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-black-big.owl-theme .owl-dots .owl-dot span {
  background-color: #333;
}
.owl-dots-white-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-white-big.owl-theme .owl-dots .owl-dot span {
  background-color: #f5f5f5;
}
.owl-dots-primary-full.owl-theme .owl-dots .owl-dot.active span,
.owl-dots-black-full.owl-theme .owl-dots .owl-dot.active span,
.owl-dots-white-full.owl-theme .owl-dots .owl-dot.active span {
  opacity: 1;
  width: 25px;
}
.owl-dots-primary-big.owl-theme .owl-dots .owl-dot.active span,
.owl-dots-black-big.owl-theme .owl-dots .owl-dot.active span,
.owl-dots-white-big.owl-theme .owl-dots .owl-dot.active span {
  width: 14px;
  height: 14px;
  opacity: 1;
  margin: 0px 4px 2px 4px;
}
@media only screen and (max-width: 1200px) {
  .owl-btn-2 .owl-prev,
  .owl-btn-2 .owl-next,
  .owl-btn-3 .owl-prev,
  .owl-btn-3 .owl-next {
    margin: 0 10px !important;
  }
} /*---------------------------------------------------------------21. BLOG---------------------------------------------------------------*/
.blog-post {
  position: relative;
  margin-bottom: 30px;
}
.dez-post-info {
}
.dez-post-title {
  margin-bottom: 10px;
}
.dez-post-title .post-title {
  margin-top: 15px;
}
.dez-post-meta {
  margin-bottom: 10px;
}
.dez-post-meta ul {
  margin: 0 -4px;
  list-style: none;
}
.dez-post-meta ul li {
  padding: 0;
  display: inline-block;
}
.dez-post-meta li:after {
  content: "/";
  display: inline-block;
  font-weight: normal;
  margin-left: 5px;
  opacity: 0.5;
}
.dez-post-meta li:last-child:after {
  display: none;
}
.dez-post-meta a {
  color: #a9a9a9;
}
.dez-post-meta i {
  color: #7b7b7b;
  margin: 0 5px;
}
.dez-post-text {
  margin-bottom: 15px;
}
.dez-post-text p:last-child {
  margin: 0;
}
.dez-post-readmore {
  margin-bottom: 15px;
}
.dez-post-tags {
  border-top: 1px solid #e9e9e9;
  padding-top: 10px;
}
.dez-post-tags .post-tags {
  margin: 0 -3px;
  list-style: none;
}
.dez-post-tags .post-tags a {
  border: 1px solid #ddd;
  padding: 2px 8px;
  color: #a9a9a9;
  margin: 0 3px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
} /*==post date syle css==*/
.date-style-1 .post-date {
  color: #fff;
  background-color: #efbb20;
  padding: 3px 5px 3px 5px;
}
.date-style-1 .post-date .fa {
  color: #fff;
  left: 5px;
  top: 5px;
}
.date-style-2 .post-date,
.date-style-4 .post-date {
  color: #fff;
  position: absolute;
  left: 20px;
  top: 0;
  width: 50px;
  text-align: center;
  text-transform: uppercase;
}
.date-style-2 .post-date strong,
.date-style-2 .post-date span,
.date-style-4 .post-date strong,
.date-style-4 .post-date span {
  display: block;
  padding: 10px 5px;
}
.date-style-2 .post-date strong,
.date-style-4 .post-date strong {
  font-size: 10px;
  padding: 10px 5px 5px;
  position: relative;
}
.date-style-2 .post-date > strong:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 1px;
  background-color: #fff;
  margin: 0 -10px;
  width: 20px;
}
.date-style-2 .post-date span {
  font-size: 16px;
  padding: 5px 5px 10px;
}
.date-style-2 .post-date:after,
.date-style-2 .post-date .fa,
.date-style-3 .post-date:after,
.date-style-3 .post-date .fa,
.date-style-4 .post-date .fa {
  display: none;
}
.date-style-3 .dez-post-info {
  position: relative;
}
.date-style-3 .post-date {
  font-family: oswald;
  text-transform: uppercase;
  position: absolute;
  padding: 8px 15px;
  color: #fff;
  top: -36px;
  left: 0;
  height: 36px;
}
.date-style-3 .post-date strong {
  color: #30353c;
  margin-right: 2px;
}
.date-style-3.skew-date .post-date {
  padding-right: 8px;
}
.date-style-3.skew-date .post-date:before {
  content: "";
  position: absolute;
  right: -15px;
  top: 0;
  width: 22px;
  height: 100%;
  border-right: 3px solid #2d3239;
  -moz-transform: skewX(20deg);
  -webkit-transform: skewX(20deg);
  -o-transform: skewX(20deg);
  -ms-transform: skewX(20deg);
  transform: skewX(20deg);
}
.date-style-4 .post-date span {
  background-color: #1d1d1d;
}
.date-style-4 .post-date {
  top: 10px;
  left: 10px;
  font-family: "Roboto Slab", serif;
}
.date-style-4 .dez-post-info {
  box-shadow: 0 3px 0 0 #dbdbdb;
}
.date-style-4 .dez-post-meta ul li {
  font-weight: normal;
} /*==post without media==*/
.no-image-blog.date-style-2 .algo-post-info {
  padding-top: 70px;
}
.no-image-blog.date-style-2 .post-date {
  top: 0;
  left: 20px;
} /*if no image with blog post*/
.blog-post.no-img-post .post-date {
  position: static;
  float: left;
} /*blog post half iamge*/
.blog-md .dez-post-media {
  width: 350px;
  float: left;
  margin-right: 30px;
}
.blog-md .dez-post-info {
  border: none;
}
.blog-md .dez-post-tags {
  border: none;
  display: inline-block;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .blog-md.blog-post .dez-post-media {
    float: none;
    margin: 0 0 20px;
    width: auto;
  }
} /*Blog page pagination main*/
.nav-links:after {
  clear: both;
  content: "";
  display: table;
}
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}
.pagination .page-numbers {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  color: #767676;
  padding: 9px 15px;
  font-weight: 600;
  font-size: 12px;
  display: block;
  float: left;
  margin-left: -1px;
}
.pagination .page-numbers.current,
.pagination a.page-numbers:hover {
  background: #3396d1;
  color: #fff;
} /*Blog paging Next & Prev css*/
.nav-links .nav-previous a,
.nav-links .nav-next a {
  display: inline-block;
  color: #3396d1;
  font-weight: 600;
  margin-bottom: 20px;
  outline: none !important;
}
.nav-links .nav-previous a:hover,
.nav-links .nav-next a:hover {
  color: #0099cc;
}
.nav-links .nav-previous a:after,
.nav-links .nav-next a:after {
  content: "\f105";
  font-family: "FontAwesome";
  vertical-align: middle;
  margin-left: 2px;
  color: #333;
} /*Post navigation*/
.post-navigation {
  background-color: #fff;
  padding: 30px;
  border-bottom: 2px solid #e3e3e3;
}
.post-navigation .screen-reader-text {
  margin-top: 0;
}
.post-navigation .nav-previous,
.post-navigation .nav-next {
  display: inline-block;
  width: 50%;
}
.post-navigation .nav-next {
  text-align: right;
}
.post-navigation .nav-previous a:after,
.post-navigation .nav-next a:after {
  display: none;
}
.post-navigation .nav-previous .post-title,
.post-navigation .nav-next .post-title {
  display: block;
  font-size: 15px;
  color: #777777;
  margin-top: 10px;
} /*post panging*/
.dez-link-pages {
  padding: 20px 0 30px;
}
.dez-link-pages a {
  color: #fff;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 600;
  background-color: #ccc;
  margin: 0 2px;
} /*Sticky Post*/
.sticky-post {
  position: absolute;
  right: 0;
  top: 0;
  background: #77c04b;
  color: #fff;
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: 600;
} /*Post password form*/
.post-password-form {
  position: relative;
  clear: both;
}
.post-password-form input[type="password"] {
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px 100px 10px 15px;
  height: 40px;
}
.post-password-form label {
  display: block;
}
.post-password-form input[type="submit"] {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  height: 40px;
  text-transform: uppercase;
  font-weight: 600;
} /*gallery css for blog post*/
.blog [class*="galleryid-"] {
  clear: both;
  margin: 0 auto;
  overflow: hidden;
}
.gallery .gallery-item {
  float: left;
  list-style: none;
  margin: 0px 0 0;
  overflow: hidden;
  padding: 0px 0px 5px 15px;
  text-align: center;
}
.gallery .gallery-caption {
  padding-top: 10px;
  font-size: 13px;
}
.gallery .gallery-item img {
  float: left;
  padding: 0 0px;
  width: 100%;
  border: none !important;
}
.gallery-columns-2 .gallery-item {
  max-width: 50%;
}
.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}
.gallery-columns-4 .gallery-item {
  max-width: 25%;
}
.gallery-columns-5 .gallery-item {
  max-width: 20%;
}
.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}
.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}
.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}
.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}
.gallery-icon img {
  margin: 0 auto;
}
.gallery-caption {
  color: #707070;
  color: rgba(51, 51, 51, 0.7);
  display: block;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 0.5em 0;
}
.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
} /*side bar*/
.side-bar .widget {
  margin-bottom: 30px;
}
@media only screen and (max-width: 991px) {
  .side-bar {
    margin-bottom: 40px;
  }
} /*Blog single*/
.blog-single .dez-post-meta {
  margin-bottom: 20px;
}
.blog-single .dez-post-text {
  margin-top: 20px;
}
.blog-single .dez-post-tags {
  margin-top: 20px;
} /*=comment list=*/
.comments-area {
  padding: 0;
}
.comments-area .comments-title {
  text-transform: uppercase;
  font-size: 20px;
}
.comments-area .comments-title:first-letter {
  font-family: "Open Sans";
  font-weight: 600;
  color: #efbb20;
}
ol.comment-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
ol.comment-list li.comment {
  position: relative;
  padding: 0;
}
ol.comment-list li.comment .comment-body {
  position: relative;
  padding: 20px;
  margin-bottom: 40px;
  margin-left: 80px;
  position: relative;
  border: 1px solid #e9e9e9;
}
ol.comment-list li.comment .comment-body:before,
ol.comment-list li.comment .comment-body:after {
  content: "";
  position: absolute;
  top: 20px;
  width: 0;
  height: 0;
  border-width: 10px 12px 10px 0;
  border-style: solid;
}
ol.comment-list li.comment .comment-body:before {
  border-color: transparent #fff transparent #fff;
  z-index: 1;
  left: -12px;
  top: 22px;
}
ol.comment-list li.comment .comment-body:after {
  border-color: transparent #e9e9e9 transparent #e9e9e9;
  border-width: 12px 14px 12px 0;
  left: -14px;
}
ol.comment-list li.comment .comment-author {
  display: block;
  margin-bottom: 10px;
}
ol.comment-list li.comment .comment-author .avatar {
  position: absolute;
  top: 0;
  left: -80px;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  border: 2px solid #fff;
}
ol.comment-list li.comment .comment-author .fn {
  display: inline-block;
  color: #555555;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  font-style: normal;
}
ol.comment-list li.comment .comment-author .says {
  display: none;
  color: #999999;
  font-weight: 600;
}
ol.comment-list li.comment .comment-meta {
  color: #8d8d8d;
  text-transform: uppercase;
  margin-bottom: 15px;
}
ol.comment-list li.comment .comment-meta a {
  color: #8d8d8d;
}
ol.comment-list li.comment .comment-meta a {
  color: #8d8d8d;
}
ol.comment-list li.comment .comment-meta:before,
ol.comment-list li.comment .reply a:before {
  font-family: "FontAwesome";
  font-size: 11px;
  vertical-align: top;
}
ol.comment-list li.comment .comment-meta:before {
  content: "\f133";
}
ol.comment-list li.comment .reply a:before {
  content: "\f064";
  font-weight: normal;
  color: #555555;
  margin-right: 5px;
  vertical-align: middle;
}
ol.comment-list li.comment p {
  line-height: 24px;
  margin: 0 0 5px;
}
ol.comment-list li.comment .reply a {
  position: absolute;
  top: 50px;
  right: 30px;
  margin-top: -5px;
  color: #efbb20;
  font-weight: 600;
}
ol.comment-list li .children {
  list-style: none;
  margin-left: 80px;
}
ol.comment-list li .children li {
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .comments-area .padding-30 {
    padding: 15px;
  }
  ol.comment-list li.comment .comment-body {
    margin-bottom: 30px;
    margin-left: 70px;
  }
  ol.comment-list li.comment .comment-author .avatar {
    left: -75px;
    height: 60px;
    width: 60px;
  }
  ol.comment-list li .children {
    margin-left: 20px;
  }
  ol.comment-list li.comment .reply a {
    position: static;
  }
}
@media only screen and (max-width: 480px) {
  ol.comment-list li.comment .comment-body {
    margin-left: 52px;
  }
  ol.comment-list li.comment .comment-author .avatar {
    left: -55px;
    top: 12px;
    width: 40px;
    height: 40px;
  }
} /*=comment form=*/
.comment-respond {
  padding: 30px 30px;
  border: 1px solid #e9e9e9;
}
.comment-respond .comment-reply-title {
  text-transform: uppercase;
  font-size: 20px;
}
.comment-respond .comment-reply-title {
  font-size: 16px;
  font-weight: 600;
}
.comments-area .comment-form {
  margin: 0 -15px;
}
.comments-area .comment-form .comment-notes {
  display: none;
}
.comments-area .comment-form p {
  width: 33.333%;
  float: left;
  padding: 0 15px;
  margin-bottom: 30px;
  position: relative;
}
.comments-area .comment-form p.form-allowed-tags {
  width: 100%;
}
ol.comment-list li.comment .comment-respond .comment-form p {
  padding: 0 15px !important;
}
.comments-area .comment-form p label {
  display: none;
  line-height: 18px;
  margin-bottom: 10px;
}
.comments-area p:before {
  font-family: "FontAwesome";
  display: inline-block;
  position: absolute;
  left: 15px;
  top: 0;
  font-size: 16px;
  color: #ccc;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #e1e6eb;
  text-align: center;
  border-radius: 4px 0 0 4px;
}
.comments-area p.comment-form-author:before {
  content: "\f007";
}
.comments-area p.comment-form-email:before {
  content: "\f0e0";
}
.comments-area p.comment-form-url:before {
  content: "\f0ac";
}
.comments-area p.comment-form-comment:before {
  content: "\f040";
}
.comments-area .comment-form p input[type="text"],
.comments-area .comment-form p textarea {
  width: 100%;
  height: 40px;
  line-height: 6px 12px;
  padding: 10px 10px 10px 50px;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}
.comments-area .comment-form p.comment-form-comment {
  width: 100%;
  display: block;
  clear: both;
}
.comments-area .comment-form p textarea {
  height: 120px;
}
.comments-area .comment-form p.form-submit {
  clear: both;
  float: none;
  width: 100%;
  margin: 0;
}
.comments-area .comment-form p input[type="submit"] {
  background-color: #efbb20;
  border: none;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 10px 20px;
  text-transform: uppercase;
}
.comments-area .comment-form p input[type="submit"]:hover,
.comments-area .comment-form p input[type="submit"]:focus,
.comments-area .comment-form p input[type="submit"]:active {
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-form p {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
  .comment-respond {
    padding: 20px;
  }
} /*---------------------------------------------------------------22. WIDGETS---------------------------------------------------------------*/
.widget {
  margin-bottom: 40px;
}
.widget-title {
  margin: 0 0 25px;
}
.widget-title {
  padding-bottom: 15px;
  position: relative;
  margin-bottom: 30px;
}
.widget-title:after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 0;
  height: 3px;
  margin: 0 0 0;
  width: 50px;
  margin: 10px 0;
}
.widget .widget-title,
.widget .post-title {
  text-transform: uppercase;
}
.recent-posts-entry ul,
.category-entry ul {
  margin: 0;
  list-style: none;
  padding: 0;
} /* widget listing*/
.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_rss ul,
.widget_recent_entries ul,
.widget_services ul,
.widget_getintuch ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.widget_categories ul li,
.widget_archive ul li,
.widget_meta ul li,
.widget_pages ul li,
.widget_recent_comments ul li,
.widget_nav_menu li,
.widget_recent_entries ul li,
.widget_services ul li {
  padding-bottom: 18px;
  margin-bottom: 13px;
  border-bottom: 1px solid rgba(102, 102, 102, 0.11);
  position: relative;
  padding: 10px 10px 10px 15px;
  margin-bottom: 0;
  line-height: 20px;
}
.widget_categories ul li a,
.widget_archive ul li a,
.widget_meta ul li a,
.widget_pages ul li a,
.widget_recent_comments ul li a,
.widget_nav_menu li a,
.widget_recent_entries ul li a,
.widget_services ul li a {
  color: #767676;
}
.widget_categories ul li:before,
.widget_archive ul li:before,
.widget_meta ul li:before,
.widget_pages ul li:before,
.widget_recent_comments ul li:before,
.widget_nav_menu ul li:before,
.widget_recent_entries ul li:before,
.widget_services ul li:before {
  content: "\f105";
  position: absolute;
  left: 0;
  top: 10px;
  display: block;
  font-family: "FontAwesome";
}
.widget_categories ul li li,
.widget_archive ul li li,
.widget_meta ul li li,
.widget_pages ul li li,
.widget_recent_comments ul li li,
.widget_nav_menu li li,
.widget_services li li {
  border-bottom: none;
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 28px;
}
.widget_categories ul li li:before,
.widget_archive ul li li:before,
.widget_meta ul li li:before,
.widget_pages ul li li:before,
.widget_recent_comments ul li li:before,
.widget_nav_menu li li:before,
.widget_services li li:before {
  top: 0;
  left: -8px;
} /*widget search custom*/
.search-bx .site-button {
  padding: 9px 12px;
  border: 1px solid transparent;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
} /*widget search wp-default*/
.widget_search .screen-reader-text {
  display: block;
}
.searchform {
  position: relative;
}
.searchform input[type="text"] {
  width: 100%;
  height: 40px;
  padding: 10px 90px 10px 15px;
  border: 1px solid #cccccc;
}
.searchform input[type="submit"] {
  height: 40px;
  padding: 10px 15px;
  background-color: #77c04b;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #ffffff;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}
.searchform input[type="submit"]:hover,
.searchform input[type="submit"]:focus,
.searchform input[type="submit"]:active {
  background-color: #6ab33e;
  color: #ffffff !important;
  border-bottom-color: #5a9e2f;
} /*widget recent-posts*/
.recent-posts-entry .post-date,
.tweets-feed-entry .tweet-date {
  color: #3396d1;
  font-style: normal;
}
.widget .post-title {
  line-height: 16px;
  margin-bottom: 8x;
}
.recent-posts-entry .widget-post {
  margin-bottom: 10px;
  display: flex;
}
.recent-posts-entry .widget-post-bx:last-child {
  border-bottom: none;
}
.recent-posts-entry .dez-post-media {
  float: left;
  width: 90px;
  max-width: 90px;
  min-width: 90px;
  align-self: center;
}
.recent-posts-entry .dez-post-info {
  background: transparent;
  padding: 0;
  margin-left: 15px;
  border: none;
  align-self: center;
}
.recent-posts-entry .dez-post-meta {
  margin-bottom: 0;
}
.recent-posts-entry .post-meta span {
  margin-right: 10px;
} /*widget recent-comment*/
.widget_recent_comments ul li:before {
  content: "\f0e6";
}
.widget_recent_comments ul li {
  padding-left: 20px;
  color: #999;
} /* widget meta*/
.widget_meta ul li a abbr[title] {
  color: #333;
  border-bottom: none;
} /*widget calender*/
.widget_calendar caption::after {
  color: #707070;
  content: ">";
  font-family: "FontAwesome";
  margin: 0 0 0 5px;
}
.widget_calendar table {
  border-collapse: separate;
  border-spacing: 2px;
  width: 100%;
}
.widget_calendar thead {
  background-color: #999999;
}
.widget_calendar tfoot tr td {
  border: none;
  padding: 0px;
}
.widget_calendar tfoot tr td a {
  background-color: #fff;
  padding: 4px 10px;
}
.widget_calendar table thead tr th {
  font-size: 11px;
  padding: 5px;
  text-align: center;
  border: none;
  color: #fff;
}
.widget_calendar table tbody td {
  font-size: 13px;
  padding: 6px 5px;
  text-align: center;
  background-color: #fff;
  border: none;
  color: #444;
}
.widget_calendar table tbody td#today {
  background-color: var(--primary);
  color: #fff;
}
.widget_calendar table tbody td#today a {
  color: #fff;
} /*widget tags-list*/
.widget_tag_cloud a {
  padding: 8px 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  font-size: 12px;
  display: inline-block;
  margin: 0 0 5px;
  color: #555555;
}
.side-bar .widget_tag_cloud a:hover {
  background-color: #cccccc;
} /*widget archive*/
.widget_archive select {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
} /*widget text*/
.widget_text select {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
}
.widget_text select option {
  width: 100%;
} /*widget categories*/
.widget_categories li {
  text-align: right;
}
.widget_categories li a {
  float: left;
} /*widget RSS*/
.widget_rss ul {
  margin: 0;
  line-height: 20px;
}
.widget_rss ul li {
  line-height: 20px;
  margin-bottom: 15px;
}
.widget_rss ul .rsswidget {
  color: #333;
}
.widget_rss ul .rss-date {
  color: #3396d1;
}
.widget_rss ul .rssSummary {
  padding: 5px 0;
}
.widget_rss ul cite {
  color: #333;
  font-weight: 600;
} /* widget get in tuch */
.widget_getintuch {
  padding-top: 10px;
}
.widget_getintuch li {
  margin-bottom: 20px;
  position: relative;
  padding-left: 40px;
}
.widget_getintuch b,
.widget_getintuch strong {
  display: block;
  text-transform: uppercase;
}
.widget_getintuch i {
  position: absolute;
  left: 0;
  top: 5px;
  text-align: center;
  font-size: 14px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.05);
} /* widget gallery */
.widget_gallery ul {
  padding-left: 0;
}
.widget_gallery li {
  display: inline-block;
  margin-bottom: 5px;
  width: 32.1%;
  min-width: 32.1%;
  max-width: 32.1%;
  border: 2px solid transparent;
}
.widget_gallery li img {
  display: inline-block;
  width: 100%;
}
.widget_gallery li:nth-child(3n-3) {
  margin-right: 0;
}
.widget_gallery li:hover {
  display: inline-block;
  margin-bottom: 0;
  width: 32.1%;
  border: 2px solid;
}
.widget_gallery a {
  display: inline-block;
  width: 100%;
} /*---------------------------------------------------------------23. CUSTOMIZE CSS>set-radius>transition>float-clearfix---------------------------------------------------------------*/ /*==>set-radius==*/
.btn,
.accordion-item,
.form-control,
.img-thumbnail,
.accordion-item-head ing,
.dropdown-menu,
.accordion-item-group .accordion-item,
.nav-tabs > li > a,
.modal-content,
.navbar-toggle,
.nav-pills > li > a,
.pager li > a,
.pager li > span,
.well,
.alert,
.list-group-item:first-child,
.list-group-item:last-child,
.input-group-addon,
.btn-group-divides
  > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
.btn-group-divides > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child),
.dez-booking-form .dez-extra-services li .icon-bx-lg,
.dez-booking-form .dez-extra-services li.active .icon-bx-lg:after,
.widget_tag_cloud a,
.searchform input[type="text"],
.searchform input[type="submit"],
ol.comment-list li.comment .comment-body {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
} /*==>transition==*/
a,
.dez-box,
.btn,
.form-control,
.overlay-bx,
.overlay-icon li a i,
.icon-bx-xl,
.icon-bx-lg,
.icon-bx-md,
.icon-bx-sm,
.icon-bx-xs,
.share-social-bx li,
.indicator,
.profile-menu ul li,
.staff-member .member-done,
.how-dez-work .dez-box .shadow-bx img,
.how-dez-work .dez-box .step-no-bx,
.dez-info-has,
.dez-we-find .dez-box,
.navbar-toggle,
.logo-header,
.header-nav .nav > li > a,
.extra-nav,
.skew-secondry:hover,
.skew-primary:hover,
.skew-secondry:hover::after,
.skew-primary:hover::after {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.animate-slow {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.animate-mid {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.animate-fast {
  -webkit-transition: all 0.8s ease-out;
  -moz-transition: all 0.8s ease-out;
  -o-transition: all 0.8s ease-out;
  -ms-transition: all 0.8s ease-out;
  transition: all 0.8s ease-out;
} /*==>float-clearfix==*/
.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
} /*error for select box*/
.has-error .bootstrap-select .dropdown-toggle,
.has-error .bootstrap-select .dropdown-toggle:hover,
.has-error .bootstrap-select .dropdown-toggle:focus {
  border-color: #a94442 !important;
} /*succes for select box*/
.has-success .bootstrap-select .dropdown-toggle,
.has-success .bootstrap-select .dropdown-toggle:hover,
.has-success .bootstrap-select .dropdown-toggle:focus {
  border-color: #3c763d !important;
} /*---------------------------------------------------------------24. SORTCODES >Buttons>Title separators>Deviders>Lists & bullets>Tabs>Accordians>Carousels>Testimonials>Pricing table>Alert box>Modal pop>Social icons>Breadcrumb>Tables>Image effects>Icon boxes---------------------------------------------------------------*/ /*==>Buttons=======*/ /* owl slider button */
.owl-prev,
.owl-next {
  background-color: #1d1d1d;
  border-color: #1d1d1d;
  color: #ffffff;
  padding: 5px 10px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 13px;
  display: inline-block;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
}
.owl-prev:hover,
.owl-prev:active,
.owl-prev:focus,
.owl-next:hover,
.owl-next:active,
.owl-next:focus {
  background-color: #2c2c2c;
  border-color: #2c2c2c;
  color: #ffffff;
} /* theme default button */
.site-button,
.site-button-secondry {
  color: #fff;
  padding: 10px 20px;
  display: inline-block;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  outline: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  line-height: 1.42857;
}
.site-button {
  background-color: #efbb20;
}
.site-button:active,
.site-button:hover,
.site-button:focus,
.active > .site-button {
  background-color: #d8a409;
  color: #fff;
}
.site-button-secondry {
  background-color: #2c2c2c;
}
.site-button-secondry:active,
.site-button-secondry:hover,
.site-button-secondry:focus,
.active > .site-button-secondry {
  background-color: #262626;
  color: #fff;
} /* button text uppercase */
.site-button.text-uppercase {
  text-transform: uppercase;
} /* button size */
.button-sm {
  padding: 5px 10px;
  font-size: 12px;
}
.button-lg {
  padding: 20px 35px;
  font-size: 16px;
}
.button-xl {
  padding: 30px 50px;
  font-size: 24px;
} /* button rounded */
.radius-no {
  border-radius: 0;
  -webkit-border-radius: 0;
}
.radius-sm {
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.radius-xl {
  border-radius: 100px;
  -webkit-border-radius: 100px;
} /* button colors */ /* white */
.site-button.white {
  background-color: #fff;
  color: #777;
}
.site-button.white:hover,
.site-button.white:active,
.site-button.white:focus {
  background-color: #f4f4f4;
  color: #555;
} /* black */
.site-button.black {
  background-color: #171717;
  color: #fff;
}
.site-button.black:hover,
.site-button.black:active,
.site-button.black:focus {
  background-color: #000;
  color: #fff;
} /* Gray */
.site-button.gray {
  background-color: #666666;
  color: #fff;
}
.site-button.gray:hover,
.site-button.gray:active,
.site-button.gray:focus {
  background-color: #555555;
  color: #fff;
} /* pink */
.site-button.pink {
  background-color: #e63f75;
  color: #fff;
}
.site-button.pink:hover,
.site-button.pink:active,
.site-button.pink:focus {
  background-color: #d22b61;
  color: #fff;
} /* Blue */
.site-button.blue {
  background-color: #42b8d4;
  color: #fff;
}
.site-button.blue:hover,
.site-button.blue:active,
.site-button.blue:focus {
  background-color: #2ca2be;
  color: #fff;
} /* Green */
.site-button.green {
  background-color: #35b494;
  color: #fff;
}
.site-button.green:hover,
.site-button.green:active,
.site-button.green:focus {
  background-color: #26a585;
  color: #fff;
} /* Orange */
.site-button.orange {
  background-color: #e56713;
  color: #fff;
}
.site-button.orange:hover,
.site-button.orange:active,
.site-button.orange:focus {
  background-color: #d55703;
  color: #fff;
} /* Red */
.site-button.red {
  background-color: #d93223;
  color: #fff;
}
.site-button.red:hover,
.site-button.red:active,
.site-button.red:focus {
  background-color: #c51e0f;
  color: #fff;
} /* Brown */
.site-button.brown {
  background-color: #69441f;
  color: #fff;
}
.site-button.brown:hover,
.site-button.brown:active,
.site-button.brown:focus {
  background-color: #5f3a15;
  color: #fff;
} /* Yellow */
.site-button.yellow {
  background-color: #ecc731;
  color: #fff;
}
.site-button.yellow:hover,
.site-button.yellow:active,
.site-button.yellow:focus {
  background-color: #d4af19;
  color: #fff;
} /* purple */
.site-button.purple {
  background-color: #ae1af7;
  color: #fff;
}
.site-button.purple:hover,
.site-button.purple:active,
.site-button.purple:focus {
  background-color: #9804e1;
  color: #fff;
} /* Skew */
.button-skew {
  position: relative;
  text-align: center;
  margin-right: 21px;
  -moz-transform: skewX(-20deg);
  -webkit-transform: skewX(-20deg);
  -o-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  transform: skewX(-20deg);
}
.button-skew span {
  display: inline-block;
  -moz-transform: skewX(20deg);
  -webkit-transform: skewX(20deg);
  -o-transform: skewX(20deg);
  -ms-transform: skewX(20deg);
  transform: skewX(20deg);
}
.button-skew i {
  background-color: inherit;
  position: absolute;
  height: 100%;
  margin-right: -32px;
  right: 0;
  top: 0;
  padding: 12px 12px;
  line-height: normal;
}
.button-skew i:before {
  display: inline-block;
  -moz-transform: skewX(20deg);
  -webkit-transform: skewX(20deg);
  -o-transform: skewX(20deg);
  -ms-transform: skewX(20deg);
  transform: skewX(20deg);
}
.button-skew.button-sm i {
  padding: 7px 10px;
  margin-right: -27px;
}
.button-skew.button-lg i {
  padding: 25px 15px;
  margin-right: -40px;
}
.button-skew.button-xl i {
  padding: 35px 22px;
  margin-right: -55px;
} /* Graphical */
.site-button.graphical {
  color: #fff;
  border-radius: 3px;
  text-shadow: 0 -1px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3),
    0 1px 3px -1px rgba(45, 60, 72, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.site-button.graphical:active {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25) inset;
} /* 3D */
.site-button.button-3d {
  color: #fff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  border-bottom: 4px solid rgba(0, 0, 0, 0.2);
  border-left: none;
  border-right: none;
  border-top: none;
} /* Outline */
.site-button.outline {
  color: #888;
  background: none;
  border-radius: 1px;
  -webkit-border-radius: 1px;
  border-width: 1px;
  border-style: solid;
  border-color: #eee;
} /* white */
.site-button.outline.white {
  color: #e7e7e7;
  border-color: #e7e7e7;
}
.site-button.outline.white:hover {
  color: #666666;
} /* black */
.site-button.outline.black {
  color: #171717;
  border-color: #171717;
} /* gray */
.site-button.outline.gray {
  color: #666666;
  border-color: #666666;
} /* pink */
.site-button.outline.pink {
  color: #e63f75;
  border-color: #e63f75;
} /* blue */
.site-button.outline.blue {
  color: #42b8d4;
  border-color: #42b8d4;
} /* green */
.site-button.outline.green {
  color: #35b494;
  border-color: #35b494;
} /* orange */
.site-button.outline.orange {
  color: #e56713;
  border-color: #e56713;
} /* red */
.site-button.outline.red {
  color: #d93223;
  border-color: #d93223;
} /* brown */
.site-button.outline.brown {
  color: #69441f;
  border-color: #69441f;
} /* yellow */
.site-button.outline.yellow {
  color: #efbb20;
  border-color: #efbb20;
} /* purple */
.site-button.outline.purple {
  color: #ae1af7;
  border-color: #ae1af7;
} /* hover */
.site-button.outline:hover {
  border-color: rgba(0, 0, 0, 0);
  color: #fff;
} /* button text link */
.site-button-link {
  display: inline-block;
  font-weight: 600;
} /* white */
.site-button-link.white {
  color: #e7e7e7;
}
.site-button-link.white:hover,
.site-button-link.white:active,
.site-button-link.white:focus {
  color: #ccc;
} /* black */
.site-button-link.black {
  color: #171717;
}
.site-button-link.black:hover,
.site-button-link.black:active,
.site-button-link.black:focus {
  color: #000;
} /* Gray */
.site-button-link.gray {
  color: #666666;
}
.site-button-link.gray:hover,
.site-button-link.gray:active,
.site-button-link.gray:focus {
  color: #555555;
} /* pink */
.site-button-link.pink {
  color: #e63f75;
}
.site-button-link.pink:hover,
.site-button-link.pink:active,
.site-button-link.pink:focus {
  color: #2ca2be;
} /* Blue */
.site-button-link.blue {
  color: #42b8d4;
}
.site-button-link.blue:hover,
.site-button-link.blue:active,
.site-button-link.blue:focus {
  color: #2ca2be;
} /* Green */
.site-button-link.green {
  color: #35b494;
}
.site-button-link.green:hover,
.site-button-link.green:active,
.site-button-link.green:focus {
  color: #26a585;
} /* Orange */
.site-button-link.orange {
  color: #e56713;
}
.site-button-link.orange:hover,
.site-button-link.orange:active,
.site-button-link.orange:focus {
  color: #d55703;
} /* Red */
.site-button-link.red {
  color: #d93223;
}
.site-button-link.red:hover,
.site-button-link.red:active,
.site-button-link.red:focus {
  color: #c51e0f;
} /* Brown */
.site-button-link.brown {
  color: #69441f;
}
.site-button-link.brown:hover,
.site-button-link.brown:active,
.site-button-link.brown:focus {
  color: #5f3a15;
} /* Yellow */
.site-button-link.yellow {
  color: #ecc731;
}
.site-button-link.yellow:hover,
.site-button-link.yellow:active,
.site-button-link.yellow:focus {
  color: #d4af19;
} /* purple */
.site-button-link.purple {
  color: #ae1af7;
}
.site-button-link.purple:hover,
.site-button-link.purple:active,
.site-button-link.purple:focus {
  color: #9804e1;
} /* filter buttons css */
.site-filters {
  margin-bottom: 30px;
}
.site-filters ul {
  margin: 0;
  list-style: none;
}
.site-filters li {
  display: inline;
  padding: 0;
}
.site-filters li.btn {
  box-shadow: none;
  -webkit-box-shadow: none;
  outline: none !important;
  display: inline-block;
  border: 0;
}
.site-filters li input {
  display: none;
}
.site-filters a {
  margin: 0 15px 5px 0;
}
.site-filters .active > [class*="site-button"] {
  color: #fff;
  background-color: #efbb20;
}
.site-filters.center {
  text-align: center;
}
.site-filters.center ul {
  display: inline-block;
  margin: auto;
}
.site-filters.center [class*="site-button"] {
  display: inline-block;
  margin: 0 5px 10px;
  text-transform: uppercase;
}
@media only screen and (max-width: 480px) {
  .site-filters.center [class*="site-button"] {
    margin: 0 1px 5px;
    padding: 5px 10px;
    font-size: 12px;
  }
} /*==>Title separators======*/
.dez-separator-outer {
  overflow: hidden;
}
.dez-separator {
  display: inline-block;
  height: 3px;
  width: 80px;
  margin-bottom: 10px;
  position: relative;
}
.dez-separator.style-liner {
  width: 20px;
}
.dez-separator.style-icon {
  width: 30px;
  height: auto;
  text-align: center;
  font-size: 20px;
}
.dez-separator[class*="style-"]:after,
.dez-separator[class*="style-"]:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 40px;
  width: 70px;
  height: 2px;
  background: #eee;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.dez-separator[class*="style-"]:before {
  left: auto;
  right: 40px;
}
.dez-separator.style-skew {
  width: 15px;
  height: 10px;
  margin-left: 1px;
  margin-right: 1px;
  -moz-transform: skewX(-10deg);
  -webkit-transform: skewX(-10deg);
  -o-transform: skewX(-10deg);
  -ms-transform: skewX(-10deg);
  transform: skewX(-10deg);
}
.dez-separator.style-skew[class*="style-"]:after,
.dez-separator.style-skew[class*="style-"]:before {
  width: 80px;
  height: 4px;
  left: 20px;
  -moz-transform: translateY(-50%) skewX(-10deg);
  -webkit-transform: translateY(-50%) skewX(-10deg);
  -o-transform: translateY(-50%) skewX(-10deg);
  -ms-transform: translateY(-50%) skewX(-10deg);
  transform: translateY(-50%) skewX(-10deg);
}
.dez-separator.style-skew[class*="style-"]:before {
  right: 20px;
  left: auto;
} /*==>Deviders======*/
.dez-divider {
  height: 1px;
  position: relative;
  margin: 30px 0;
}
.dez-divider.divider-2px {
  height: 2px;
}
.dez-divider.divider-3px {
  height: 3px;
}
.dez-divider.divider-4px {
  height: 4px;
}
.dez-divider i {
  position: absolute;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: block;
  border-radius: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.dez-divider.icon-left {
  margin-left: 40px;
}
.dez-divider.icon-left i {
  left: -40px;
}
.dez-divider.icon-right {
  margin-right: 40px;
}
.dez-divider.icon-right i {
  left: auto;
  right: -40px;
}
.dez-divider.icon-center i {
  left: 50%;
  margin-left: -5px;
} /*==>Lists & bullets======*/
.list-circle,
.list-angle-right,
.list-arrow,
.list-check,
.list-checked,
.list-check-circle,
.list-chevron-circle,
.list-arrow-circle,
.list-times-circle {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;
}
.list-circle li,
.list-angle-right li,
.list-arrow li,
.list-check li,
.list-checked li,
.list-check-circle li,
.list-chevron-circle li,
.list-arrow-circle li,
.list-times-circle li {
  padding: 5px 5px 5px 20px;
  position: relative;
}
.list-circle li:before,
.list-angle-right li:before,
.list-arrow li:before,
.list-check li:before,
.list-checked li:before,
.list-check-circle li:before,
.list-chevron-circle li:before,
.list-arrow-circle li:before,
.list-times-circle li:before {
  font-family: "FontAwesome";
  position: absolute;
  left: 0;
  top: 6px;
  display: block;
  font-size: 15px;
  color: #777;
}
.list-circle li:before {
  top: 10px;
  font-size: 10px;
}
.list-circle li:before {
  content: "\f111";
}
.list-angle-right li:before {
  content: "\f105";
}
.list-arrow li:before {
  content: "\f0da";
}
.list-check li:before {
  content: "\f00c";
}
.list-checked li:before {
  content: "\f046";
}
.list-check-circle li:before {
  content: "\f058";
}
.list-chevron-circle li:before {
  content: "\f138";
}
.list-arrow-circle li:before {
  content: "\f0a9";
}
.list-times-circle li:before {
  content: "\f057";
} /*listing before icon color css*/
.primary li:before {
  color: #efbb20;
}
.secondry li:before {
  color: #77c04b;
}
.black li:before {
  color: #000;
}
.white li:before {
  color: #fff;
}
.orange li:before {
  color: #ff6600;
}
.green li:before {
  color: #00cc00;
}
.red li:before {
  color: #ff3737;
}
.no-margin {
  margin: 0 0 0 !important;
  list-style: none;
} /*listing before numbers*/
.list-num-count {
  counter-reset: li;
  padding-left: 0;
}
.list-num-count > li {
  position: relative;
  margin: 0 0 6px 30px;
  padding: 4px 8px;
  list-style: none;
}
.list-num-count > li:before {
  content: counter(li, decimal);
  counter-increment: li;
  position: absolute;
  top: 0;
  left: -28px;
  width: 28px;
  height: 28px;
  padding: 5px;
  color: #fff;
  background: #efbb20;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
.list-num-count.lower-alpha > li:before {
  content: counter(li, lower-alpha);
}
.list-num-count.upper-alpha > li:before {
  content: counter(li, upper-alpha);
} /*==>Tabs=========*/ /*tabs style 1 [ defult]*/
.dez-tabs .tab-pane {
  padding: 20px 0;
}
.dez-tabs .nav-tabs > li > button {
  color: #3d474a;
  font-weight: 600;
  font-size: 13px;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.dez-tabs .nav-tabs > li > button.active,
.dez-tabs .nav-tabs > li > button.active:focus,
.dez-tabs .nav-tabs > li > button.active:hover {
  background-color: #fff;
  border-color: #ddd;
  border-bottom: 1px solid #fff;
}
.dez-tabs .nav > li > button:focus,
.dez-tabs .nav > li > button:hover {
  background-color: transparent;
}
.dez-tabs .nav li button:hover {
  border: 1px solid transparent;
}
.dez-tabs .nav-tabs > li > button i {
  color: #efbb20;
  margin-right: 5px;
} /*tabs bg [ tabs background]*/
.dez-tabs.bg-tabs .nav-tabs > li > button {
  border: 1px solid #ddd;
  background-color: #efefef;
  margin-right: 2px;
}
.dez-tabs.bg-tabs .nav-tabs > li > button.active {
  border-bottom: 1px solid transparent;
  background-color: #fff;
}
.dez-tabs.vertical.bg-tabs .nav-tabs > li > button {
  border: 1px solid #ddd;
  width: 100%;
}
.dez-tabs.vertical.bg-tabs .nav-tabs > li > button.active {
  border-right: 1px solid transparent;
}
.dez-tabs.vertical.right.bg-tabs .nav-tabs > li > button {
  border: 1px solid #ddd;
  width: 100%;
}
.dez-tabs.vertical.right.bg-tabs .nav-tabs > li > button.active {
  border-left: 1px solid transparent;
} /*tabs bg & top border [ tabs background & top border in active]*/
.dez-tabs.border-top .nav-tabs > li > button.active {
  border-top: 2px solid #efbb20;
  padding: 9px 15px 10px;
} /*tabs style 2 [ content with border outer]*/
.dez-tabs.border .tab-pane {
  padding: 20px;
  border: 1px solid #ddd;
  margin-top: -1px;
} /*tabs style 3 [ left-nav]*/
.dez-tabs.vertical .nav-tabs {
  float: left;
  width: 170px;
  border-bottom: none;
  border-right: 1px solid #ddd;
}
.dez-tabs.vertical .tab-pane {
  padding: 10px 0 10px 20px;
}
.dez-tabs.vertical .nav-tabs li {
  float: none;
  margin-right: -1px;
}
.dez-tabs.vertical .nav-tabs li button {
  margin-right: 0;
  border-right: none;
  width: 100%;
}
.dez-tabs.vertical .nav-tabs li button.active {
  border-bottom: 1px solid #ddd;
}
.dez-tabs.vertical .tab-content {
  border-left: 1px solid #ddd;
  margin-left: 169px;
} /*tabs style 4 [ left-nav & content with border]*/
.dez-tabs.vertical.border .tab-pane {
  padding: 20px;
  margin-left: -1px;
} /*tabs style 5 [ right-nav]*/
.dez-tabs.vertical.right .nav-tabs {
  border-left: 1px solid #ddd;
  border-right: none;
  float: right;
}
.dez-tabs.vertical.right .nav-tabs li {
  margin-right: 0;
  margin-left: -1px;
}
.dez-tabs.vertical.right .nav-tabs li button {
  border-right: 1px solid transparent;
  border-left: none;
  width: 100%;
}
.dez-tabs.vertical.right .nav-tabs li button.active {
  border-right: 1px solid #ddd;
  border-left: none;
}
.dez-tabs.vertical.right .tab-content {
  border-left: none;
  border-right: 1px solid #ddd;
  margin-right: 169px;
  margin-left: 0;
}
.dez-tabs.vertical.right .tab-pane {
  padding: 10px 20px 10px 0;
} /*tabs style 6 [ right-nav & content with border]*/
.dez-tabs.vertical.right.border .tab-pane {
  padding: 20px;
  margin-right: -1px;
} /*==>Accordians=====*/
.accordion-item {
  box-shadow: none;
  -webkit-box-shadow: none;
  border: 0;
  background-color: unset;
  border-radius: 0 !important; /* margin-bottom: 5px; */
}
.accordion .accordion-item {
  border: none;
  border-radius: 0;
  margin-bottom: -1px;
}
.accordion-header {
  position: relative;
}
.accordion-header {
  margin-top: 0;
  margin-bottom: 0;
}
.accordion-header .fa {
  margin-right: 5px;
}
.accordion-header .accordion-button {
  display: block;
  padding: 12px 40px 12px 15px;
  border: 1px solid #ddd; /* border-radius:0!important; */
  background-color: unset;
  box-shadow: none;
}
.accordion-header .accordion-button,
.accordion-header .accordion-button:hover,
.accordion-header .accordion-button.collapsed:hover {
  color: #efbb20;
}
.accordion-header .accordion-button.collapsed,
.accordion-header .accordion-button.collapsed:after {
  color: #3d474a;
}
.accordion-header .accordion-button:after {
  font-family: "FontAwesome";
  content: "\f068";
  float: right;
  color: #efbb20;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  right: 12px;
  top: 12px;
  background-image: none;
  transform: none;
}
.accordion-header .accordion-button.collapsed:after {
  content: "\f067";
}
.accordion-collapse {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid transparent;
  margin-top: -1px;
}
.accordion-body {
  margin: 20px;
  padding: 0;
} /*==accordian rounded corners==*/
.accordion.rounded
  .accordion-item:first-child
  .accordion-header
  .accordion-button {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.accordion.rounded
  .accordion-item:last-child
  .accordion-header
  .accordion-button.collapsed {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.accordion.rounded .accordion-item:last-child .accordion-collapse {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.accordion.rounded .accordion-item:last-child .accordion-collapse.in {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
} /*==accordian space between accordion-item==*/
.accordion.space .accordion-item {
  margin-bottom: 5px !important;
}
.accordion.rounded.space .accordion-item .accordion-header .accordion-button {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.accordion.rounded.space
  .accordion-item
  .accordion-header
  .accordion-button.collapsed {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.accordion.rounded.space .accordion-item .accordion-collapse {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
} /*==accordian background for title==*/
.accordion.bg-title .accordion-header .accordion-button {
  background-color: #f7f8fa;
} /*==accordian no cover border with content area==*/
.accordion.no-cover .accordion-collapse {
  border: none;
}
.accordion.no-cover .accordion-body {
  padding-left: 20px;
  margin-right: 10px;
  border-left: 2px solid #e8e8e8;
  position: relative;
}
.accordion.no-cover .accordion-body:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -5px;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #e8e8e8;
} /*==accordian title bottom bordered==*/
.accordion.border-bottom .accordion-header .accordion-button {
  border-left: none;
  border-right: none;
  border-top: none;
  padding-left: 0;
} /*==accordian title bottom bordered with colored==*/
.accordion.border-bottom.no-border .accordion-header .accordion-button {
  border: none;
}
.accordion.no-border .accordion-body {
  margin-top: 10px;
  margin-bottom: 10px;
} /*==>Carousels======*/ /* Blog carousel */
.ow-post-info {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ebebeb;
}
.ow-post-title .post-title {
  margin: 0 0 10px;
}
.ow-post-text {
  margin-bottom: 10px;
}
.ow-post-text p:last-child {
  margin: 0;
}
.ow-post-readmore {
  margin-bottom: 10px;
}
.ow-post-meta {
  margin-bottom: 10px;
}
.ow-post-meta ul {
  margin: 0 -4px;
  list-style: none;
}
.ow-post-meta ul li {
  padding: 0;
  display: inline-block;
  font-weight: bold;
}
.ow-post-meta li:after {
  content: "/";
  display: inline-block;
  font-weight: normal;
  margin-left: 5px;
  opacity: 0.5;
}
.ow-post-meta li:last-child:after {
  display: none;
}
.ow-post-meta a {
  color: #a9a9a9;
}
.ow-post-meta li i {
  color: #7b7b7b;
  margin: 0 5px;
}
.ow-post-tags {
  border-top: 1px solid #e9e9e9;
  padding-top: 10px;
}
.ow-post-tags .post-comment {
  float: left;
  font-weight: bold;
  text-transform: uppercase;
}
.ow-post-tags .post-comment a {
  color: #a9a9a9;
}
.ow-post-tags .post-tags {
  margin: 0 -3px;
  list-style: none;
}
.ow-post-tags .post-tags a {
  border: 1px solid #ebebeb;
  padding: 2px 8px 1px;
  color: #777;
  margin: 0 3px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 11px;
}
.ow-post-tags .post-tags a:hover,
.ow-post-tags .post-tags a:active,
.ow-post-tags .post-tags a:focus {
  border: 1px solid #0098a6;
  color: #0098a6;
} /* empty media */
.no-image-blog.date-style-2 .ow-post-info {
  padding-top: 70px;
} /*Event carousel */
.ow-event-info {
  position: relative;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ebebeb;
}
.ow-event-title .event-title {
  margin: 0 0 10px;
}
.ow-event-text {
  margin-bottom: 10px;
}
.ow-event-text p:last-child {
  margin: 0;
}
.ow-event-readmore {
  margin-bottom: 10px;
}
.ow-event-meta ul {
  margin: 0;
}
.ow-event-meta ul li {
  color: #a9a9a9;
  font-weight: 600;
  display: inline-block;
  padding-right: 12px;
}
.ow-event-meta ul li i {
  color: #7b7b7b;
  margin-right: 5px;
} /* empty media */
.no-image-event.date-style-2 .ow-post-info {
  padding-top: 70px;
} /*Client carousel */
.ow-client-logo {
  background-color: #fff;
  display: table;
  width: 100%;
}
.client-logo {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 10px;
}
.owl-carousel .ow-client-logo img {
  height: 80px;
  max-height: 100%;
  width: auto;
  display: inline-block;
} /*==>Testimonials======*/
.testimonial-pic {
  background: #fff;
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
  border: 5px solid #fff;
}
.testimonial-pic.radius {
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.testimonial-pic.radius img {
  width: 100%;
  height: 100;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.testimonial-pic.shadow {
  -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
}
.quote-left,
.quote-right {
  position: relative;
}
.quote-left:before,
.quote-right:after {
  font-family: "FontAwesome";
  position: absolute;
  z-index: 10;
  top: 15px;
}
.quote-left:before {
  content: "\f10d";
  left: 0;
}
.quote-right:after {
  content: "\f10e";
  left: auto;
  right: 0;
}
.testimonial-text {
  padding: 15px;
  position: relative;
}
.testimonial-detail {
  padding: 5px;
}
.testimonial-name,
.testimonial-position {
  display: block;
}
.testimonial-position {
  font-style: italic;
}
.testimonial-text p:last-child {
  margin: 0;
} /*testimonial with background image*/
.testimonial-bg {
  color: #fff;
} /*testimonial 1*/
.testimonial-1 {
  text-align: center;
}
.testimonial-1 .testimonial-position {
  color: #efbb20;
}
.testimonial-1 .testimonial-name,
.testimonial-1 .testimonial-position {
  display: block;
}
.testimonial-1 .quote-left:before {
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  background: #efbb20;
  color: #fff;
  text-align: center;
  left: 0;
  top: 65px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
} /*testimonial 2*/
.testimonial-2 .testimonial-text {
  background-color: #f4f5f7;
  padding: 30px;
}
.testimonial-2 .testimonial-text:after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #f4f5f7;
}
.testimonial-2 .testimonial-detail {
  padding: 25px 0 20px 20px;
}
.testimonial-2 .testimonial-pic {
  float: left;
  margin-right: 15px;
  width: 80px;
  height: 80px;
}
.testimonial-2 .quote-left:before {
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  background: #efbb20;
  color: #fff;
  text-align: center;
  left: 0;
  top: 65px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.testimonial-2 .testimonial-name,
.testimonial-2 .testimonial-position {
  padding: 2px 0;
}
.testimonial-2 .testimonial-name {
  padding-top: 20px;
}
.testimonial-2 .testimonial-position {
  color: #efbb20;
}
.testimonial-2 .quote-left:before {
  top: 50px;
} /*testimonial 2 with background image*/
.testimonial-2.testimonial-bg .testimonial-text {
  color: #777;
  background-color: rgba(255, 255, 255, 0.9);
}
.testimonial-2.testimonial-bg .testimonial-text:after {
  border-top-color: rgba(255, 255, 255, 0.9);
} /*testimonial 3*/
.testimonial-3 {
  text-align: center;
}
.testimonial-3 .quote-left:before {
  position: static;
  font-size: 40px;
  color: #efbb20;
}
.testimonial-3 .testimonial-position {
  color: #efbb20;
}
.testimonial-3 .testimonial-name,
.testimonial-3 .testimonial-position {
  display: inline-block;
} /*testimonial 4*/
.testimonial-4 {
  background-color: #1d1d1d;
  position: relative;
  padding: 30px 40px 30px 10px;
  margin-left: 140px;
  min-height: 150px;
}
.testimonial-4:after {
  background-color: #1d1d1d;
  border-left-width: 4px;
  border-left-style: solid;
  border-left-color: #efbb20;
  content: "";
  position: absolute;
  left: -80px;
  top: 0;
  height: 100%;
  width: 80%;
  z-index: -1;
  -moz-transform: skewX(-25deg);
  -webkit-transform: skewX(-25deg);
  -o-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.testimonial-4 .testimonial-pic {
  position: absolute;
  top: 30px;
  left: 0;
  margin-left: -110px;
}
.testimonial-4 [class*="quote-"] {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px;
  border-color: #efbb20;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
}
.testimonial-4 [class*="quote-"]:after,
.testimonial-4 [class*="quote-"]:before {
  top: 0;
  right: -20px;
  font-size: 20px;
  color: #fff;
}
.testimonial-4 .testimonial-text,
.testimonial-4 .testimonial-detail {
  padding: 0;
}
.testimonial-4 .testimonial-text {
  margin-bottom: 10px;
}
.testimonial-4 .testimonial-name,
.testimonial-4 .testimonial-position {
  display: inline-block;
}
.testimonial-4 .testimonial-name:after {
  content: "/";
  color: #efbb20;
  margin: 0 2px 0 5px;
}
.testimonial-4 .testimonial-name {
  text-transform: uppercase;
}
.testimonial-4 .testimonial-position {
  font-style: italic;
  color: #bebebe;
}
.testimonial-4 .testimonial-pic {
  border-color: #efbb20;
  border-width: 3px;
  width: 90px;
  height: 90px;
}
@media only screen and (max-width: 480px) {
  .testimonial-4 .testimonial-pic {
    height: 80px;
    width: 80px;
  }
  .testimonial-4:after {
    transform: skewX(-10deg);
  }
  .testimonial-4 {
    padding: 25px 10px 25px 0px;
    margin-left: 110px;
  }
}
.testimonial-5 .testimonial-text {
  border: 2px solid #e6e6e6;
  padding: 30px;
}
.testimonial-5 .testimonial-detail {
  padding: 0 0 0 20px;
  margin-top: -35px;
}
.testimonial-5 .testimonial-pic {
  box-shadow: 0 0 0 2px #e6e6e6;
  width: 70px;
  height: 70px;
}
.testimonial-5 .testimonial-text p {
  font-size: 16px;
  line-height: 30px;
}
.testimonial-5 .info p {
  display: inline-block;
  position: relative;
  top: -10px;
  padding: 0 10px;
} /*==>Pricing table===*/
.pricingtable-inner {
  text-align: center;
}
.pricingtable-price {
  padding: 20px 20px;
  background-color: #f4f7f8;
  border: 1px solid #e9e9e9;
}
.pricingtable-bx {
  font-size: 50px;
  font-family: Arial;
  color: #333333;
  font-weight: 600;
}
.pricingtable-type {
  font-size: 20px;
  text-transform: uppercase;
  font-size: 16px;
}
.pricingtable-type:before {
  content: "/";
  margin-right: 3px;
}
.pricingtable-title {
  background-color: var(--primary);
  padding: 20px;
  text-transform: uppercase;
}
.pricingtable-title * {
  margin: 0;
  color: #fff;
  font-weight: 800;
}
.pricingtable-features {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #e9e9e9;
}
.pricingtable-features li {
  padding: 12px;
  border-bottom: 1px solid #e9e9e9;
}
.pricingtable-features li i {
  margin: 0 3px;
}
.pricingtable-features li:nth-child(even) {
  background-color: #f4f7f8;
}
.pricingtable-features li:last-child {
  border-bottom: none;
}
.pricingtable-footer {
  margin-top: -1px;
  padding: 20px;
  background-color: #f4f7f8;
  border: 1px solid #e9e9e9;
}
.pricingtable-highlight {
  margin: -20px 0;
  -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 99;
}
.pricingtable-highlight .pricingtable-price {
  padding: 30px 20px;
}
.pricingtable-highlight .pricingtable-footer {
  padding: 30px 20px;
}
.no-col-gap .pricingtable-wrapper {
  margin-left: -1px;
} /*==COUNTERUP===*/
.counter {
  position: relative;
} /*==COUNTDOWN CLOCK */
.count-row {
  background: #333;
}
#countdown-clock {
  display: table;
  text-align: center;
  width: 80%;
  margin-left: -15px;
  border: 1px solid #3f3f3f;
  margin: 17px 0;
}
#countdown-clock span {
  display: table-cell;
  width: 1%;
  color: #fff;
  padding: 5px 15px;
  text-transform: uppercase;
  font-weight: 600;
  color: #999;
  border-right: 1px solid #3f3f3f;
}
#countdown-clock span:last-child {
  border-right: none;
}
#countdown-clock span b {
  display: block;
  font-size: 25px;
  font-weight: 900;
  color: #fff;
}
@media only screen and (max-width: 991px) {
  #countdown-clock {
    width: 100%;
  }
} /*==>Alert box=====*/
.alert.alert-sm {
  padding: 5px 15px;
  font-size: 12px;
}
.alert.alert-lg {
  padding: 25px 15px;
  font-size: 16px;
}
.alert.alert-xl {
  padding: 35px 15px;
  font-size: 18px;
}
.alert[class*="alert-"] i {
  margin-right: 8px;
}
.alert.no-radius {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
.alert.no-bg {
  background-color: transparent;
  border-width: 2px;
}
.alert[class*="alert-"] ul {
  padding-left: 25px;
  margin-top: 10px;
}
.alert[class*="alert-"] ul li:before {
  color: #a94442;
} /*==>Modal pop=====*/
.modal-header {
  border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
}
.modal-footer {
  border-radius: 0 0 3px 3px;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
}
.modal-header {
  background: #efbb20;
  padding: 15px 25px;
}
.modal-header .modal-title {
  color: #fff;
}
.modal .modal-body {
  padding: 25px;
}
.modal-xlg {
  width: 1000px;
}
.provider_registration .bootstrap-select div.dropdown-menu ul li a {
  font-weight: 600;
}
.provider_registration
  .bootstrap-select
  div.dropdown-menu
  ul
  li
  a
  span.childcat {
  font-weight: 400;
  color: #7c7c7c;
}
@media only screen and (max-width: 1024px) {
  .modal-xlg {
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
  }
} /*==>Social icons===*/ /* social icon default */
.dez-social-icon {
  display: inline-block;
  margin: 0 -3px;
  padding: 0;
  text-align: center;
}
.dez-social-icon li {
  display: inline-block;
  padding: 0;
  font-size: 12px;
}
.dez-social-icon li a {
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 4px;
}
.dez-social-icon li .fa {
  vertical-align: middle;
}
.dez-social-icon.dez-social-icon-lg li a {
  font-size: 14px;
  height: 35px;
  line-height: 34px;
  padding: 0;
  width: 35px;
} /* social icon with border */
.dez-social-icon.border li {
  padding: 0 3px;
}
.dez-social-icon.border li a {
  border: 1px solid rgba(255, 255, 255, 0.2);
} /* social icon dark */
.dez-social-links.dark li {
  padding: 0 3px;
}
.dez-social-icon.dark li a {
  border: 1px solid #777777;
  color: #777777;
  border-radius: 2px;
  -webkit-border-radius: 2px;
} /* social share icon style 1*/
.dez-share-icon li {
  border: none;
  width: 36px;
  display: block;
  float: left;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 0;
}
.dez-share-icon li a {
  color: #fff;
}
.dez-share-icon li i {
  border-right: none;
  font-size: 14px;
  color: #fff;
  width: 36px;
  height: 34px;
  line-height: 34px;
  padding: 0;
  text-align: center;
}
.dez-share-icon li.fb {
  background: #354d89;
}
.dez-share-icon li.fb i {
  background: #3a5799;
}
.dez-share-icon li.gp {
  background: #d34b2b;
}
.dez-share-icon li.gp i {
  background: #e35736;
}
.dez-share-icon li.tw {
  background: #029fdf;
}
.dez-share-icon li.tw i {
  background: #00abf0;
}
.dez-share-icon li.dig {
  background: #1d61aa;
}
.dez-share-icon li.dig i {
  background: #2b6fb8;
}
.dez-share-icon li.lin {
  background: #0176be;
}
.dez-share-icon li.lin i {
  background: #0082ca;
}
.dez-share-icon li.pin {
  background: #ac0104;
}
.dez-share-icon li.pin i {
  background: #bd0e15;
}
.dez-share-icon li:hover {
  width: 90px;
} /*==>Breadcrumb====*/
.breadcrumb-row {
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: -60px;
  padding: 20px 0;
  position: relative;
  z-index: 1;
}
.breadcrumb-row ul {
  margin: 0;
}
.breadcrumb-row ul li {
  padding: 0;
  margin-right: 3px;
  color: #333333;
}
.breadcrumb-row ul li:after {
  content: "/";
  margin-left: 7px;
  color: #fff;
}
.breadcrumb-row ul li {
  color: #fff;
}
.breadcrumb-row ul li:last-child {
  color: #fff;
}
.breadcrumb-row ul li:last-child:after {
  display: none;
} /*==>Tables====*/
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 10px;
}
.table.borderless > tbody > tr > td,
.table.borderless > tbody > tr > th {
  border: none;
} /*data table*/
.dataTable .sorting_asc .checkbox {
  margin-right: 0;
} /*==>Image effects==*/ /*use for section*/
.overlay-black-light,
.overlay-black-middle,
.overlay-black-dark,
.overlay-gradient-light,
.overlay-gradient-middle,
.overlay-gradient-dark,
.overlay-white-light,
.overlay-white-middle,
.overlay-white-dark,
.overlay-primary-light,
.overlay-primary-middle,
.overlay-primary-dark {
  position: relative;
}
.overlay-black-light:after,
.overlay-black-middle:after,
.overlay-black-dark:after,
.overlay-gradient-light:after,
.overlay-gradient-middle:after,
.overlay-gradient-dark:after,
.overlay-white-light:after,
.overlay-white-middle:after,
.overlay-white-dark:after,
.overlay-primary-light:after,
.overlay-primary-middle:after,
.overlay-primary-dark:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.overlay-black-light:after,
.overlay-black-middle:after,
.overlay-black-dark:after {
  background: #000;
}
.overlay-gradient-light:after,
.overlay-gradient-middle:after,
.overlay-gradient-dark:after {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* dezC, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000', GradientType=0); /* IE6-9 */
}
.overlay-white-light:after,
.overlay-white-middle:after,
.overlay-white-dark:after {
  background: #fff;
}
.overlay-primary-light:after,
.overlay-primary-middle:after,
.overlay-primary-dark:after {
}
.overlay-black-light:after {
  opacity: 0.3;
}
.overlay-black-middle:after {
  opacity: 0.5;
}
.overlay-black-dark:after {
  opacity: 0.9;
}
.overlay-gradient-light:after {
  opacity: 0.3;
}
.overlay-gradient-middle:after {
  opacity: 0.5;
}
.overlay-gradient-dark:after {
  opacity: 0.9;
}
.overlay-white-light:after {
  opacity: 0.5;
}
.overlay-white-middle:after {
  opacity: 0.7;
}
.overlay-white-dark:after {
  opacity: 0.9;
}
.overlay-primary-light:after {
  opacity: 0.3;
}
.overlay-primary-middle:after {
  opacity: 0.5;
}
.overlay-primary-dark:after {
  opacity: 0.9;
}
.overlay-black-light .container,
.overlay-black-middle .container,
.overlay-black-dark .container,
.overlay-white-light .container,
.overlay-white-middle .container,
.overlay-white-dark .container,
.overlay-primary-light .container,
.overlay-primary-middle .container,
.overlay-primary-dark .container,
.overlay-black-light .container-fluid,
.overlay-black-middle .container-fluid,
.overlay-black-dark .container-fluid,
.overlay-white-light .container-fluid,
.overlay-white-middle .container-fluid,
.overlay-white-dark .container-fluid,
.overlay-primary-light .container-fluid,
.overlay-primary-middle .container-fluid,
.overlay-primary-dark .container-fluid {
  position: relative;
  z-index: 1;
} /*use for box*/
.overlay-bx {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
}
.overlay-icon {
  list-style: none;
  width: 160px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px -75px;
}
.overlay-icon a {
  display: inline-block;
  padding: 0;
  margin: 0 2px;
}
.overlay-icon a i {
  background-color: #fff;
  border-radius: 3px;
}
.overlay-bx:hover a > i,
.dez-media:hover .overlay-bx a > i,
.dez-box-bx:hover .overlay-bx a > i {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.overlay-bx:hover,
.dez-media:hover .overlay-bx,
.dez-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
  opacity: 1;
  visibility: visible;
} /* show for text */
.overlay-text {
  width: 100%;
  position: absolute;
  padding: 20px;
  color: #fff;
  left: 0;
  bottom: 20px;
}
.dez-address-bx {
  display: table;
  text-align: left;
  padding: 20px 20px 20px 25px;
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 14px;
  vertical-align: middle;
  font-weight: 600;
}
.dez-address-bx .fa {
  position: absolute;
  top: 22px;
  left: 0;
  font-size: 22px;
}
.col-md-3 .overlay-text {
  padding: 5px;
}
.col-md-4 .overlay-text {
  padding: 20px;
}
.dez-address2-bx {
  display: table;
  background: #eaeaea;
  padding: 5px 10px;
  margin-bottom: 10px;
} /*==>Icon boxes==*/
.icon-bx-xl,
.icon-bx-lg,
.icon-bx-md,
.icon-bx-sm,
.icon-bx-xs {
  display: inline-block;
  text-align: center;
}
.icon-bx-xl {
  width: 150px;
  height: 150px;
  line-height: 150px;
}
.icon-bx-lg {
  width: 120px;
  height: 120px;
  line-height: 120px;
}
.icon-bx-md {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.icon-bx-sm {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.icon-bx-xs {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.icon-bx-xl.radius,
.icon-bx-lg.radius,
.icon-bx-md.radius,
.icon-bx-sm.radius,
.icon-bx-xs.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.border-1,
.border-2,
.border-3,
.border-4,
.border-5 {
  border-style: solid;
}
.border-1 {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.border-3 {
  border-width: 3px;
}
.border-4 {
  border-width: 4px;
}
.border-5 {
  border-width: 5px;
}
.icon-bx-xl i,
.icon-bx-lg i,
.icon-bx-md i,
.icon-bx-sm i,
.icon-bx-xs i {
  vertical-align: middle;
}
.icon-bx-xl i {
  font-size: 80px;
}
.icon-bx-lg i {
  font-size: 60px;
}
.icon-bx-md i {
  font-size: 45px;
}
.icon-bx-sm i {
  font-size: 30px;
}
.icon-bx-xs i {
  font-size: 20px;
}
.icon-bx-xl img,
.icon-bx-lg img,
.icon-bx-md img,
.icon-bx-sm img,
.icon-bx-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}
.icon-bx-xl img {
  height: 80px;
}
.icon-bx-lg img {
  height: 55px;
}
.icon-bx-md img {
  height: 40px;
}
.icon-bx-sm img {
  height: 30px;
}
.icon-bx-xs img {
  height: 20px;
} /*==11. ICON==*/
.icon-xl,
.icon-lg,
.icon-md,
.icon-sm,
.icon-xs {
  display: inline-block;
  text-align: center;
}
.icon-xl {
  width: 100px;
}
.icon-lg {
  width: 80px;
}
.icon-md {
  width: 60px;
}
.icon-sm {
  width: 40px;
}
.icon-xs {
  width: 30px;
}
.icon-xl i,
.icon-lg i,
.icon-md i,
.icon-sm i,
.icon-xs i {
  vertical-align: middle;
}
.icon-xl i {
  font-size: 80px;
}
.icon-lg i {
  font-size: 60px;
}
.icon-md i {
  font-size: 45px;
}
.icon-sm i {
  font-size: 30px;
}
.icon-xs i {
  font-size: 20px;
}
.icon-xl img,
.icon-lg img,
.icon-md img,
.icon-sm img,
.icon-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}
.icon-xl img {
  height: 80px;
}
.icon-lg img {
  height: 70px;
}
.icon-md img {
  height: 50px;
}
.icon-sm img {
  height: 30px;
}
.icon-xs img {
  height: 20px;
} /*==place icon with box==*/
.icon-bx-wraper {
  position: relative;
}
.icon-bx-wraper .dez-tilte {
  margin-top: 0;
}
.icon-bx-wraper .after-titile-line {
  margin-bottom: 10px;
}
.icon-bx-wraper p:last-child {
  margin: 0;
}
.icon-content {
  overflow: hidden;
} /* icon box left aligh */
.icon-bx-wraper.left .icon-bx-xl,
.icon-bx-wraper.left .icon-bx-lg,
.icon-bx-wraper.left .icon-bx-md,
.icon-bx-wraper.left .icon-bx-sm,
.icon-bx-wraper.left .icon-bx-xs {
  float: left;
  margin-right: 20px;
}
.icon-bx-wraper.left .icon-xl,
.icon-bx-wraper.left .icon-lg,
.icon-bx-wraper.left .icon-md,
.icon-bx-wraper.left .icon-sm,
.icon-bx-wraper.left .icon-xs {
  float: left;
  margin-right: 10px;
} /* icon box right aligh */
.icon-bx-wraper.right {
  text-align: right;
}
.icon-bx-wraper.right .icon-bx-xl,
.icon-bx-wraper.right .icon-bx-lg,
.icon-bx-wraper.right .icon-bx-md,
.icon-bx-wraper.right .icon-bx-sm,
.icon-bx-wraper.right .icon-bx-xs {
  float: right;
  margin-left: 20px;
}
.icon-bx-wraper.right .icon-xl,
.icon-bx-wraper.right .icon-lg,
.icon-bx-wraper.right .icon-md,
.icon-bx-wraper.right .icon-sm,
.icon-bx-wraper.right .icon-xs {
  float: right;
  margin-left: 10px;
} /* icon box center aligh */
.icon-bx-wraper.center {
  text-align: center;
}
[class*="icon-bx-"][class*="bg-"] a {
  color: #fff;
}
[class*="icon-bx-"].bg-white a {
  color: inherit;
}
[class*="icon-bx-"][class*="border-"] {
  display: table;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
}
[class*="icon-bx-"][class*="border-"] .icon-cell {
  display: table-cell;
  vertical-align: middle;
}
.icon-bx-wraper.bx-style-1,
.icon-bx-wraper.bx-style-2 {
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
}
.icon-bx-wraper.bx-style-2.center [class*="icon-bx-"],
.icon-bx-wraper.bx-style-2.left [class*="icon-bx-"],
.icon-bx-wraper.bx-style-2.right [class*="icon-bx-"] {
  position: absolute;
}
.icon-bx-wraper.bx-style-2.center [class*="icon-bx-"] {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.icon-bx-wraper.bx-style-2.center .icon-bx-xl {
  margin-left: -75px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-lg {
  margin-left: -60px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-md {
  margin-left: -50px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-sm {
  margin-left: -40px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-xs {
  margin-left: -20px;
}
.icon-bx-wraper.bx-style-2.left [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  left: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.icon-bx-wraper.bx-style-2.right [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  right: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
} /*==media with content box css==*/
.dez-box,
.dez-info,
.dez-tilte,
.dez-tilte-inner {
  position: relative;
}
.dez-tilte-inner {
  display: inline-block;
}
.dez-tilte-inner.skew-title:after {
  content: "";
  position: absolute;
  right: -15px;
  top: 0;
  width: 50px;
  height: 100%;
  z-index: -1;
  -moz-transform: skewX(20deg);
  -webkit-transform: skewX(20deg);
  -o-transform: skewX(20deg);
  -ms-transform: skewX(20deg);
  transform: skewX(20deg);
  left: auto;
}
.dez-box[class*="border-"],
.dez-info[class*="border-"] {
  border-color: #eee;
}
.dez-info.border-1,
.dez-info.border-2,
.dez-info.border-3,
.dez-info.border-4,
.dez-info.border-5 {
  border-top: none;
} /*==before & after for line css==*/
.left-border,
.right-border {
  position: relative;
}
.left-border:before,
.right-border:before {
  content: "";
  position: absolute;
  top: 5px;
  width: 1px;
  height: 90%;
  background: #ccc;
}
.right-border:before {
  right: 0;
} /*==for images==*/
.dez-media,
.dez-media,
.dez-post-media {
  background-color: #fff;
  position: relative;
}
.dez-media img,
.dez-post-media img {
  width: 100%;
  height: auto;
} /*---------------------------------------------------------------25. PAGINATION---------------------------------------------------------------*/ /* pagination style-1 */
.pagination-bx .pagination,
.cvf-universal-pagination .pagination {
  margin: 0;
}
.pagination {
  padding: 10px 0;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 0;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.pagination > li > a,
.pagination > li > span {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  color: #767676;
  padding: 8px 14px;
  font-weight: 600;
  font-size: 12px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  border-color: transparent;
  color: #fff;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  border-color: transparent;
}
.pagination > .previous > a,
.pagination > .next > a {
  padding: 8px 14px;
  font-size: 12px;
} /*DataTable pagination */
.dataTables_paginate ul.pagination {
  margin: 0;
}
.dataTables_paginate .pagination > li {
  padding: 0;
} /*---------------------------------------------------------------26. FOOTER---------------------------------------------------------------*/ /*newslatter*/
.dez-newsletter {
  overflow-x: hidden;
}
.skew-subscribe {
  position: relative;
}
.skew-subscribe:after {
  background-color: #1d1d1d;
  content: "";
  position: absolute;
  left: -50px;
  bottom: 0;
  width: 2000px;
  height: 100%;
  border-right: 7px solid #2d3239;
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -o-transform: skew(30deg);
  -ms-transform: skew(30deg);
  transform: skew(30deg);
}
@media only screen and (max-width: 767px) {
  .dez-newsletter .p-t40.p-b20 {
    padding-top: 30px;
    padding-bottom: 0;
  }
  .skew-subscribe.p-t40.p-b20 {
    padding-top: 30px;
    padding-bottom: 0;
  }
  .skew-subscribe:after {
    left: auto;
    right: 30px;
    -webkit-transform: skew(-30deg);
    -moz-transform: skew(-30deg);
    -o-transform: skew(-30deg);
    -ms-transform: skew(-30deg);
    transform: skew(-30deg);
  }
} /* footer fixed on bottom css*/
.footer-fixed .site-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
}
.footer-fixed .page-content {
  position: relative;
  z-index: 1;
  background-color: #fff;
}
footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a {
  color: #fff;
}
footer p,
footer strong,
footer b,
footer {
  color: #ffffff;
}
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a {
  color: #efbb20;
}
footer a,
footer a:visited {
  color: #fff;
}
footer a:active,
footer a:focus,
footer a:hover {
  color: #efbb20;
}
footer p {
  margin-bottom: 10px;
}
footer p,
footer li {
  font-size: 14px;
  line-height: 22px;
}
footer#footer {
  background-position: center;
  background-size: cover;
}
.dez-top-footer-overlay,
.dez-bottom-footer-overlay {
  position: relative;
}
.dez-top-footer-overlay .container,
.dez-bottom-footer-overlay .container {
  position: relative;
  z-index: 1;
}
.dez-top-footer-overlay:before,
.dez-bottom-footer-overlay:before {
  content: "";
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.dez-top-footer-overlay:before {
}
.dez-bottom-footer-overlay:before {
}
footer p {
  line-height: 24px;
  margin-bottom: 10px;
}
footer p a {
  color: #3396d1;
}
footer .widget ul {
  list-style: none;
  margin-top: 5px;
} /*widget li in footer*/
footer .widget_categories ul li,
footer .widget_archive ul li,
footer .widget_meta ul li,
footer .widget_pages ul li,
footer .widget_recent_comments ul li,
footer .widget_nav_menu li,
footer .widget_recent_entries ul li,
footer .widget_services ul li {
  border-bottom: 1px dashed rgba(102, 102, 102, 0.3);
} /*widget Getintuch*/
footer .widget_getintuch i {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.footer-top {
  background: #2c2c2c;
  background-size: cover;
  background-position: center;
  padding: 50px 0 20px;
}
.footer-line {
  position: relative;
}
.footer-line:after {
  content: "";
  position: absolute;
  top: 0;
  height: 5px;
  width: 100%;
  background-color: #efbb20;
  background-image: url(../images/line.png);
  background-position: top;
  background-repeat: repeat-x;
}
.footer-bottom {
  background-color: #1d1d1d;
  padding: 25px 0;
  color: #fff;
  font-size: 15px;
}
.footer-bottom ul {
  margin: 0;
}
@media only screen and (max-width: 991px) {
  .col-md-3.col-sm-6.footer-col-4:nth-child(3) {
    clear: both;
  }
}
@media only screen and (max-width: 767px) {
  .footer-clear {
    display: block !important;
    clear: both;
  }
  #footer .footer-4-col {
    width: 100%;
  }
  .footer-bottom [class*="clo-"] {
    width: 100%;
  }
} /* scroll top btn css */
button.scroltop {
  background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
  border-color: #efbb20;
  border-radius: 20px;
  border-style: solid;
  border-width: 0;
  bottom: 15px;
  box-shadow: 2px 2px 12px -5px #000000;
  color: #efbb20;
  cursor: pointer;
  display: none;
  height: 40px;
  line-height: 26px;
  margin: 0;
  position: fixed;
  right: 15px;
  text-align: center;
  width: 40px;
  z-index: 999;
}
.trans-footer .footer-top .saf-footer {
  padding: 50px 30px 20px;
  background-color: rgba(255, 255, 255, 0.02);
}
.trans-footer .footer-top {
  padding-bottom: 0;
}
.trans-footer .footer-top,
.trans-footer .footer-bottom {
  background-color: #1d1d1d;
}
.trans-footer .widget {
  margin-bottom: 0;
}
.trans-footer .newsletter {
  max-width: 500px;
  margin: 0px auto 30px;
}
.trans-footer .newsletter input[type="text"] {
  background-color: rgba(255, 255, 255, 0.02);
  border: 0;
  border-radius: 30px !important;
}
.trans-footer .newsletter input,
.trans-footer .newsletter button {
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
}
.trans-footer .section-head {
  margin-bottom: 30px;
}
.footer-social li a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  margin-right: 5px;
} /* Footer Bg Image */
.footer-image .footer-top,
.footer-image .footer-bottom {
  background-color: rgba(0, 0, 0, 0);
}
.footer-image .saf-footer,
.footer-image .newsletter input[type="text"],
.trans-footer.footer-image .footer-top .saf-footer {
  background-color: rgba(0, 0, 0, 0.5);
}
.site-footer .widget_services ul li a {
  color: #ffffff;
} /* Footer Overlay */
.footer-overlay .footer-bottom {
  background-color: rgba(0, 0, 0, 0.85);
}
.footer-overlay .footer-top {
  background-color: rgba(0, 0, 0, 0.8);
} /* Footer Style */
.footer-info-box {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.footer-info-box .dez-social-icon li a:hover {
  color: #000;
}
.footer-info-box .dez-social-icon li a {
  background-color: #ffffff;
  border: medium none;
  border-radius: 100%;
  font-size: 16px;
  height: 38px;
  line-height: 38px;
  padding: 0;
  width: 38px;
}
.newsletter input {
  height: 44px;
}
.footer-info-box .dez-social-icon {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
  display: inline-block;
  margin: 0 -3px;
  padding: 0 0 15px;
  text-align: center;
}
.footer-info-box .newsletter .input-group {
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  padding-right: 2px;
}
.footer-info-box .newsletter input[type="text"] {
  background-color: #fff;
} /* Footer White */
.footer-white {
  border-top: 2px solid #e0e0e0;
}
.footer-white .footer-top {
  background-color: #fff;
}
.footer-white .footer-bottom {
  background-color: #efefef;
}
footer.footer-white h1,
footer.footer-white h2,
footer.footer-white h3,
footer.footer-white h4,
footer.footer-white h5,
footer.footer-white h6,
footer.footer-white h1 a,
footer.footer-white h2 a,
footer.footer-white h3 a,
footer.footer-white h4 a,
footer.footer-white h5 a,
footer.footer-white h6 a,
footer.footer-white.site-footer .widget_services ul li a,
footer.footer-white p,
footer.footer-white strong,
footer.footer-white b,
footer.footer-white,
footer.footer-white .footer-bottom,
footer.footer-white .footer-bottom a {
  color: #000;
}
.footer-white .footer-bottom {
  border: 0;
} /*---------------------------------------------------------------27. PAGE-CONTENT---------------------------------------------------------------*/
.page-wraper {
  background: #f7f8fa;
}
.page-content {
  margin-top: 0;
  padding-bottom: 50px;
}
.content-area {
  padding-top: 60px;
}
.section-full {
  position: relative;
}
.section-full:last-child {
  margin-bottom: -50px;
} /*=Section Head Part=*/
.section-head {
  margin-bottom: 40px;
}
.section-head.no-margin {
  margin-bottom: 0;
}
.section-head h1,
.section-head h2,
.section-head h3 {
  margin-top: 0;
}
.title-small {
  display: block;
  color: #494949;
  margin-bottom: 15px;
}
.section-head p {
  padding-top: 10px;
  font-size: 14px;
  color: #494949;
}
.text-center.section-head p {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
}
.text-white {
  color: #fff;
}
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white .title-small {
  color: #fff;
} /*=Section Content Part=*/
.section-content {
}
@media only screen and (max-width: 1024px) {
  .section-full {
    background-attachment: scroll !important;
  }
}
@media only screen and (max-width: 991px) {
  .page-content {
    margin-top: 0;
  }
} /*---------------------------------------------------------------28. INNER-CONTENT---------------------------------------------------------------*/ /*page not found*/
.page-notfound {
  padding: 70px 0 20px;
}
.page-notfound strong {
  font-size: 200px;
  font-weight: 900;
  line-height: 140px;
  display: block;
}
.page-notfound strong i {
  font-size: 190px;
  margin: 0 10px;
}
.page-notfound .searchform {
  position: relative;
  text-align: left;
  max-width: 420px;
  margin: 30px auto;
} /* required classes */
.sticky {
  clear: both;
}
.gallery-caption {
  clear: both;
}
.bypostauthor {
  clear: both;
} /*Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
} /*===============================================gallery pages css===============================================*/
.dez-gallery-listing {
  list-style: none;
}
.dez-gallery-box {
  margin-bottom: 30px;
} /*===============================================Layout boxed css===============================================*/
#bg {
  background-attachment: fixed;
  background-size: cover;
}
.boxed .page-wraper {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.boxed .tp-banner-container,
.boxed .rev_slider_wrapper {
  left: 0 !important;
  width: 100% !important;
}
.boxed .tp-rightarrow {
  right: 0 !important;
}
.boxed .tp-leftarrow {
  left: 0 !important;
}
.boxed.footer-fixed .site-footer {
  left: 50%;
  width: 1200px;
  margin: 0 -600px;
}
.boxed .is-fixed .main-bar {
  left: 50%;
  width: 1200px;
  margin: 0 -600px;
}
.boxed .is-fixed.header-curve .logo-header:after {
  right: auto;
  left: -15px;
  width: 90%;
}
.owl-imgbx,
.ow-portfolio-img {
  position: relative;
}
.ow-entry-content {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #eee;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 5px;
}
.ow-entry-title {
  font-size: 16px;
  font-weight: 600;
  padding: 5px 0;
}
.ow-entry-title,
.ow-entry-title a {
  color: #3d474a;
  text-transform: capitalize;
}
.ow-entry-text p:last-child {
  margin: 0;
} /*product detail page css*/ /* Product Details*/
.btn-quantity {
  width: 150px;
}
.product-description.dex-tabs.border-top .nav-tabs > li.active > a {
  border-top: 2px solid #efbb20;
}
.product-description.dex-tabs .nav-tabs > li > a i {
  color: #efbb20;
}
.dex-tabs.bg-tabs .nav-tabs > li > a {
  background-color: #fcfcfc;
} /*Product Review*/
ol.commentlist {
  list-style: none;
  margin: 0;
}
ol.commentlist li {
  position: relative;
  padding: 0;
  margin-bottom: 20px;
  background: #f7f8fa;
  padding: 20px;
  border: 1px dashed #eeeeee;
}
ol.commentlist li img {
  float: left;
  border: 5px solid #fff;
  width: 80px;
  height: auto;
}
ol.commentlist li .comment-text {
  padding-left: 100px;
}
ol.commentlist li .meta {
  margin-bottom: 5px;
}
ol.commentlist li .meta strong {
  font-size: 16px;
}
ol.commentlist li .meta .time {
  color: #999;
  display: block;
  font-size: 14px;
}
ol.commentlist li .description p {
  margin: 0;
}
ol.commentlist li .star-rating {
  position: absolute;
  top: 20px;
  right: 20px;
}
.comment-reply-title {
  margin-top: 0;
}
.comment-form [class*="comment-form"] {
  width: 49%;
}
.comment-form label {
  display: block;
}
.comment-form [class*="comment-form"] input,
.comment-form [class*="comment-form"] textarea {
  border: 1px solid #ccc;
  padding: 8px 10px;
  width: 100%;
}
.comment-form {
  margin: 0 -15px;
}
.comment-form [class*="comment-form"],
.comment-form .form-submit {
  margin-bottom: 20px;
  padding: 0 15px;
}
.comment-form .form-submit input:active,
.comment-form .form-submit input:focus,
.comment-form .form-submit input:hover {
  background: #037a85;
}
.dez-quik-search {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  z-index: 999;
  display: none;
  overflow: hidden;
}
.dez-quik-search form {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  position: relative;
}
.dez-quik-search .form-control {
  padding: 15px;
  width: 100%;
  height: 94px;
  border: none;
  background: none;
  color: #fff;
  font-size: 20px;
}
.dez-quik-search .form-control::-moz-placeholder {
  color: #fff;
}
.dez-quik-search .form-control:-moz-placeholder {
  color: #fff;
}
.dez-quik-search .form-control:-ms-input-placeholder {
  color: #fff;
}
.dez-quik-search .form-control::-webkit-input-placeholder {
  color: #fff;
}
.dez-quik-search span {
  position: absolute;
  right: 15px;
  top: 50%;
  margin: -15px 0;
  height: 25px;
  font-size: 20px;
  cursor: pointer;
  color: #fff;
}
#gmap_canvas {
  width: 100%;
  height: 400px;
}
#gmap_canvas img {
  max-width: none !important;
  background: none !important;
} /* sosiyal btn color */
.fb-btn {
  background-color: #4867aa;
}
.tw-btn {
  background-color: #1da1f2;
}
.gplus-btn {
  background-color: #dc4a38;
}
.pin-btn {
  background-color: #cc2127;
}
.link-btn {
  background-color: #0274b3;
}
.dez-social-icon.dez-social-icon-lg li a {
  font-size: 14px;
  height: 35px;
  line-height: 34px;
  padding: 0;
  width: 35px;
} /* Team Section */
.dez-media.dez-media-left .dez-info-has,
.dez-media.dez-media-top .dez-info-has,
.dez-media.dez-media-right .dez-info-has {
  bottom: auto;
  margin-bottom: 0;
  top: 0;
} /* Top Side Icon */
.dez-media.dez-media-top .dez-info-has {
  margin-top: -100px;
}
.dez-media.dez-media-top:hover .dez-info-has,
.dez-box:hover .dez-media.dez-media-top .dez-info-has {
  margin-top: 0;
} /* Left Side Icon */
.dez-media.dez-media-left .dez-info-has {
  left: 0;
  padding: 15px;
  width: auto;
  margin-left: -60px;
}
.dez-media.dez-media-left:hover .dez-info-has {
  margin-left: 0px;
}
.dez-media.dez-media-left .dez-social-icon li {
  display: block;
  margin: 3px 0;
} /* Right Side Icon */
.dez-media.dez-media-right .dez-info-has {
  right: 0;
  padding: 15px;
  width: auto;
  left: auto;
  margin-right: -60px;
}
.dez-media.dez-media-right:hover .dez-info-has {
  margin-right: 0px;
}
.dez-media.dez-media-right .dez-social-icon li {
  display: block;
  margin: 3px 0;
} /*---------------------------------------------------------------29. SIDE-NAV---------------------------------------------------------------*/
.sidenav.cs-sidenav {
  width: 800px;
  right: -820px;
  left: auto;
}
.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: -320px;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  border: 0 none;
  border-radius: 0;
}
.sidenav > ul li a {
  padding: 10px 15px;
  display: block;
  border-color: #ebebeb;
  border-image: none;
  border-style: solid;
  border-width: 1px 0;
  display: block;
  margin-bottom: -1px;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: 600;
}
.sidenav > ul li a:hover {
  color: #fff;
}
.sidenav .logo-header {
  height: auto;
  padding: 15px 50px 15px 15px;
  width: 100%;
}
.button-side-nav {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 999;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, 0.4);
  font-size: 17px;
  cursor: pointer;
}
.sidenav .closebtn {
  color: #ffffff;
  font-size: 24px;
  height: 35px;
  line-height: 35px;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 35px;
  z-index: 9;
}
#main {
  transition: margin-left 0.5s;
}
@media only screen and (max-width: 800px) {
  .sidenav.cs-sidenav {
    width: 100%;
  }
} /*---------------------------------------------------------------30. ONE-PAGE-LAYOUT---------------------------------------------------------------*/
.sidenav.full-page {
  left: 0;
  width: 280px;
}
.one-page-layout #main {
  margin-left: 280px;
}
.contant-block {
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  z-index: 1;
}
.one-page-layout .closebtn,
.one-page-layout .button-side-nav {
  display: none;
}
#myNavbar li.active a {
  color: #fff;
}
.one-page-layout {
  padding-bottom: 0;
  margin-bottom: 0;
} /* Right */
.one-page-layout.right-side .sidenav {
  left: auto;
  right: 0;
  border-radius: 0;
}
.one-page-layout.right-side #main {
  margin-left: 0;
  margin-right: 280px;
}
.blog-page-content .dez-gallery-box {
  margin-bottom: 20px;
} /* Responsive Css */
@media only screen and (max-width: 1024px) {
  .one-page-layout .closebtn,
  .one-page-layout .button-side-nav {
    display: block;
  }
  .sidenav.full-page {
    left: 0;
    width: 0;
    z-index: 999;
  }
  .one-page-layout #main {
    margin-left: 0px !important;
  }
  .site-footer .footer-bottom .col-md-4 {
    width: 33.33%;
    display: inline-block;
    float: left;
  }
  .one-page-layout.right-side #main {
    margin-right: 0px;
  }
  .one-page-layout.right-side .button-side-nav {
    left: 20px;
    right: auto;
  }
}
@media only screen and (max-width: 767px) {
  .site-footer .footer-bottom .col-md-4 {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
  }
}
.dez-full-blog {
  height: 100%;
  position: fixed;
  width: 50%;
  background-color: #efefef;
}
.dez-full-blog .owl-carousel .dez-thum-bx img {
  width: 100%;
}
.dez-full-blog .owl-carousel {
  z-index: auto;
}
.dez-full-blog .dez-post-info {
  background-color: #efefef;
  padding: 20px 30px 30px;
  width: 100%;
}
.left-footer-menu .footer-social li a {
  border-radius: 50%;
  height: 40px;
  line-height: 40px;
  width: 40px;
  padding: 0;
  text-align: center;
}
.left-footer-menu .footer-social li {
  padding: 0 2px;
}
.left-footer-menu {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.dez-full-blog .dez-post-tags {
  background-color: #efefef;
  bottom: 0;
  padding: 15px 30px;
  position: fixed;
  width: 100%;
}
.full-blog-dark .dez-full-blog .dez-post-tags {
  background-color: #090909;
}
.blog-page-content .dez-blog-grid-3 .blog-post {
  margin-bottom: 20px;
}
.blog-page-content .dez-blog-grid-3 {
  margin-left: -10px;
  margin-right: -10px;
}
.blog-page-content .dez-blog-grid-3 [class*="col-sm-"] {
  padding-left: 10px;
  padding-right: 10px;
} /* Blog Section Dark */
.full-blog-dark {
  background-color: #101010;
  color: #767676;
}
.full-blog-dark .post-title a {
  color: #fff;
}
.full-blog-dark.style-1 .dez-post-info {
  padding-top: 10px;
  padding-bottom: 15px;
}
.full-blog-dark .dez-full-blog .dez-post-info {
  background-color: #090909;
  border: 0;
  padding: 20px 30px 30px;
}
.full-blog-dark .dez-post-tags {
  border-top: 1px solid #141414;
}
.full-blog-dark .dez-post-tags .post-tags a {
  border: 1px solid #2b2b2b;
}
.full-blog-dark .sidenav {
  background-color: #090909;
}
.full-blog-dark .sidenav ul li a {
  border-color: #0c0c0c;
}
.full-blog-dark .dez-full-blog {
  background: #090909;
} /* Dark Style With Border */
.full-blog-dark.style-2 .dez-post-info,
.full-blog-dark.style-3 .dez-post-info {
  border: 1px solid #1b1b1b;
  padding: 20px;
} /* Light Style With Border */
.style-2 .dez-post-info,
.style-3 .dez-post-info {
  border: 1px solid #dddddd;
  padding: 10px 20px 20px;
}
.style-3 .dez-full-blog {
  width: 41.66%;
}
.overlay-50:after {
  width: 50%;
} /*====Responsive====*/
@media only screen and (max-width: 1024px) {
  .style-3 .dez-full-blog {
    width: 50%;
  }
}
@media only screen and (max-width: 991px) {
  .dez-full-blog,
  .style-3 .dez-full-blog {
    position: unset;
    width: 100%;
  }
  .dez-full-blog .dez-post-tags {
    position: unset;
  }
}
@media only screen and (max-width: 767px) {
  #main {
    margin-left: 0 !important;
  }
} /* Responsive All Design */
@media only screen and (max-width: 1024px) {
  .header-nav .nav i {
    display: none;
  }
  .main-slider .site-button,
  .main-slider .site-button-secondry {
    padding: 10px 15px;
  }
  .our-gallery h3.h3 {
    font-size: 18px;
  }
  .our-gallery .dez-img-effect .site-button {
    display: none;
  }
}
@media only screen and (max-width: 980px) {
  .header-nav .nav i {
    display: block;
  }
  .our-gallery .dez-img-effect .site-button {
    display: block;
  }
  .overlay-50:after {
    width: 100%;
  }
  .slide-contant-btn .site-button {
    margin-top: 50px;
  }
  @media only screen and (max-width: 767px) {
    .worker {
      margin-bottom: 30px;
    }
    .footer-info-box .text-right,
    .footer-info-box .text-left,
    .newsletter strong {
      text-align: center;
    }
    .page-notfound strong i,
    .page-notfound strong {
      font-size: 120px;
    }
    .left-footer-menu {
      position: unset;
    }
  }
  @media only screen and (max-width: 320px) {
    .logo-header,
    .is-fixed.header-curve .logo-header {
      width: 150px;
    }
    .is-fixed .logo-header,
    .is-fixed.header-curve .logo-header {
      width: 150px;
    }
    .is-fixed .navbar-toggle {
      margin: 14px 0 14px 10px;
    }
    .main-slider .site-button,
    .main-slider .site-button-secondry {
      padding: 10px 8px;
    }
  } /* spacial */
  .bg-primary a:hover {
    color: #fff;
  }
  .dez-button-block .site-button {
    margin-bottom: 10px;
  } /*-------------------------- Team And Club Section */
  .dez-team-club .dez-divider {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .dez-team-club .dez-thum-bx {
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    background-size: cover;
  }
  .dez-team-club .bg-gray-dark {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .dez-team-club .owl-prev,
  .dez-team-club .owl-next {
    background-color: rgba(255, 255, 255, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #000;
    font-size: 24px;
    padding: 20px 8px;
  }
  .dez-team-club .owl-prev:hover,
  .dez-team-club .owl-next:hover {
    background-color: rgba(255, 255, 255, 1);
  } /* Up Comming Match*/
  .dez-upcomming-1 {
    border: 2px solid rgb(255, 195, 0);
  }
  .dez-upcomming-1 .section-head text {
    display: block;
  }
  .dez-upcomming-1 .section-head span {
    font-size: 16px;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
  }
  .dez-upcomming-1 .team-name {
    margin-top: 20px;
    padding: 0 25px;
  }
  .dez-upcomming-1 .team-match {
    padding: 0 50px;
    position: relative;
  }
  .dez-upcomming-1 .dez-vs {
    background: #ffc300 none repeat scroll 0 0;
    border-radius: 50%;
    color: #ffffff;
    font-size: 24px;
    height: 70px;
    line-height: 70px;
    overflow: hidden;
    position: absolute;
    right: -30px;
    top: 20px;
    width: 70px;
    border: 1px solid #2d3239;
  }
  .dez-upcomming-1 .dez-vs span {
    position: relative;
    z-index: 2;
  }
  .dez-upcomming-1 .dez-vs:after {
    background: #2d3239 none repeat scroll 0 0;
    content: "";
    height: 100px;
    position: absolute;
    right: -65px;
    top: 0;
    transform: rotate(20deg);
    width: 100px;
  }
  .dez-upcomming-1.dez-skew:after {
    background: #ffc300 none repeat scroll 0 0;
    content: "";
    display: block;
    height: 220px;
    position: absolute;
    top: 0;
    transform: skew(160deg);
    width: 50px;
  }
  .dez-upcomming-1.dez-skew:before {
    background: #ffc300 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    display: block;
    height: 220px;
    position: absolute;
    right: 30px;
    transform: skew(160deg);
    width: 50px;
  }
  .team-logo {
    display: block;
    max-width: 100px;
    margin: 0px auto;
  }
  .team-name {
    display: block;
  } /*====Pricing Table=====*/
  .trans-pricing .pricingtable-wrapper {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .trans-pricing .pricingtable-highlight {
    margin-bottom: 0;
  }
  .trans-pricing .pricingtable-features {
    border: 0;
  }
  .trans-pricing .pricingtable-footer,
  .trans-pricing .pricingtable-features li:nth-child(2n),
  .trans-pricing .pricingtable-price {
    background-color: rgba(255, 255, 255, 0.05);
    border-width: 0;
    color: #fff;
  }
  .trans-pricing .pricingtable-features li {
    border-width: 0;
  }
  .trans-pricing .pricingtable-title {
    background-color: rgba(0, 0, 0, 0);
  }
  .trans-pricing .pricingtable-bx,
  .trans-pricing .pricingtable-features li {
    color: #fff;
  }
  .price-table .pricingtable-features li:nth-child(2n) {
  }
  .price-table .pricingtable-price,
  .price-table .pricingtable-footer {
    border: 0;
    position: relative;
  }
  .price-table .pricingtable-wrapper.border-1,
  .price-table .pricingtable-wrapper .border-1 {
    border-color: #3c4044;
  }
  .price-table .pricingtable-features,
  .price-table .pricingtable-features li {
    border: 0;
  }
  .price-table .pricingtable-price-bx h2 {
    font-size: 60px;
    font-weight: bold;
    line-height: 65px;
  }
  .price-table .pricingtable-price-bx span {
    font-size: 20px;
    font-weight: bold;
    color: #2d3239;
  }
  .price-table .pricingtable-price:after,
  .price-table .pricingtable-price:before {
    background-color: inherit;
    bottom: -10px;
    content: "";
    height: 30px;
    position: absolute;
    width: 50%;
    z-index: 2;
  }
  .price-table .pricingtable-price:after {
    -moz-transform: skewY(7deg);
    -webkit-transform: skewY(7deg);
    -o-transform: skewY(7deg);
    -ms-transform: skewY(7deg);
    transform: skewY(7deg);
    left: 0;
  }
  .price-table .pricingtable-price:before {
    -moz-transform: skewY(-7deg);
    -webkit-transform: skewY(-7deg);
    -o-transform: skewY(-7deg);
    -ms-transform: skewY(-7deg);
    transform: skewY(-7deg);
    left: auto;
    right: 0;
  } /* Effict 8 */
  .box-gap:hover .dez-info-has {
    transform: scale(1);
  }
  .box-gap .dez-info-has {
    transform: scale(0.8);
  }
  .box-gap:hover .dez-info-has,
  .box-gap .dez-info-has {
    height: 85%;
    left: 7.5%;
    top: 7.5%;
    width: 85%;
  }
  .box-gap .dez-info-has.bg-white {
    background: #fff;
  }
  .no-gap:hover .dez-info-has {
    width: 100%;
    height: 100%;
    transform: scale(1);
    top: 0;
    left: 0;
  }
  .no-gap .dez-info-has {
    width: 100%;
    height: 100%;
    transform: scale(0.8);
    top: 0;
    left: 0;
  }
  .no-gap .dez-info-has.bg-white {
    background: #fff;
  } /* Skew Title Triangle */
  .skew-triangle {
    position: relative;
  }
  .skew-triangle:after {
    border-left: 0 solid rgba(0, 0, 0, 0);
    border-right: 15px solid rgba(0, 0, 0, 0);
    border-top: 15px solid #ffc300;
    content: "";
    position: absolute;
  }
  .skew-title:after {
    top: -20px;
    left: -20px;
  }
  .skew-triangle.left-top:after {
    top: 5px;
    left: 5px;
  }
  .skew-triangle.right-top:after {
    top: 5px;
    left: auto;
    right: 5px;
    border-left: 15px solid rgba(0, 0, 0, 0);
    border-right: 0 solid rgba(0, 0, 0, 0);
  }
  .skew-triangle.left-bottom:after {
    top: auto;
    left: 5px;
    right: auto;
    bottom: 5px;
    border-bottom: 15px solid #ffc300;
    border-top: 0 none;
  }
  .skew-triangle.right-bottom:after {
    top: auto;
    left: auto;
    right: 5px;
    bottom: 5px;
    border-left: 15px solid rgba(0, 0, 0, 0);
    border-right: 0 none;
    border-bottom: 15px solid #ffc300;
    border-top: 0 none;
  } /* Products */
  .dez-cart-add .dez-info-has li a {
    font-size: 15px;
    height: 35px;
    width: 35px;
  }
  #particles-js .container {
    z-index: 2;
  } /* Responsive */ /*====Particles====*/
  .particles-js-canvas-el {
    position: absolute;
    top: 0;
    z-index: 1;
  }
  #loading-area {
    width: 100%;
    height: 100%;
    background-color: #1d1d1d;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 1;
    z-index: 9999;
    background-image: url(../images/loading.svg);
    background-repeat: no-repeat;
    background-size: 60px;
    background-position: center;
  } /* Support and Buy Button */
  .theme-btn {
    background-color: #ffffff;
    border-radius: 40px;
    bottom: 10px;
    color: #ffffff;
    display: table;
    height: 50px;
    left: 10px;
    min-width: 50px;
    position: fixed;
    text-align: center;
    z-index: 9999;
  }
  .theme-btn i {
    font-size: 22px;
    line-height: 50px;
  }
  .theme-btn.bt-support-now {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1ebbf0+8,39dfaa+100 */
    background: #1ebbf0; /* Old browsers */
    background: -moz-linear-gradient(
      45deg,
      #1ebbf0 8%,
      #39dfaa 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      45deg,
      #1ebbf0 8%,
      #39dfaa 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      45deg,
      #1ebbf0 8%,
      #39dfaa 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1ebbf0', endColorstr='#39dfaa',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    bottom: 70px;
  }
  .theme-btn.bt-buy-now {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1fdf61+0,88BA46+100 */
    background: #1fdf61; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #a3d179 0%,
      #88ba46 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #a3d179 0%,
      #88ba46 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #a3d179 0%,
      #88ba46 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1fdf61', endColorstr='#88BA46',GradientType=0 ); /* IE6-9 */
  }
  .theme-btn:hover {
    color: #fff;
    padding: 0 20px;
  }
  .theme-btn span {
    display: table-cell;
    vertical-align: middle;
    font-size: 16px;
    letter-spacing: -15px;
    opacity: 0;
    line-height: 50px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    text-transform: uppercase;
  }
  .theme-btn:hover span {
    opacity: 1;
    letter-spacing: 1px;
    padding-left: 10px;
  }
  .at-expanding-share-button[data-position="bottom-left"] {
    bottom: 130px !important;
  } /* Support and Buy Button END */
}
