/*==========================Custom css==========================*/ /* h1 to h6 */
.h1 {
  font-size: 80px;
  font-weight: 700;
}
.h2 {
  font-size: 40px;
  font-weight: 700;
}
.h3 {
  font-size: 35px;
  font-weight: 700;
}
.h4 {
  font-size: 30px;
}
.h5 {
  font-size: 28px;
  font-weight: 500;
}
.h6 {
  font-size: 24px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "oswald", sans-serif;
}
.font-roboto {
  font-family: roboto;
}
.font-poppins {
  font-family: "Poppins", sans-serif;
}
.dis-block {
  display: block;
}
.bg-img-fix {
  background-size: cover;
}
.bg-secondry {
  background-color: #2c4a6b;
}
.text-gray-dark {
  color: #9a9a9a;
} /* Slider */
.main-slider .button-lg {
  padding: 15px 30px;
} /* Content Inner */
.content-inner {
  padding-top: 70px;
  padding-bottom: 40px;
}
.content-inner-1 {
  padding-top: 70px;
  padding-bottom: 70px;
}
.content-inner-2 {
  padding-top: 70px;
  padding-bottom: 0px;
} /* About Us */
.about-us {
  min-height: 700px;
}
.about-us .dez-tabs .nav-tabs {
  border: 0 none;
  left: 61px;
  position: absolute;
  top: 0;
  -o-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -o-transform-origin: left top 0;
  -webkit-transform-origin: left top 0;
  -moz-transform-origin: left top 0;
  transform-origin: left top 0;
  width: auto;
}
.about-us .dez-tabs .nav-tabs li {
  display: inline-block;
  margin: 0;
  background-color: #f6f6f6;
  text-align: center;
  float: left;
  border-right: 4px solid #e8e8e8;
}
.about-us .dez-tabs .about-contant {
  padding: 30px 30px 10px 30px;
}
.about-us .dez-tabs {
  position: relative;
}
.about-us .dez-tabs .tab-content {
  margin-left: 60px;
  border: 0;
}
.about-us .dez-tabs .tab-content h3 {
  margin-top: 0;
}
.about-us .dez-tabs .nav-tabs li a {
  border: 0;
  -o-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.about-us .dez-tabs .nav-tabs li.active {
}
.owl-none .owl-nav {
  display: none;
}
.about-us .dez-tabs .nav-tabs li.active a {
  border: 0;
  color: #000;
}
.about-us .dez-tabs .nav-tabs li.active a h4,
.about-us .dez-tabs .nav-tabs li.active a span {
  color: #000;
}
.about-us .dez-tabs .nav-tabs li h4 {
  margin: 0;
  text-transform: uppercase;
  color: #757575;
}
.about-us .dez-tabs .nav-tabs li a span {
  font-weight: 300;
  font-size: 14px;
} /* Service Head */
.service-head {
  font-family: "Open Sans", sans-serif;
  margin-bottom: 20px;
  margin-top: -46px;
  padding: 15px 0;
  position: relative;
  z-index: 1;
}
.service-head:after {
  background: inherit;
  content: "";
  height: 37px;
  left: -18px;
  margin-top: -19px;
  position: absolute;
  top: 50%;
  -o-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 37px;
  z-index: -1;
}
.service-head:before {
  background: inherit;
  content: "";
  height: 37px;
  right: -18px;
  margin-top: -19px;
  position: absolute;
  top: 50%;
  -o-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 37px;
  z-index: -1;
} /* Our Project */
.dez-project-left {
  position: relative;
}
.dez-project-left:after {
  content: "";
  height: 100%;
  opacity: 0.8;
  position: absolute;
  right: 15px;
  top: 0;
  width: 1000px;
  z-index: -2;
} /* service carousel */
.service-carousel .date {
  position: absolute;
  right: 0;
  padding: 1px 5px;
  color: #000;
  top: -22px;
}
.service-carousel .ow-entry-content {
  position: relative;
  border: 0;
  text-align: center;
}
.service-carousel .ow-entry-content h4 {
  margin-top: 0;
  margin-bottom: 5px;
}
.service-carousel.owl-theme .owl-controls {
  margin-top: 0;
  position: absolute;
  right: 8.33333%;
  text-align: center;
  top: -80px;
  padding: 0 15px;
}
.service-carousel .owl-prev,
.service-carousel .owl-next {
  border: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 40px;
}
.service-carousel .owl-prev:hover,
.service-carousel .owl-next:hover {
  opacity: 0.8;
} /* dez team */
.dez-team-1 .dez-media {
  border-bottom: 4px solid #333232;
  border-top: 4px solid #dadada;
}
.dez-team-1 .dez-social-icon li a {
  height: 30px;
  width: 30px;
  border: 0;
  color: #333232;
  padding: 0;
  line-height: 30px;
  font-size: 15px;
}
.dez-team-1 .dez-img-overlay6:before,
.dez-team-1 .dez-img-overlay6:after {
  background-image: url(../images/overlay/rrdiagonal-line.png);
  background-color: rgba(0, 0, 0, 0);
  opacity: 0.2;
} /* testimoniyal | client-logo */ /* client-logo */
.client-logo ul {
  margin: 0 -10px;
}
.client-logo {
  padding: 0;
}
.client-logo ul li {
  display: inline-block;
  float: left;
  padding: 0 10px;
  width: 33.33%;
  margin-bottom: 20px;
}
.client-logo img {
  width: 100%;
} /* testimonials */
.testimonial-section {
  position: relative;
  z-index: 1;
}
.testimonial-section:after {
  background: #f2f2f2 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.testimonial-section .col-md-6 {
  padding-left: 30px;
  padding-right: 30px;
}
.testimonial-section .row {
  margin-left: -30px;
  margin-right: -30px;
} /*testimonial 1*/
.testimonial-7 .testimonial-text {
  border: 2px solid #e6e6e6;
  padding: 18px;
}
.testimonial-7 .testimonial-detail {
  padding: 0 0 0 20px;
  margin-top: -35px;
}
.testimonial-7 .testimonial-pic {
  box-shadow: 0 0 0 2px #e6e6e6;
  width: 70px;
  height: 70px;
}
.testimonial-7 .testimonial-text p {
  font-size: 16px;
  line-height: 30px;
}
.testimonial-7 .info p {
  display: inline-block;
  position: relative;
  top: -10px;
  padding: 0 10px;
}
.testimonial-7 .owl-controls .owl-nav {
  bottom: 0;
  margin-top: 0;
  position: absolute;
  right: 0;
}
.testimonial-7 .owl-prev,
.testimonial-7 .owl-next {
  background-color: #cccccc;
  border: 0 none;
}
.bg-black-1 {
  background-color: #2e1e13;
} /* blog */
.blog-info {
  background-color: rgba(0, 0, 0, 0.9);
  left: 0;
  list-style: outside none none;
  margin: 0;
  padding: 7px 15px;
  position: absolute;
  top: -34px;
  width: 100%;
}
.blog-info li {
  display: inline-block;
  padding: 0 5px 0px 5px;
  line-height: 14px;
  border-right: 1px solid;
}
.blog-info li:hover,
.blog-info li a:hover {
  color: #fff;
} /* index 2 */
.company-status .counter {
  font-family: Roboto;
  font-size: 60px;
  font-weight: 300;
} /* about-service */
.about-service .icon-bx-wraper {
  border: none;
}
.about-service .icon-bx-wraper:nth-child(2) {
  margin-top: -40px;
}
.about-service {
  margin-top: -150px;
}
.gallery-projects .dez-gallery-box {
  margin-bottom: 0;
}
@media only screen and (max-width: 1280px) {
  .blog-info li {
    font-size: 13px;
  }
  .service-head {
    font-size: 15px;
  }
}
@media only screen and (max-width: 991px) {
  .about-img {
    display: none;
  }
  .about-us .dez-tabs .about-contant {
    padding: 15px 15px 10px 15px;
  }
  .get-a-quote {
    text-align: center;
  }
  .get-a-quote .pull-right {
    width: 100%;
    padding: 10px 0;
  }
  .get-a-quote {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .testimonial-section:after {
    left: 100%;
  }
  .testimonial-section {
    color: #fff;
  }
  .testimonial-section .col-md-6 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .testimonial-section .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .about-us .dez-tabs .nav-tabs,
  .about-us .dez-tabs .nav-tabs li a {
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    position: unset;
  }
  .about-us .dez-tabs .nav-tabs {
    background-color: #f6f6f6;
    width: 100%;
  }
  .about-us .dez-tabs .tab-content,
  .about-us .dez-tabs .nav-tabs {
    margin-left: 0;
    display: block;
    clear: both;
  }
  .about-us .dez-tabs .nav-tabs li.active {
    border-right: 0;
  }
  .about-us .dez-tabs .nav-tabs li {
    border-right: 0;
    width: 100%;
  }
  .about-us {
    padding-top: 20px;
  }
  .dez-tabs.vertical .tab-pane {
  }
  .dez-project-left:after {
    right: 0;
  }
  .dez-service {
    padding: 30px 15px;
  }
  .dez-service .service-carousel {
    margin-top: 0;
  }
  .dez-service .owl-stage-outer {
    margin-bottom: 30px;
  }
  .service-carousel.owl-theme .owl-controls {
    position: unset;
  }
  .testimonial-section:after {
    content: none;
  }
  .footer-bottom .text-left,
  .footer-bottom .text-right {
    text-align: center;
  }
} /* Team Section */
.skew-content-box ul li a {
  color: #fff;
  font-size: 14px;
  width: auto;
  padding: 0px 10px;
  line-height: 24px;
}
.skew-content-box {
  display: inline;
  left: -1px;
  padding: 5px 20px;
  position: absolute;
  top: -15px;
  width: auto;
}
.skew-content-box:after {
  background-color: inherit;
  content: "";
  height: 100%;
  position: absolute;
  right: -10px;
  top: 0;
  transform: skewX(20deg);
  width: 20px;
  z-index: 1;
} /* Coming Soon Page */
.countdown .date span {
  font-size: 80px;
  font-weight: bold;
}
.countdown .date {
  min-width: 140px;
  color: #fff;
  display: inline-block;
}
.countdown .date text {
  border: 1px solid;
  border-radius: 20px;
  display: block;
  margin: 0 auto;
  padding: 5px 1px;
  width: 100px;
}
.coming-soon-content h2 {
  font-size: 50px;
}
.coming-soon-content p {
  max-width: 800px;
  margin: 0px auto;
}
.dez-coming-soon {
  height: 100%;
  width: 100%;
}
.dez-coming-soon .container {
  position: relative;
}
.dez-coming-soon-full {
  position: fixed;
  overflow-y: scroll;
}
.dez-coming-bx {
  margin-top: 13%;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.sidenav.coming-side-bar {
  background-color: rgba(0, 0, 0, 0.9);
}
.dez-coming-soon.two-box {
  width: 50%;
  position: fixed;
  right: 0;
} /* Login */
.dez-login {
  background-color: #292929;
  bottom: 0;
  display: table;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.login-form {
  max-width: 400px;
  margin: 0px auto;
  background: #fff;
}
.dez-form .form-control {
  background: #dde3ec;
  height: 44px;
}
.dez-form .form-group {
  margin-bottom: 15px;
}
@media only screen and (max-width: 991px) {
  .dez-coming-soon.two-box,
  .dez-coming-soon.two-box .dez-coming-bx {
    position: relative;
    width: 100%;
  }
  .dez-coming-soon.two-box .dez-coming-bx {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .dez-coming-soon.two-box .dez-coming-bx h2 {
    margin-top: 0;
  }
  .coming-side-bar {
    background: #fff;
  }
  .skew-content-box {
    padding: 5px;
  }
  .skew-content-box ul li a {
    margin-right: 3px;
    font-size: 13px;
  }
  .dez-social-icon {
    margin: 0;
  }
  .skew-content-box ul li a {
    padding: 5px;
  }
  .request-a-quote:before {
    left: 0;
    transform: skewX(0deg);
  }
  .header-style-4 .slide-up {
    position: unset;
  }
}
@media only screen and (max-width: 767px) {
  .dez-book-now-content {
    margin-top: 10px;
    margin-bottom: 50px;
  }
  .coming-soon-content h2 {
    font-size: 30px;
  }
  .countdown .date span {
    font-size: 45px;
  }
  .countdown .date {
    max-width: 50px;
  }
  .dez-coming-bx {
    margin-top: 20px;
  }
  .our-service .service-box {
    margin-bottom: 30px;
  }
  .our-service {
    margin-top: 50px;
  }
  .dez-newsletter {
    padding: 15px;
  }
  .company-stats-worker {
    display: none;
  }
  .hidden-sm-down {
    display: none;
  }
  .main-slider .button-lg {
    font-size: 14px;
    padding: 8px 10px;
  }
} /* Responsive */
@media only screen and (max-width: 1024px) {
  .awesome-services .dez-info-has {
    position: unset;
  }
} /* Gallery-page */
.dez-hover a:active,
.dez-hover a:hover {
  color: #fff;
}
.testimonial-6 .quote-left:before {
  left: 15px;
}
.testimonial-6 .quote-left:before,
.testimonial-6 .quote-right:after {
  color: #b5b5b5;
  font-size: 18px;
} /* Testimonial Text */
.testimonial-6 .testimonial-text {
  padding: 25px 35px 70px 40px;
}
.testimonial-6 .testimonial-detail {
  position: relative;
  padding: 10px 15px;
  top: -30px;
  width: 85%;
}
.testimonial-6 .testimonial-pic {
  position: absolute;
  right: 20px;
  top: -50px;
  z-index: 2;
}
.testimonial-6 .quote-left:before,
.testimonial-6 .quote-right:after {
  color: #b5b5b5;
  font-size: 18px;
}
.testimonial-6 .quote-left:before {
  left: 15px;
}
.testimonial-6 .quote-right:after {
  right: 15px;
  bottom: 20px;
  top: auto;
}
.testimonial-6 .testimonial-detail:after {
  background-color: inherit;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: -23px;
  top: 0;
  transform: skewX(35deg);
  width: 50px;
  z-index: 1;
} /* spacing */
.site-footer .widget_getintuch {
  padding-top: 0;
}
.content-inner {
  padding-top: 70px;
  padding-bottom: 40px;
}
.testimonial-five .owl-controls {
  margin-top: 0px;
}
.dez-newsletter.style1 {
  background-color: rgba(255, 255, 255, 0.05);
}
.inner-haed {
  margin-bottom: 25px;
} /* Mobile Spacing */
@media only screen and (max-width: 767px) {
  .content-area {
    padding-top: 30px;
  }
  .page-content {
    padding-bottom: 30px;
  }
  .footer-top {
    padding-top: 30px;
  }
  .site-footer .widget {
    margin-bottom: 25px;
  }
  .section-full:last-child {
    margin-bottom: -30px;
  }
  .content-inner {
    padding-bottom: 20px;
    padding-top: 30px;
  }
  .section-head {
    margin-bottom: 25px;
  }
  .dez-project-left {
    padding-top: 30px;
  }
  .testimonial-section {
    padding-top: 30px;
  }
  .testimonial-section .owl-controls {
    margin-top: 20px;
  }
  .section-head p {
    padding-top: 0;
  }
  .h3 {
    font-size: 28px;
    margin-top: 10px;
  }
  .about-service .icon-bx-wraper:nth-child(2) {
    margin-top: 0;
  }
  .side-bar {
    margin-bottom: 30px;
  }
  .dez-post-title .post-title {
    margin-top: 10px;
  }
  .footer-info-box .newsletter {
    margin-bottom: 10px;
  }
  ol.commentlist li .star-rating {
    position: unset;
  }
  .comment-form [class*="comment-form"] {
    width: 100%;
  }
  .pricingtable-wrapper {
    margin-bottom: 30px;
  }
  .pricingtable-highlight {
    margin: 0;
  }
  .title-head {
    display: none;
  }
  .disnone-md {
    display: none;
  }
}
footer .footer-bottom .fa-heart {
  animation: 2.5s ease 0s normal none infinite running animateHeart;
  font-size: 16px;
}
@keyframes animateHeart {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.2);
  }
  10% {
    transform: scale(1.1);
  }
  15% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
} /* Sports Css */ /* Latest Result */
.team-box-left {
  position: relative;
}
.team-box-left .team-result {
  background-color: #2d3239;
  bottom: 0;
  padding: 20px;
  position: absolute;
  right: 10%;
  width: 65%;
  z-index: 1;
}
.team-box-left .team-result:after {
  background-color: #2d3239;
  content: "";
  height: 100%;
  left: -65px;
  position: absolute;
  top: 0;
  transform: skew(-38deg);
  width: 160px;
  z-index: -1;
}
.team-box-left .player {
  position: relative;
  z-index: 2;
}
.team-box-right {
  position: relative;
}
.team-box-right .team-result {
  background-color: #2d3239;
  bottom: 0;
  padding: 20px;
  position: absolute;
  left: 10%;
  width: 65%;
  z-index: 1;
}
.team-box-right .team-result:after {
  background-color: #2d3239;
  content: "";
  height: 100%;
  right: -65px;
  position: absolute;
  top: 0;
  transform: skew(38deg);
  width: 160px;
  z-index: -1;
}
.team-box-right .player {
  position: relative;
  z-index: 2;
}
.score {
  display: block;
  font-size: 120px;
  font-family: oswald;
  font-weight: 600;
  color: #2d3239;
}
.score-info p {
  font-size: 13px;
  font-weight: 600;
  margin-top: -20px;
}
.vs {
  display: block;
  font-size: 80px;
  font-family: oswald;
  font-weight: 600;
  color: #d0d0d0;
}
.score-board {
  position: relative;
  z-index: 1;
}
.score-board:after {
  background: #e5e5e5;
  bottom: 0px;
  content: "";
  height: 35%;
  position: absolute;
  width: 100%;
  z-index: -1;
} /* Match Stats */
.loction strong {
  width: 100%;
  display: block;
  font-family: oswald;
  font-weight: 400;
  font-size: 16px;
}
.next-match-team a span {
  float: left;
  color: #2d3239;
  margin: 0 5px;
  font-family: oswald;
  font-size: 22px;
  line-height: 30px;
}
.next-match-team a {
  clear: both;
}
.country {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}
.country img {
  max-width: 100%;
  width: 100% !important;
}
.vs-team {
  outline: 1px solid #ffffff;
  outline-offset: -20px;
  display: block;
  margin: 0px auto;
  height: 70px;
  transform: rotate(45deg);
  width: 70px;
}
.vs-team span {
  color: #2d3239;
  display: block;
  font-family: oswald;
  font-size: 24px;
  left: 0;
  line-height: 70px;
  text-align: center;
  transform: rotate(-45deg);
  width: 100%;
} /* Header */ /* Our Achievements */
.our-achievements .dez-info-has:hover {
  top: 0;
}
.our-achievements .dez-info-has {
  top: -30px;
}
.our-achievements .skew-triangle.dez-info-has {
  position: absolute;
}
.our-achievements .skew-triangle.right-top:after {
  border-top: 15px solid #fff;
} /* Team Section */
.dez-team {
  position: relative;
  z-index: 2;
}
.dez-team:after {
  background: inherit;
  content: "";
  height: 80px;
  left: 0;
  position: absolute;
  top: -25px;
  transform: skewY(10deg) skewX(125deg);
  width: 100%;
  z-index: -1;
}
.dez-staff:hover {
  box-shadow: 0 0 17px 0 #909090;
}
.blog-details .skew-triangle:after {
  border-top-color: #fff;
}
.blog-details .ow-post-meta li i {
  color: #fff;
}
.blog-details .dez-info-has.skew-triangle {
  position: absolute;
}
.blog-details .skew-angle-1:before {
  background-color: inherit;
  content: "";
  height: 50px;
  left: 0;
  position: absolute;
  top: -20px;
  transform: skewY(4deg);
  width: 100%;
  z-index: -1;
} /* Testimoniyal Block */
.testimoniyal-block .owl-controls {
  margin-top: -15px;
  position: relative;
  z-index: 1;
}
.testimoniyal-block .testimonial-1 {
  border: 1px solid #ffc300;
  margin: 0 auto;
  max-width: 800px;
} /* Footer Overlay */
.footer-overlay .footer-top {
  background-color: rgba(0, 0, 0, 0.7);
  background-image: url(../images/overlay/rrdiagonal-line.png);
  background-size: auto auto;
}
.footer-overlay .footer-bottom {
  background-color: rgba(0, 0, 0, 0.8);
  background-image: url(../images/overlay/rrdiagonal-line.png);
  background-size: auto auto;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.footer-skew .footer-top {
  position: relative;
  overflow: hidden;
  padding-top: 120px;
}
.footer-skew .footer-top:before,
.footer-skew .footer-top:after {
  background: #ffffff;
  content: "";
  position: absolute;
}
.footer-skew .footer-top:after {
  height: 80px;
  left: 0;
  top: -60px;
  transform: skewX(0deg) skewY(-7deg);
  width: 40%;
}
.footer-skew .footer-top:before {
  height: 85px;
  right: 0;
  top: -80px;
  transform: skewX(0deg) skewY(5deg);
  width: 60%;
} /* Responsive Css */
@media only screen and (max-width: 1200px) {
  .score {
    font-size: 90px;
  }
  .team-box-left .team-result:after {
    left: -15px;
    transform: skew(-10deg);
  }
  .team-box-right .team-result:after {
    right: -15px;
    transform: skew(10deg);
  }
  .team-box-left .team-result,
  .team-box-right .team-result {
    width: 85%;
  }
  .our-achievements .skew-triangle.dez-info-has {
    overflow: scroll;
  }
}
@media only screen and (max-width: 991px) {
  .player img {
    width: 110px;
  }
  .score,
  .vs {
    font-size: 30px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .score-board:after {
    display: none;
  }
  .score,
  .vs {
    margin-bottom: 0;
  }
  .score-info p {
    margin: 0;
  }
  .score-info {
    width: 200px;
    margin: 20px auto 0;
  }
  .next-match-team a span {
    display: none;
  }
  .next-match-team a span.country {
    display: block;
  }
  .footer-bottom .text-left,
  .footer-bottom .text-right,
  .footer-bottom .text-center {
    text-align: center;
    margin-bottom: 3px;
  }
  .h2 {
    font-size: 24px;
  }
  .blog-details .dez-info-has.skew-triangle {
    position: unset;
  }
} /* Effict 8 */
.box-gap:hover .dez-info-has {
  transform: scale(1);
}
.box-gap .dez-info-has {
  transform: scale(0.8);
}
.box-gap:hover .dez-info-has,
.box-gap .dez-info-has {
  height: 85%;
  left: 7.5%;
  top: 7.5%;
  width: 85%;
}
.box-gap .dez-info-has.bg-white {
  background: #fff;
}
.no-gap:hover .dez-info-has {
  width: 100%;
  height: 100%;
  transform: scale(1);
  top: 0;
  left: 0;
}
.no-gap .dez-info-has {
  width: 100%;
  height: 100%;
  transform: scale(0.8);
  top: 0;
  left: 0;
}
.no-gap .dez-info-has.bg-white {
  background: #fff;
} /* Index 2 */
.dez-box-list.widget_archive {
  border-color: #303030;
}
.dez-box-list.widget_archive ul li {
  padding: 10px 10px 10px 30px;
}
.dez-box-list.widget_archive ul li:before {
  left: 15px;
}
.border-light {
  border-color: #e5e5e5;
}
.bg-gray-dark {
  background-color: #1d1d1d;
}
.bg-gray-middle {
  background-color: #2c2c2c;
}
.border-light.bg-gray-middle {
  border-color: #303030 !important;
}
.border-light.bg-gray-middle.table-bordered > tbody > tr > td,
.border-light.bg-gray-middle.table-bordered > tbody > tr > th,
.border-light.bg-gray-middle.table-bordered > tfoot > tr > td,
.border-light.bg-gray-middle.table-bordered > tfoot > tr > th,
.border-light.bg-gray-middle.table-bordered > thead > tr > td,
.border-light.bg-gray-middle.table-bordered > thead > tr > th {
  border-color: #303030;
  padding-top: 15px;
  padding-bottom: 15px;
  background: transparent;
  color: #fff;
}
.col-set-block > [class*="col-lg"],
.col-set-block > [class*="col-md"],
.col-set-block > [class*="col-xs"],
.col-set-block > [class*="col-sm"] {
  padding-left: 10px;
  padding-right: 10px;
}
.col-set-block {
  margin-left: -10px;
  margin-right: -10px;
}
.col-set-block .widget {
  margin-bottom: 20px;
}
.dez-head-bx {
  background-color: #262626;
  border-left: 5px solid;
}
.dez-head-bx.m-a-out {
  padding: 20px;
}
.next-match-count {
  position: relative;
}
.next-match-count:before {
  background: #2c2c2c none repeat scroll 0 0;
  content: "";
  height: 100%;
  position: absolute;
  right: -75px;
  top: 0;
  transform: skewX(-15deg);
  width: 105px;
} /* */
.white-block .dez-head-bx {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-left: 5px solid #ffc300;
} /* Table Headding */
.table-bordered .table-headding th {
  color: #fff;
  border-bottom: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
.team-thumb {
  width: 20px;
  display: inline-block;
  margin-right: 10px;
}
.team-thumb img {
  width: 100%;
}
.team-compact {
  max-width: 500px;
  margin: 0px auto;
}
.team-logo {
  display: block;
  max-width: 100px;
  margin: 0px auto;
}
.team-name {
  display: block;
}
.dez-vs-team {
  position: relative;
}
.dez-vs-team:before {
  content: "VS";
  font-family: oswald;
  font-size: 40px;
  position: absolute;
  right: -25px;
  top: 25%;
  width: 50px;
} /* Login */ /* Coming Soon Page */
.coming-soon-content h2 {
  font-size: 50px;
}
.coming-soon-content p {
  max-width: 800px;
  margin: 0px auto;
}
.dez-coming-soon {
  height: 100%;
  position: absolute;
  width: 100%;
}
.dez-coming-soon .container {
  position: relative;
}
.dez-coming-bx {
  position: absolute;
  margin-top: 13%;
  width: 100%;
  left: 0;
  padding-left: 15px;
  padding-right: 15px;
}
.sidenav.coming-side-bar {
  background-color: rgba(0, 0, 0, 0.9);
}
.dez-coming-soon.two-box {
  width: 50%;
  position: fixed;
  right: 0;
} /* Login */
.dez-login {
  background-color: #2c4a6b;
  bottom: 0;
  display: table;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.login-form {
  max-width: 400px;
  margin: 0px auto;
  background: #fff;
}
.dez-form .form-control {
  background: #dde3ec;
  height: 44px;
}
.dez-form .form-group {
  margin-bottom: 15px;
}
@media only screen and (max-width: 991px) {
  .dez-coming-soon.two-box,
  .dez-coming-soon.two-box .dez-coming-bx {
    position: relative;
    width: 100%;
  }
  .dez-coming-soon.two-box .dez-coming-bx {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .dez-coming-soon.two-box .dez-coming-bx h2 {
    margin-top: 0;
  }
  .coming-side-bar {
    background: #fff;
  }
}
@media only screen and (max-width: 767px) {
  .dez-book-now-content {
    margin-top: 10px;
    margin-bottom: 50px;
  }
  .coming-soon-content h2 {
    font-size: 30px;
  }
  .dez-coming-bx {
    margin-top: 20px;
  }
}
.font-roboto h1,
.font-roboto h2,
.font-roboto h3,
.font-roboto h4,
.font-roboto h5,
.font-roboto h6 {
  font-family: roboto;
} /* Index 3 */
.mack-an-appointment.border-1 {
  border-color: #dddddd;
} /* Time Bar Styles 1 */
.countdown.dez-style-1 .time {
  position: relative;
  color: #2d3239;
  font-size: 70px;
  font-weight: 600;
}
.countdown.dez-style-1 .time,
.countdown.dez-style-1 .time-counting {
  width: 100%;
  display: block;
  border: 0 none;
  border-radius: 0;
  color: #2d3239;
  display: block;
  width: 100%;
}
.countdown.dez-style-1 .time-counting {
  font-size: 14px;
}
.countdown.dez-style-1 .date {
  min-width: 90px;
  display: inline-block;
  font-family: oswald;
}
.countdown.dez-style-1 .time:after {
  background-color: rgba(255, 255, 255, 0.1);
  content: "";
  height: 52%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .countdown.dez-style-1 .date {
    position: relative;
    min-width: 65px;
  }
  .countdown.dez-style-1 .time {
    font-size: 40px;
  }
} /* Time Bar Styles 2 */
.countdown.dez-style-2 .date span {
  font-size: 80px;
  font-weight: bold;
}
.countdown.dez-style-2 .date {
  min-width: 150px;
  color: #fff;
  display: inline-block;
}
.countdown.dez-style-2 .date text {
  border: 1px solid;
  border-radius: 20px;
  display: block;
  margin: 0 auto;
  padding: 5px 1px;
  width: 100px;
}
@media only screen and (max-width: 767px) {
  .countdown.dez-style-2 .date .time {
    font-size: 45px;
  }
  .countdown.dez-style-2 .date {
    max-width: 50px;
  }
} /* Time Bar Styles 3 */
.dez-style-3.countdown .time {
  font-weight: 600;
  color: #000;
  font-size: 24px;
}
.dez-style-3.countdown .date {
  color: #000;
  border: 1px solid #000;
  padding: 10px 15px;
  min-width: 90px;
  display: inline-block;
  font-family: oswald;
}
.dez-style-3.countdown .time,
.dez-style-3.countdown .time-counting {
  border: 0 none;
  display: inline-block;
  padding: 0;
  width: auto;
}
.dez-style-3.countdown .time-counting {
  font-size: 13px;
}
@media only screen and (max-width: 767px) {
  .dez-style-3.countdown .date {
    min-width: 110px;
    margin-bottom: 5px;
  }
} /* Home 4 */
.content-right .icon-bx-wraper.bx-style-1,
.content-right .icon-bx-wraper.bx-style-2,
.content-right .mack-an-appointment.border-1,
.content-right .border-1,
.content-right .mack-an-appointment .form-control {
  border-color: #3c4044;
}
.content-right .mack-an-appointment .form-control {
}
.content-right .dez-separator[class*="style-"]:after,
.content-right .dez-separator[class*="style-"]:before,
.content-right .mack-an-appointment .form-control {
  background-color: #3c4044;
}
.content-right .bootstrap-select .dropdown-toggle {
  background-color: #3c4044 !important;
  border-color: #3c4044 !important;
}
.content-right .footer-top {
  background-color: #292c30;
}
.content-right .footer-bottom {
  background-color: #313539;
} /* Service */
.service-box {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
  transition: all 2s ease 0s;
}
.service-box:hover {
  transform: rotateY(360deg);
  transition: all 2s ease 0s;
}
.skew-angle {
  overflow: hidden;
  position: relative;
}
.skew-angle:after {
  bottom: -30px;
  content: "";
  height: 60px;
  left: -30px;
  position: absolute;
  transform: rotate(45deg);
  width: 60px;
}
.our-service {
  margin-top: -70px;
} /* Awesome Services */
.awesome-services .dez-info-has {
  top: 0;
}
.dez-info-has.bg-secondry {
  background-color: rgba(44, 74, 107, 0.8);
}
.dez-info-has.bg-secondry:hover {
} /* Request A Quote */
.request-a-quote:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #2c4a6b;
  left: -45%;
  z-index: 1;
  top: 0;
  opacity: 0.8;
  transform: skewX(15deg);
} /* Team Section */
.skew-content-box ul li a {
  color: #fff;
  font-size: 14px;
  width: auto;
  padding: 5px 10px;
}
.skew-content-box {
  display: inline;
  left: -1px;
  padding: 5px 20px;
  position: absolute;
  top: -15px;
  width: auto;
}
.skew-content-box:after {
  background-color: inherit;
  content: "";
  height: 100%;
  position: absolute;
  right: -10px;
  top: 0;
  transform: skewX(20deg);
  width: 20px;
  z-index: 1;
} /* Latest Blog */
.skew-content-box .latest-blog-date li a {
  padding: 2px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
} /* Testimonial Text */
.testimonial-6 .testimonial-text {
  padding: 25px 35px 70px 40px;
}
.testimonial-6 .testimonial-detail {
  position: relative;
  padding: 10px 15px;
  top: -30px;
  width: 85%;
}
.testimonial-6 .testimonial-pic {
  position: absolute;
  right: 20px;
  top: -50px;
  z-index: 2;
}
.testimonial-6 .quote-left:before,
.testimonial-6 .quote-right:after {
  color: #b5b5b5;
  font-size: 18px;
}
.testimonial-6 .quote-left:before {
  left: 15px;
}
.testimonial-6 .quote-right:after {
  right: 15px;
  bottom: 20px;
  top: auto;
}
.testimonial-6 .testimonial-detail:after {
  background-color: inherit;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: -23px;
  top: 0;
  transform: skewX(35deg);
  width: 50px;
  z-index: 1;
} /* Footer */
.dez-newsletter {
  background-color: rgba(255, 255, 255, 0.05);
}
.newsLetter-bx {
  margin-top: 25px;
}
.newsLetter-bx input {
  background-color: #fff;
  border: 0;
} /*====Pricing Table=====*/
.trans-pricing .pricingtable-wrapper {
  background-color: rgba(255, 255, 255, 0.2);
}
.trans-pricing .pricingtable-highlight {
  margin-bottom: 0;
}
.trans-pricing .pricingtable-features {
  border: 0;
}
.trans-pricing .pricingtable-footer,
.trans-pricing .pricingtable-features li:nth-child(2n),
.trans-pricing .pricingtable-price {
  background-color: rgba(255, 255, 255, 0.05);
  border-width: 0;
  color: #fff;
}
.trans-pricing .pricingtable-features li {
  border-width: 0;
}
.trans-pricing .pricingtable-title {
  background-color: rgba(0, 0, 0, 0);
}
.trans-pricing .pricingtable-bx,
.trans-pricing .pricingtable-features li {
  color: #fff;
}
.bg-secondry-dark {
  background-color: #3c4044;
}
.price-table .pricingtable-features li:nth-child(2n) {
  background-color: #3c4044;
}
.price-table .pricingtable-price,
.price-table .pricingtable-footer {
  border: 0;
  position: relative;
}
.price-table .pricingtable-wrapper.border-1,
.price-table .pricingtable-wrapper .border-1 {
  border-color: #3c4044;
}
.price-table .pricingtable-features,
.price-table .pricingtable-features li {
  border: 0;
}
.price-table .pricingtable-price-bx h2 {
  font-size: 60px;
  font-weight: bold;
  color: #fff;
  line-height: 65px;
}
.price-table .pricingtable-price-bx span {
  font-size: 20px;
  font-weight: bold;
  color: #2d3239;
}
.price-table .pricingtable-price.text-white:after,
.price-table .pricingtable-price.text-white:before {
  background-color: inherit;
  bottom: -10px;
  content: "";
  height: 30px;
  position: absolute;
  width: 50%;
  z-index: 2;
}
.price-table .pricingtable-price.text-white:after {
  -moz-transform: skewY(7deg);
  -webkit-transform: skewY(7deg);
  -o-transform: skewY(7deg);
  -ms-transform: skewY(7deg);
  transform: skewY(7deg);
  left: 0;
}
.price-table .pricingtable-price.text-white:before {
  -moz-transform: skewY(-7deg);
  -webkit-transform: skewY(-7deg);
  -o-transform: skewY(-7deg);
  -ms-transform: skewY(-7deg);
  transform: skewY(-7deg);
  left: auto;
  right: 0;
}
.client-pic li {
  position: relative;
  list-style: none;
  display: inline-block;
}
.client-pic li a {
  border: 2px solid;
  border-radius: 100%;
  display: block;
  height: 80px;
  overflow: hidden;
  width: 80px;
}
.client-think {
  position: relative;
  max-width: 780px;
  margin: 0px auto;
  background-color: #e8e8e7;
  border-radius: 30px;
}
.client-think:before {
  content: "\f10e";
  font-family: "FontAwesome";
  bottom: 0;
  color: rgba(0, 0, 0, 0.1);
  font-size: 80px;
  position: absolute;
  right: 30px;
}
.client-pic li.active:after {
  content: "\f0d7";
  font-family: "FontAwesome";
  color: #e8e8e7;
  display: block;
  font-size: 60px;
  left: 50%;
  margin-left: -15px;
  overflow: hidden;
  position: absolute;
  top: -77px;
} /* White */
.client-think-white {
  border-radius: 30px;
  margin: 0 auto;
  max-width: 780px;
  position: relative;
}
.client-think-white:before {
  bottom: 0;
  color: rgba(0, 0, 0, 0.1);
  content: \f10e;
  font-family: "FontAwesome";
  font-size: 80px;
  position: absolute;
  right: 30px;
} /*====Pricing Table End=====*/ /* Home 8 */
ul.u-list {
  margin: 0 0 15px 0;
  padding: 0;
}
ul.u-list li {
  list-style: none;
} /* Home 9 */
.dez-book-now-content {
  margin-top: 25%;
}
.dez-book-now-content .h2 {
  font-size: 50px;
} /* Form Tp Classes */
.dez-book-now-form.tp-light {
  background-color: rgba(0, 0, 0, 0.3);
}
.dez-book-now-form.tp-light .form-control,
.dez-book-now-form.tp-light .bootstrap-select .dropdown-toggle {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
}
.dez-book-now-form.tp-dark {
  background-color: rgba(0, 0, 0, 0.7);
}
.dez-book-now-form.tp-dark .form-control,
.dez-book-now-form.tp-dark .bootstrap-select .dropdown-toggle {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}
@media only screen and (max-width: 767px) {
  .dez-book-now-content {
    margin-top: 10px;
    margin-bottom: 50px;
  }
} /* Table Effict */
.dez-score-table tr {
  transition: all 0.5s ease 0.2s;
}
.dez-score-table tr:hover {
  transform: scale(1.02);
  z-index: 2;
  background: #262626;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
}
.dez-head-bx {
  border-left: 1px solid #ffc300;
}
.dez-head-bx.skew-triangle.right-top:after {
  right: 0px;
  top: 0px;
  border-top: 20px solid #ffc300;
  border-left: 20px solid rgba(0, 0, 0, 0);
} /* Club Media */
.dez-club-media .dez-info-has {
  background: rgba(0, 0, 0, 0)
    linear-gradient(to bottom, rgba(50, 49, 80, 0) 0%, rgba(0, 0, 0, 0.85) 100%)
    repeat scroll 0 0;
}
.dez-club-media .dez-info-has a {
  font-weight: 700;
  font-size: 15px;
}
.dez-club-media .dez-info-has a.site-button {
  font-size: 12px;
  font-weight: 600;
  color: #000;
}
.dez-club-media {
  margin-left: -10px;
  margin-right: -10px;
}
.dez-club-media .col-md-4 {
  padding-left: 10px;
  padding-right: 10px;
} /* Slider Next Preview */
.main-slider .erinyen.tparrows {
  min-height: 50px;
  min-width: 50px;
}
.main-slider .erinyen.tparrows:before {
  line-height: 50px;
}
.main-slider .erinyen .tp-arr-titleholder {
  line-height: 50px;
}
.main-slider .erinyen .tp-title-wrap {
  line-height: 50px;
  min-height: 50px;
} /* widget post Hover */
.widget-post:hover .dez-post-media {
  transform: scale(1.05);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}
.widget-post,
.widget-post .dez-post-info,
.widget-post .dez-post-media,
.widget-post:hover,
.widget-post:hover .dez-post-info,
.widget-post:hover .dez-post-media {
  transition: all 0.2s;
}
@media only screen and (max-width: 767px) {
  .blog-md .dez-post-media {
    margin: 0 0 15px 0;
    width: 100%;
  }
} /* bg-gray-dark transparent */
.bg-gray-dark.transparent .bg-gray-middle {
  background-color: rgba(0, 0, 0, 0.4);
}
.bg-gray-dark.transparent .border-light.bg-gray-middle {
  border-color: rgba(0, 0, 0, 0.2);
}
.bg-gray-dark.transparent .dez-head-bx {
  background-color: rgba(0, 0, 0, 0.6);
}
.bg-gray-dark.transparent .dez-score-table tr {
  transition: all 0.5s ease 0.2s;
}
.bg-gray-dark.transparent .dez-score-table tr:hover {
  transform: scale(1.02);
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
}
.bg-gray-dark.transparent .widget-post:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.bg-white.dez-score-table tr {
  transition: all 0.5s ease 0.2s;
}
.bg-white.dez-score-table tr:hover {
  transform: scale(1.02);
  z-index: 2;
  background-color: #cfcfcf;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
}
.dez-card.bg-white .widget-post:hover {
  background-color: #cfcfcf;
}
.page-wraper {
  background: #fff;
}
p {
  color: #4f4f4f;
} /* header */
@media only screen and (max-width: 1024px) {
  .header-curve .logo-header::before {
    right: -14px;
  }
  .footer-skew .footer-top:before {
    transform: skewX(0deg) skewY(6deg);
  }
}
@media only screen and (max-width: 768px) {
  .header-curve .logo-header::before {
    right: -50px;
  }
} /* Toggler */
.navicon {
  width: 20px;
  height: 18px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  border: 0;
  float: right;
}
.navicon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 18px;
  border-radius: 1px;
  opacity: 1;
  left: 8px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  background: #000;
}
.header-style-7 .navicon span,
.header-style-4.style-1 .navicon span,
.header-style-5 .navicon span,
.header-style-6 .navicon span {
  background: #fff;
}
.navicon span:nth-child(1) {
  top: 10px;
}
.navicon span:nth-child(2) {
  top: 17px;
}
.navicon span:nth-child(3) {
  top: 24px;
}
.navicon.open span:nth-child(1) {
  top: 16px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.navicon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.navicon.open span:nth-child(3) {
  top: 16px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.navbar-toggler {
  margin: 16px 0 16px 5px;
  padding: 17px 17px;
  border: 2px solid #ffc300;
  border-radius: 0px;
  outline: none;
}
[class*="bg-"] .navbar-toggler {
  border-color: #fff;
}
.is-fixed .navbar-toggler {
  margin: 16px 0 15px 5px;
}
.header-nav .nav > li .sub-menu li .fa {
  display: none;
}
.header-nav .nav > li .mega-menu > li > a {
  color: #000;
  font-weight: 600;
}
.header-style-7 .social-line li a {
  color: #e6e3e3;
}
.header-style-6 .social-line li a {
}
.header-style-3 .social-line li a span,
.header-style-6 .social-line li a span {
  margin-left: 5px;
}
.header-style-2 .social-line li a,
.header-style-3 .social-line li a,
.top-bar,
.header-style-4 .social-line li a,
.header-style-1 .top-bar .fa {
  color: #797979;
}
.header-style-1 .top-bar .fa {
  vertical-align: initial;
}
.dark.header-style-2 .social-line li a,
.dark.header-style-3 .social-line li a,
.dark .top-bar,
.dark.header-style-4 .social-line li a,
.dark.header-style-1 .top-bar .fa {
  color: #d2d2d2;
}
.social-bx {
  margin: 0px;
}
.dez-topbar-center,
.dez-topbar-left,
.dez-topbar-right {
}
.e-p-bx li {
  padding-left: 10px;
  padding-right: 0px;
}
.dez-topbar-left .social-line li a {
  padding-left: 15px;
  padding-right: 15px;
}
.header-style-5 .dez-topbar-left .social-line li a,
.header-style-4 .dez-topbar-left .social-line li a,
.header-style-3 .dez-topbar-left .social-line li a,
.header-style-2 .dez-topbar-left .social-line li a {
  padding-left: 15px;
}
.header-style-1 .top-bar .fa {
  border: 1px solid rgba(128, 128, 128, 0.1);
}
.accordion.space .accordion-item {
  margin-bottom: 5px !important;
}
.social-icon-lg.social-icon li {
  display: inline-block;
  font-size: 12px;
  padding: 0;
}
.social-icon.social-icon-lg li a {
  color: #ffffff;
  font-size: 14px;
  height: 35px;
  line-height: 34px;
  padding: 0;
  width: 35px;
  text-align: center;
}
.contact-style-1 .border-1 {
  border: 1px solid #eee;
}
@media only screen and (max-width: 991px) {
  .container {
    max-width: 750px;
  }
  .mo-left .header-nav .logo-header:nth-child(2) {
    display: none;
  }
  .header-nav .nav > li:last-child > a {
    border-bottom: 1px solid #e9e9e9;
  }
  .is-fixed .header-nav .nav > li > a,
  .header-nav .nav > li > a {
    padding: 12px 15px;
  }
  .header-style-6 .header-nav .nav > li .sub-menu,
  .header-style-6 .header-nav .nav > li .mega-menu {
    margin-left: 0px;
  }
  .is-fixed .header-nav .nav {
    height: auto;
  }
  .header-nav .nav li {
    display: block;
    width: 100%;
  }
  .mo-left .header-nav.nav-dark {
    background-color: #202020;
  }
  .mo-left .header-nav {
    overflow: hidden;
    position: fixed;
    width: 60px;
    left: -280px;
    height: 100% !important;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    top: 0;
    background-color: #fff;
    margin: 0;
    z-index: 99;
  }
  .mo-left .header-nav li.open a {
    position: relative;
  }
  .mo-left .header-nav.show {
    left: -1px;
    transition: all 0.8s;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    margin: 0;
    width: 260px;
  }
  .mo-left .navbar-toggler.open:after {
    background-color: rgba(0, 0, 0, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: scale(100);
    -o-transform: scale(100);
    -moz-transform: scale(100);
    -webkit-transform: scale(100);
    width: 100%;
    z-index: -1;
    transition: all 0.5s;
  }
  .mo-left .header-nav .logo-header {
    display: block;
    float: none;
    height: auto;
    max-width: 100%;
    padding: 30px 15px;
    width: 100%;
  }
  .mo-left .header-nav .logo-header img {
    max-width: unset;
    width: 200px;
  }
  .mo-left .navbar-toggler.open span {
    background: #fff;
  }
  .header-style-6 .navbar-toggler {
    margin: 17px 0 17px 5px;
    border-radius: 100%;
  }
  .extra-nav {
    margin-right: 0px;
  }
  .header-style-6 .is-fixed .extra-nav,
  .header-style-4 .extra-nav,
  .header-style-6 .extra-nav {
    padding: 17px 0;
  }
  .extra-nav {
    padding: 16px 0;
  }
  .header-style-6 .is-fixed .navicon span {
    background: #000;
  }
  .header-style-3 .header-curve .logo-header,
  .logo-header,
  .header-style-6 .logo-header {
    height: 70px;
    width: 170px;
  }
  .e-p-bx li {
    padding-left: 10px;
    padding-right: 0px;
  }
  .dez-topbar-left .social-line li a {
    padding-left: 0px;
    padding-right: 15px;
  }
  .header-style-5 .dez-topbar-left .social-line li a,
  .header-style-4 .dez-topbar-left .social-line li a,
  .header-style-3 .dez-topbar-left .social-line li a,
  .header-style-2 .dez-topbar-left .social-line li a {
    padding-left: 15px;
  }
  .dez-topbar-left,
  .dez-topbar-right,
  .header-style-1 .top-bar .dez-topbar-left,
  .header-style-1 .top-bar .dez-topbar-right,
  .header-style-2 .dez-topbar-left,
  .header-style-2 .dez-topbar-right,
  .header-style-6 .dez-topbar-left,
  .header-style-6 .dez-topbar-right {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 991px) {
  /* Mobile Responsive */
  .side-nav .nav.navbar-nav li a i.fa-chevron-down:before,
  .nav.navbar-nav li a i.fa-chevron-down:before {
    content: "\f078";
  }
  .side-nav .nav.navbar-nav li .sub-menu.open a i.fa-chevron-down:before,
  .nav.navbar-nav .sub-menuli.open a i.fa-chevron-down:before {
    content: "\f054";
  }
  .side-nav .nav.navbar-nav li a i.fa-chevron-down,
  .nav.navbar-nav li a i.fa-chevron-down,
  .nav.navbar-nav li a i.fa-angle-right {
    background-color: rgba(0, 0, 0, 0.2);
    color: #ffffff;
    height: 100%;
    line-height: 40px;
    position: absolute;
    right: 0;
    text-align: center;
    top: -6px;
    width: 50px;
    z-index: 3;
  }
  .header-nav .nav > li .sub-menu li i.fa-angle-right:before {
    content: "\f078";
    font-size: 10px;
  }
  .header-nav .nav > li .sub-menu li.open i.fa-angle-right:before {
    content: "\f054";
  }
  .header-nav .nav > li .sub-menu .sub-menu,
  .header-nav .nav > li:hover .sub-menu .sub-menu,
  .header-nav .nav > li:hover .sub-menu,
  .header-nav .nav > li:hover .mega-menu {
    display: none;
    opacity: 1;
    margin-top: 0;
  }
  .header-nav .nav li .sub-menu .sub-menu {
    display: none;
    opacity: 1;
    margin-top: 0;
  }
  .header-nav .nav > li.open > .sub-menu .sub-menu {
    display: none;
  }
  .header-nav .nav > li.open > .sub-menu li.open .sub-menu,
  .header-nav .nav > li.open > .mega-menu,
  .header-nav .nav > li.open > .sub-menu,
  .header-nav .nav > li ul.mega-menu ul {
    display: block;
    opacity: 1;
    margin-top: 0;
  }
  .header-nav .nav > li .sub-menu li .fa {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    margin: 0;
    right: -1px;
    color: inherit;
    background: transparent;
    display: block;
  }
  .nav.navbar-nav li .sub-menu li.open .fa-chevron-down:before {
    content: "\f054";
  }
  .header-nav .nav > li .mega-menu > li {
    padding: 0;
  }
  .header-nav .nav > li .mega-menu > li > a {
    display: none;
  }
  .header-nav .nav .mega-menu a i {
    display: inline-block;
    float: none;
    margin-top: 0;
  }
  .header-nav .nav .open > a,
  .header-nav .nav .open > a:focus,
  .header-nav .nav .open > a:hover {
    background-color: inherit;
    border-color: #e9e9e9;
  }
  .header-nav.nav-dark.nav .open > a,
  .header-nav.nav-dark .nav .open > a:focus,
  .header-nav.nav-dark .nav .open > a:hover {
    border-color: rgba(0, 0, 0, 0.2);
  }
  header .logo {
    display: block;
    padding: 4px 0;
  }
  .header-nav .nav > li > a {
  }
  .header-nav .nav i {
    margin-top: 6px;
  }
  .mo-left .header-nav.nav-dark {
    background-color: #202020;
  }
  .mo-left .header-nav {
    overflow: hidden;
    position: fixed;
    width: 60px;
    left: -280px;
    height: 100% !important;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    top: 0;
    background-color: #fff;
    margin: 0;
    z-index: 99;
  }
  .mo-left .header-nav li.open a {
    position: relative;
  }
  .mo-left .header-nav {
    overflow: scroll;
  }
  .navbar-nav {
    height: 100%;
  }
  .mo-left .header-nav.show {
    left: -1px;
    transition: all 0.8s;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    margin: 0;
    width: 280px;
    overflow: scroll;
  }
  .mo-left .navbar-toggler.open:after {
    background-color: rgba(0, 0, 0, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: fixed;
    right: -20px;
    top: -63px;
    transform: scale(100);
    -o-transform: scale(100);
    -moz-transform: scale(100);
    -webkit-transform: scale(100);
    width: 100%;
    z-index: -1;
    transition: all 0.5s;
    transform-origin: top right;
    margin: 0 0px 0px 20px;
    -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
  }
  .mo-left .header-nav .logo-header {
    display: block;
    float: none;
    height: auto;
    max-width: 100%;
    padding: 15px;
    width: 100%;
    overflow: hidden;
  }
  .mo-left .header-nav .logo-header img {
    max-width: unset;
    width: 180px;
  }
  .mo-left .header-nav .nav > li .sub-menu {
    box-shadow: none;
  }
  .mo-left .navbar-toggler.open span {
    background: #fff;
  } /* responsive strat when media screen [991px] css START*/
  .navbar-header {
    float: none;
  }
  .navbar-toggler {
    display: block;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-collapse.collapse.show {
    display: block !important;
  } /* responsive strat when medai screen [991px] css END*/
  .header-nav {
    clear: both;
    margin: 0 -15px;
    border-bottom: 1px solid #e9e9e9;
  }
  .header-nav .nav {
    float: none;
    margin: 0;
    background: #fff;
    width: 100%;
    display: block;
    height: auto;
  }
  .header-nav .nav li {
    float: none;
    display: block;
    width: 100%;
  }
  .header-nav .nav > li .sub-menu > li,
  .header-nav .nav > li .mega-menu > li {
    float: none;
    display: block;
    width: auto;
  }
  .header-nav .nav > li > a {
    padding: 12px 15px;
    border-top: 1px solid #e9e9e9;
    display: block;
    position: relative;
  }
  .header-nav .nav i {
    float: right;
  }
  .header-nav .nav > li > a:hover,
  .header-nav .nav > li > a:active,
  .header-nav .nav > li > a:focus {
    background-color: #f0f0f0;
    text-decoration: none;
  }
  .header-nav .nav > li .mega-menu > li:after {
    display: none;
  }
  .header-nav .nav > li ul,
  .header-nav .nav > li .sub-menu,
  .header-nav .nav > li .mega-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f6f6f6;
  }
  .header-nav .nav > li ul.mega-menu ul {
    display: none;
  }
  .header-nav .nav > li:hover > ul,
  .header-nav .nav > li:hover .sub-menu,
  .header-nav .nav > li:hover .mega-menu,
  .header-nav .nav > li .sub-menu li > .sub-menu {
    opacity: 1;
    visibility: visible;
    display: block;
    margin: 0;
  }
  .header-nav .nav > li ul.mega-menu li:hover ul {
    display: block;
  }
  .header-nav .nav > li .sub-menu .sub-menu,
  .header-nav .nav > li:hover .sub-menu .sub-menu,
  .header-nav .nav > li:hover .sub-menu,
  .header-nav .nav > li:hover .mega-menu {
    display: none;
    opacity: 1;
    margin-top: 0;
  }
  .header-nav .nav li .sub-menu .sub-menu {
    display: none;
    opacity: 1;
    margin-top: 0;
  }
  .header-nav .nav > li.open > .sub-menu .sub-menu {
    display: none;
  }
  .header-nav .nav > li.open > .sub-menu li.open .sub-menu,
  .header-nav .nav > li.open > .mega-menu,
  .header-nav .nav > li.open > .sub-menu,
  .header-nav .nav > li ul.mega-menu ul {
    display: block;
    opacity: 1;
    margin-top: 0;
  }
  .pagination-bx .pagination {
    margin-bottom: 20px;
  }
}
.dez-post-title .post-title {
  margin-top: 20px;
}
.bg-primary {
  color: #fff;
}
.breadcrumb-row ul li {
  display: inline;
}
.border {
  border: 0px;
} /* tab */
.dez-tabs.bg-tabs.vertical.right.border,
.dez-tabs.bg-tabs.vertical.border,
.dez-tabs.vertical.right.border,
.dez-tabs.vertical.border,
.dez-tabs.border-tp.border.bg-tabs,
.dez-tabs.border,
.dez-tabs.border.bg-tabs,
.dez-social-icon.border {
  border: 0 !important;
}
.dez-tabs .nav-tabs > li > button.active:hover {
  background-color: #fff;
  border-color: #ddd;
  border-bottom: 1px solid #fff;
}
.nav-tabs .nav-link.active,
.dez-tabs.bg-tabs .nav-tabs > li > button.active {
  border-bottom: 1px solid transparent;
  background-color: #fff;
}
.dez-tabs.vertical .nav-tabs li button.active {
  border-bottom: 1px solid #ddd;
}
.dez-tabs.vertical .nav-tabs li {
  width: 100%;
  margin-right: -1px;
}
.dez-tabs.vertical .nav-tabs li button.active {
  margin-right: -1px;
  border-right: none;
}
.dez-tabs.vertical .nav-tabs li button {
  margin-right: -1px;
}
.dez-tabs.vertical.right .nav-tabs li button.active {
  border-right: 1px solid #ddd;
  border-left: none;
}
.skew-content-box ul li a {
  padding: 0px 10px;
}
@media only screen and (max-width: 1024px) {
  .counter-box h5 {
    font-size: 13px;
    line-height: 22px;
  }
  .img-height {
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (max-width: 767px) {
  .dez-tabs.vertical .nav-tabs {
    width: 50px;
  }
  .dez-tabs.vertical .tab-content {
    margin-left: 49px;
  }
} /* home-1 */
h1,
h2,
h3 {
  margin-top: 20px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Slab", serif;
}
.section-head h2 {
  font-weight: 700;
} /* form */
.bootstrap-select div.dropdown-menu ul li a {
  padding: 10px 10px 10px 15px;
}
textarea.form-control {
  height: auto;
}
.input-group-text {
  background-color: transparent;
  border-radius: 0px;
  width: 100%;
  height: 100%;
}
.bootstrap-touchspin .input-group-btn-vertical {
  width: auto;
}
.contact-style-1 h2 {
  margin-top: 0;
}
.contact-style-1 .site-button {
  height: 50px;
  width: 100%;
}
.contact-style-1 input {
  height: 50px;
}
.contact-style-1 input,
.contact-style-1 textarea {
  font-size: 16px;
  padding: 10px 20px;
}
.contact-style-1 .social-icon-lg {
  border-top: 1px solid #eeeeee;
  padding-top: 20px;
  text-align: left;
  width: 100%;
  margin: 0;
}
.modal-content .form-control::placeholder {
  color: #bab5b5;
}
.form-control::placeholder {
  color: #495057;
}
.trans-footer .form-control::placeholder {
  color: #8d8d8d;
} /* blog */
.comments-area .comments-title::first-letter {
  color: inherit;
}
.header-nav .nav > li {
  font-weight: 600;
}
h4 {
  margin-top: 10px;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  color: #fff;
}
.dez-post-info,
.dez-post-meta a,
.dez-post-meta ul li {
  color: #7b7b7b;
}
.home-dark .dez-post-info,
.home-dark .dez-post-meta a,
.home-dark .dez-post-meta ul li {
  color: #d0cdcd;
}
.dez-post-meta strong {
  font-weight: 500;
}
.left-footer-menu ul {
  display: flex;
  justify-content: center;
}
.dez-post-title,
.dez-post-title .post-title {
  margin-bottom: 5px;
}
.dez-post-meta {
  margin-bottom: 15px;
}
.blog-post.blog-md {
  margin-bottom: 30px;
}
.blog-post {
  margin-bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .blog-post {
    margin-bottom: 30px;
  }
}
.blog-single.blog-post {
  margin-bottom: 50px;
}
.blog-single .dez-post-text {
  text-align: justify;
}
.comment-respond .comment-reply-title {
  font-size: 20px;
}
.home-dez .post-title a {
  color: #fff;
}
.home-dez .post-title a:hover {
  color: #ffc300;
}
.blog .blog-post {
  margin-bottom: 30px;
}
.share-details-btn ul li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 6px;
}
.site-button.facebook {
  background-color: #3b5998;
}
.site-button.google-plus {
  background-color: #de4e43;
}
.site-button.linkedin {
  background-color: #007bb6;
}
.site-button.instagram {
  background-color: #8a5a4e;
}
.site-button.twitter {
  background-color: #1ea1f3;
}
.site-button.whatsapp {
  background-color: #01c854;
}
.widget .post-title {
  text-transform: none;
  font-size: 15px;
  color: #484848;
}
.dez-img-effect.zoom-slow img {
  transition: all 0.7s;
  -moz-transition: all 0.7s;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
}
.dez-img-effect.zoom-slow:hover img {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.site-button-link .fa-angle-double-right {
  margin-left: 6px;
}
ol.comment-list li.comment .comment-author .fn {
  font-size: 15px;
  text-transform: capitalize;
  color: #000;
}
ol.comment-list li.comment .comment-meta:before,
ol.comment-list li.comment .reply a:before {
  vertical-align: middle;
}
ol.comment-list li.comment .reply a {
  font-weight: 700;
}
ol.comment-list li.comment .comment-meta {
  text-transform: capitalize;
  display: inline-block;
} /* testimonial */
.testimonial-6 .testimonial-text {
  padding: 25px 35px 75px 40px;
} /* shortcodes */
.accordion.border-bottom {
  border-bottom: 0px solid #dee2e6;
} /* footer */
footer h4 {
  margin-top: 10px;
}
footer .newsletter .input-group-btn {
  align-self: center;
}
footer .footer-info-box {
  display: flex;
}
footer .dez-post-meta i,
footer .dez-post-info,
footer .dez-post-meta a,
footer .dez-post-meta ul li {
  color: rgba(255, 255, 255, 0.7);
}
.footer-white .dez-post-meta i,
.footer-white .dez-post-meta a,
.footer-white .dez-post-meta ul li {
  color: #7b7b7b;
}
footer .dez-separator {
  margin-bottom: 20px;
}
.footer-bottom a {
  margin-left: 10px;
}
footer.site-footer.footer-overlay form {
  margin-bottom: 25px;
  border-radius: 4px;
}
footer.site-footer.footer-overlay form,
.site-footer.bg-color-2 .footer-top form,
.site-footer.footer-white .footer-top form {
  margin-bottom: 25px;
  border-radius: 4px;
}
.trans-footer .newsletter input[type="email"] {
  background-color: rgba(255, 255, 255, 0.02);
  border: 0;
  border-radius: 30px !important;
  color: #fff;
}
.footer-image .newsletter input[type="email"] {
  background-color: rgba(0, 0, 0, 0.5);
}
.footer-info-box .newsletter input[type="email"] {
  background-color: #fff;
}
@media only screen and (max-width: 991px) {
  .footer-bottom .text-left,
  .footer-bottom .text-right {
    text-align: center !important;
  }
}
@media only screen and (max-width: 576px) {
  .login-switch {
    display: flex;
  }
} /* New Css */
.footer-bottom span,
.footer-bottom a {
  opacity: 0.4;
}
.footer-bottom.bg-primary span,
.footer-bottom.bg-primary a {
  opacity: 0.5;
} /* Google Recaptcha */
.g-recaptcha,
#rc-imageselect {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}
@media screen and (max-width: 1280px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    margin-bottom: -20px;
  }
}
@media screen and (max-width: 575px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}
.recaptcha-box {
  height: 60px;
} /* Google Recaptcha */ /* New Css */
.new-page {
  padding: 2px 5px;
  font-size: 10px;
  background: #ff0000;
  color: #fff;
  border-radius: 4px;
  vertical-align: middle;
  margin-left: 2px;
} /* Light Gallery */
.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: #fff;
}
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #fff;
}
.check-km {
  cursor: pointer;
}
.icon-bx-xs.check-km {
  width: 40px;
  height: 40px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 2px;
}
.icon-bx-xs.check-km i {
  font-size: 16px;
} /* dez box */
.dez-box .dez-title {
  text-transform: capitalize;
} /* Portfolio detail */
.portfolio-detail .blog-single {
  margin-bottom: 30px;
}
.blog-page-content.style-2.style-3 .gallery .dez-gallery-box,
.blog-page-content.style-1 style-3 .gallery .dez-gallery-box {
  z-index: 1;
} /* Coming Soon */
.dez-coming-soon .coming-soon-content .title {
  margin-top: 0;
} /* Footer */
.logo-footer {
  max-width: 220px;
}
.site-footer .newsletter {
  overflow: hidden;
}
.site-footer .footer-info-box .input-group-btn {
  display: flex;
}
.site-footer .footer-info-box .input-group-btn .site-button {
  align-self: center;
}
.newsletter .equal-wraper .input-group.equal-col .dzSubscribeMsg {
  display: block;
  width: 100%;
} /* Blog */
.skew-triangle {
  padding: 15px;
  align-self: center;
}
.skew-triangle .title {
  margin-top: 0;
}
.blog-md .dez-post-info .dez-post-title h3 {
  margin-bottom: 10px;
}
.blog-post.blog-md .dez-post-title .post-title {
  margin-top: 3px;
} /* Bnr */
.site-header.header-style-1 + .page-content .dez-bnr-inr,
.site-header.header-style-2 + .page-content .dez-bnr-inr {
  padding-top: 100px;
}
.site-header.header-style-3 + .page-content .dez-bnr-inr {
  padding-top: 80px;
}
.site-header.header-style-4 + .page-content .dez-bnr-inr {
  padding-top: 90px;
}
.site-header.header-style-5 + .page-content .dez-bnr-inr {
  padding-top: 150px;
} /* login */
.dez-login .dez-form.p-b0 {
  padding-bottom: 0;
}
@media screen and (max-width: 1200px) {
  .footer-fixed .site-footer {
    position: relative;
  }
  .site-footer .newsletter .dez-tilte {
    font-size: 18px;
  }
  .site-footer .newsletter .equal-wraper strong {
    text-align: left !important;
  }
  .header-nav .nav > li .sub-menu li > .sub-menu.right {
    left: auto;
    right: 220px;
  }
  .header-nav .nav > li .sub-menu.right {
    left: auto;
    right: 0;
  }
  .styleswitcher {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .footer-bottom .text-center {
    margin: 8px 0;
  }
  .trans-footer .footer-top .saf-footer {
    padding: 50px 30px 50px;
  }
  .trans-footer .footer-top {
    padding: 15px 15px 0;
  }
  .site-footer .newsletter .input-group.equal-col.p-t40 {
    padding-top: 0;
  }
  .is-fixed .extra-nav {
    padding: 16px 0;
  }
  .is-fixed .logo-header,
  .is-fixed.header-curve .logo-header {
    height: 71px;
    width: 170px;
  }
  .main-bar.bg-primary .navicon span,
  .header-style-3 .main-bar .navicon span,
  .header-style-4 .main-bar .navicon span {
    background-color: #fff;
  }
  .mo-left .header-nav .logo-header,
  .mo-left.header-style-3 .is-fixed.header-curve .header-nav .logo-header {
    height: auto;
    width: 100%;
  }
  .is-fixed .logo-header a img,
  .is-fixed.header-curve .logo-header a img {
    vertical-align: inherit;
  }
  .dez-quik-search .form-control {
    height: 71px;
  }
  .dez-bnr-inr {
    padding-top: 90px;
  }
  .navbar-toggler {
    z-index: 99;
  }
  .next-match-count:before {
    content: unset;
  }
  .full-blog-dark .dez-full-blog .dez-post-info {
    padding: 5px 30px 0;
  }
  .content-inner {
    padding-top: 60px;
    padding-bottom: 30px;
  }
  .content-inner-1 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .content-inner-2 {
    padding-top: 60px;
    padding-bottom: 0px;
  }
  .header-style-6 .top-bar-crve:before {
    right: -10px;
  }
  .score-info .vs {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 30px;
  }
  .score-info .score {
    margin-bottom: 5px;
    font-size: 50px;
    line-height: 60px;
  }
  .score-info p {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 600px) {
  .dez-tabs.vertical.right .tab-content {
    margin-right: 49px;
  }
  .blog-page-content.style-2.style-3 .blog-post {
    margin-bottom: 0;
  }
  .dez-coming-soon .coming-soon-content .title {
    margin-bottom: 10px;
  }
  .dez-coming-soon .coming-soon-content {
    margin-bottom: 10px;
  }
  .footer-info-box .text-right,
  .footer-info-box .text-left,
  .newsletter strong {
    text-align: center !important;
    display: block;
  }
  .score-info {
    margin: 20px auto -40px;
  }
}
@media screen and (max-width: 360px) {
  .page-notfound {
    padding: 40px 0 20px;
  }
  .pricingtable-row.m-b30 {
    margin-bottom: 0;
  }
  .dez-login .dez-form.m-t100 {
    margin-top: 50px;
  }
  .gallery-area .m-b50 {
    margin-bottom: 25px;
    margin-top: -15px;
  }
  .portfolio-detail .blog-single {
    margin-bottom: 20px;
  }
  .dez-coming-soon .m-t50.info-style-1 {
    margin-top: 30px;
  }
  .trans-footer .footer-top .saf-footer {
    padding: 30px;
  }
  .site-header.header-style-2 .top-bar {
    display: none;
  }
  .dez-bnr-inr {
    height: 300px;
    padding-top: 60px;
  }
  .site-header.header-style-5 + .page-content .dez-bnr-inr {
    padding-top: 140px;
  }
  .dez-bnr-inr .dez-bnr-inr-entry h1 {
    font-size: 24px;
  }
  .footer-skew .footer-top {
    padding-top: 80px;
  }
  .content-inner {
    padding-top: 30px;
    padding-bottom: 0px;
  }
  .content-inner-1 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .content-inner-2 {
    padding-top: 30px;
    padding-bottom: 0px;
  }
  .dez-move-image .font-40 {
    font-size: 18px;
    line-height: 24px;
  }
  .dez-move-image .font-16 {
    margin-top: 15px;
    font-size: 14px;
  }
  .dez-style-3.countdown .date {
    min-width: 70px;
  }
  .section-head p {
    margin-bottom: 10px;
  }
  .site-header.header-style-1 + .page-content .dez-bnr-inr,
  .site-header.header-style-2 + .page-content .dez-bnr-inr {
    padding-top: 70px;
  }
  .site-header.header-style-4 + .page-content .dez-bnr-inr {
    padding-top: 60px;
  }
  .site-header.header-style-6 + .page-content .dez-bnr-inr {
    padding-top: 130px;
  }
  .site-header.header-style-4.style-1 + .page-content .dez-bnr-inr {
    padding-top: 30px;
  }
  .newsletter.input-group.equal-col.p-t30.p-b20 {
    padding-bottom: 10px;
    padding-top: 0;
  }
  .newsletter.icon-bx-wraper.equal-col.p-t30.p-b20 {
    padding-bottom: 0;
  }
  .blog-page-content.style-1.style-3 .dez-full-blog .blog-post {
    margin-bottom: 0;
  }
  .alignleft,
  .alignright {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .dez-button-block hr {
    margin: 0;
  }
} /* container */
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
